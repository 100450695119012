import { useCallback } from 'react';
import PassInfo from '../../../common_lib_front/types/passInfo';
import { VehicleInfoConfig } from '../../../common_lib_front/types/vehicleInfo';
import useVehicleConfigs from '../../../utils/useVehicleConfigs';

export function useIsPassComplete(): (p: PassInfo) => boolean {
  const { vehicleConfig } = useVehicleConfigs();

  return useCallback(
    (p: PassInfo) => {
      const cfg: VehicleInfoConfig = vehicleConfig[p.passInfoId] || {
        make: 'required',
        model: 'required',
        type: 'required',
        color: 'required',
      };
      // debugger;
      return Object.entries(cfg)
        .filter(([_k, v]) => v === 'required')
        .every(([k]) => {
          const val = p[k] || p.vehicle[k];
          return val !== undefined && val !== null && val !== '';
        });
    },
    [vehicleConfig],
  );
}
