import { ApolloError, gql, useMutation, useQuery } from '@apollo/client';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import alertIcon from '../../assets/alert.svg';
import GenericAlert from '../../common_lib_front/components/genericAlert/genericAlert';
import InputField from '../../common_lib_front/components/inputField/inputField';
import PhoneInputWrapper from '../../common_lib_front/components/phoneInputWrapper/phoneInputWrapper';
import { backendResponse } from '../../common_lib_front/types/backendResponse';
import UserInfo, { newUserInfo } from '../../common_lib_front/types/userInfo';
import store from '../../common_lib_front/utilities/store';
import CountrySelect from '../countrySelect/CountrySelect';
import UsStateSelect from '../usStateSelect/UsStateSelect';
import style from './myinfoForm.module.css';

export const EDIT_USER_INFO = gql`
  mutation EditUserInfo($userInfo: UserInput!) {
    editUserInfo(userInfo: $userInfo) {
      success
      error
    }
  }
`;
type EDIT_USER_INFO_REQUEST = {
  userInfo: {
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    address?: string;
    country?: string;
    city?: string;
    state?: string;
    zipCode?: string;
  };
};
type EDIT_USER_INFO_RESPONSE = {
  editUserInfo: backendResponse<undefined>;
};

export const GET_USER_INFO = gql`
  query GetUserInfo {
    getUserInfo {
      success
      error
      data {
        firstName
        lastName
        phoneNumber
        address
        country
        city
        state
        zipCode
      }
    }
  }
`;
type GET_USER_INFO_REQUEST = Record<string, never>;
type GET_USER_INFO_RESPONSE = {
  getUserInfo: backendResponse<{
    firstName: string;
    lastName: string;
    phoneNumber: string;
    address: string;
    country: string;
    city: string;
    state: string;
    zipCode: string;
  }>;
};

export const MyInfoForm = (): ReactElement => {
  const [userInfo, setUserInfo] = useState<UserInfo>(newUserInfo());
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green' | undefined>(undefined);
  const { t } = useTranslation();

  const { refetch } = useQuery<GET_USER_INFO_RESPONSE, GET_USER_INFO_REQUEST>(
    GET_USER_INFO,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'network-only',
      onError: (e: ApolloError) => {
        setAlert(e.message);
        setAlertColor('red');
      },
      onCompleted: d => {
        if (!d.getUserInfo.success) {
          setAlert(d.getUserInfo.error);
          setAlertColor('red');
        } else {
          setUserInfo(newUserInfo(d.getUserInfo.data || {}));
        }
      },
    },
  );
  const [doSave, { loading: mutationLoading }] = useMutation<
    EDIT_USER_INFO_RESPONSE,
    EDIT_USER_INFO_REQUEST
  >(EDIT_USER_INFO, {
    onCompleted: d => {
      refetch();
      if (d.editUserInfo.success) {
        setAlert('Data has been saved');
        setAlertColor('green');
        if (userInfo.firstName !== '' || userInfo.lastName !== '') {
          store.dispatch({
            type: 'UPDATE_PROFILE',
            payload: userInfo,
          });
        }
      } else {
        setAlert(d.editUserInfo.error);
        setAlertColor('red');
      }
    },
    onError: (e: ApolloError) => {
      setAlert(e.message);
      setAlertColor('red');
    },
  });

  const updateUserInfo = (data: Partial<UserInfo>) => {
    setUserInfo({
      ...userInfo,
      ...data,
    });
  };

  if (!userInfo.country) {
    updateUserInfo({ country: 'United States' });
  }

  return (
    <form
      onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        doSave({
          variables: {
            userInfo: {
              firstName: userInfo.firstName || '',
              lastName: userInfo.lastName || '',
              phoneNumber: userInfo.phoneNumber || '',
              address: userInfo.address || '',
              city: userInfo.city || '',
              country: userInfo.country || '',
              state: userInfo.state || '',
              zipCode: userInfo.zipCode || '',
            },
          },
        });
      }}
      className={style.form}
    >
      <div>
        <GenericAlert color={alertColor} hidden={!alert} title={alert} />
      </div>
      {mutationLoading ? 'Saving ...' : null}
      {/* {mutationError ? { mutationError } : null} */}
      <div className={style.inputBox}>
        <div className={style.inputShort}>
          <InputField
            largeSize
            highlightOnFocus
            htmlFor="first-name-inpt"
            labelTitle={t('First Name')}
            inputValue={userInfo?.firstName}
            changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateUserInfo({ firstName: e.target.value });
            }}
          />
        </div>
        <div className={style.inputShort}>
          <InputField
            largeSize
            highlightOnFocus
            htmlFor="last-name-inpt"
            labelTitle={t('Last Name')}
            inputType="text"
            inputValue={userInfo?.lastName}
            changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateUserInfo({ lastName: e.target.value });
            }}
          />
        </div>
      </div>
      <div className={style.inputLong}>
        <PhoneInputWrapper
          standardInput
          inputTitle="Phone Number"
          value={userInfo?.phoneNumber}
          changeHandler={(val: string) => {
            updateUserInfo({ phoneNumber: val });
          }}
        />
      </div>
      {/* <div className={style.inputLong}>
        <InputField
          largeSize
          highlightOnFocus
          htmlFor="email-inpt"
          labelTitle={t('Email Address')}
          inputType="email"
          inputPlaceholder={queryData?.getUserInfo.data.email}
          inputValue={userInfo.email}
          changeHandler={(e:React.ChangeEvent<HTMLInputElement>) => {
            updateUserInfo({ email: e.target.value });
          }}
        />
      </div> */}
      <div className={style.inputBox}>
        <div className={style.inputShort}>
          <InputField
            largeSize
            highlightOnFocus
            htmlFor="address-inpt"
            labelTitle={t('Home Address')}
            inputValue={userInfo?.address}
            changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateUserInfo({ address: e.target.value });
            }}
          />
        </div>
        <div className={style.inputShort}>
          <InputField
            largeSize
            highlightOnFocus
            htmlFor="city-inpt"
            labelTitle={t('City')}
            inputValue={userInfo?.city}
            changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateUserInfo({ city: e.target.value });
            }}
          />
        </div>
      </div>
      <div className={style.inputBox}>
        <div className={style.inputShort}>
          <CountrySelect
            title={t('Country')}
            htmlFor="country-inpt"
            value={userInfo?.country}
            changeHandler={(e: React.ChangeEvent<HTMLSelectElement>) => {
              updateUserInfo({ country: e.target.value });
            }}
          />
        </div>
      </div>
      <div className={style.inputBox}>
        <div className={style.inputShort}>
          {!userInfo.country || userInfo.country === 'United States' ? (
            <UsStateSelect
              title={t('State')}
              htmlFor="state-inpt"
              value={userInfo?.state}
              changeHandler={(e: React.ChangeEvent<HTMLSelectElement>) => {
                updateUserInfo({ state: e.target.value });
              }}
            />
          ) : (
            <InputField
              largeSize
              highlightOnFocus
              htmlFor="state-inpt"
              labelTitle={t('State')}
              inputValue={userInfo.state}
              changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                updateUserInfo({ state: e.target.value });
              }}
            />
          )}
        </div>
        <div className={style.inputShort}>
          <InputField
            largeSize
            highlightOnFocus
            htmlFor="zip-inpt"
            labelTitle={t('Zip Code')}
            formatCode={userInfo.country === 'United States' ? 'zip-strict' : ''}
            inputValue={userInfo?.zipCode}
            changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (userInfo.country !== 'United States' && e.target.validationMessage) {
                e.target.setCustomValidity('');
                e.target.reportValidity();
              }
              updateUserInfo({ zipCode: e.target.value });
            }}
          />
        </div>
      </div>
      <div className={style.alertBox}>
        <img src={alertIcon} alt="" />
        <p className={`${style.alert} textColorMedium`}>
          {t('Please verify that the above information is current and accurate.')}
        </p>
      </div>
      <div className={style.btnBox}>
        <input
          type="submit"
          value={`${t('Save Changes')}`}
          className={`${style.btn} mainColor textColorWhite`}
        />
      </div>
    </form>
  );
};

export default MyInfoForm;
