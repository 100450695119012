import { gql } from '@apollo/client';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';
// import PassInfo, { PassStatuses } from '../../../common_lib_front/types/passInfo';
import { backendClient } from '../../../common_lib_front/utilities/BackendAPI';

// const SAVE_PASS = gql`
//   mutation SavePass(
//     $passInfoId: String
//     $registrationId: String
//     $status: String
//     $startDate: DateTime
//     $endDate: DateTime
//   ) {
//     registerPass(
//       passInfoId: $passInfoId
//       registrationId: $registrationId
//       status: $status
//       startDate: $startDate
//       endDate: $endDate
//     ) {
//       success
//       error
//       data {
//         passId
//         passInfoId
//       }
//     }
//   }
// `;
// export type SAVE_PASS_VARS = {
//   passInfoId: string;
//   registrationId: string;
//   status: PassStatuses;
//   startDate?: Date;
//   endDate?: Date;
// };
// export type SAVE_PASS_RES = {
//   registerPass: backendResponse<
//     Array<{
//       passId: string;
//       passInfoId: string;
//     }>
//   >;
// };

// export const DELETE_PASS = gql`
//   mutation DeletePassAndVehicle($passId: String) {
//     deletePass(passId: $passId) {
//       success
//       error
//     }
//   }
// `;
// export type DELETE_PASS_VARS = {
//   passId: string;
// };
// export type DELETE_PASS_RES = {
//   deletePass: backendResponse<undefined>;
// };

export const SET_NUM_WRISTBANDS = gql`
  mutation Mutation($passInfoId: String, $desiredCount: Float, $registrationId: String) {
    ensureWristbandCount(
      passInfoId: $passInfoId
      desiredCount: $desiredCount
      registrationId: $registrationId
    ) {
      success
      error
    }
  }
`;
export type SET_NUM_WRISTBANDS_VARS = {
  passInfoId: string;
  desiredCount: number;
  registrationId: string;
};
export type SET_NUM_WRISTBANDS_RES = {
  ensureWristbandCount: backendResponse<undefined>;
};

export default function registerWristbands(
  desiredCount: number,
  passInfoId: string,
  registrationId: string,
) {
  return backendClient
    .mutate<SET_NUM_WRISTBANDS_RES, SET_NUM_WRISTBANDS_VARS>({
      mutation: SET_NUM_WRISTBANDS,
      variables: {
        desiredCount,
        passInfoId,
        registrationId,
      },
    })
    .then(res => {
      if (res.data?.ensureWristbandCount.error) {
        throw res.data.ensureWristbandCount.error;
      }
    });
}

// function addWristband(variables: SAVE_PASS_VARS) {
//   return backendClient.mutate<SAVE_PASS_RES, SAVE_PASS_VARS>({
//     mutation: SAVE_PASS,
//     variables,
//   });
// }

// function deleteWristband(passId: string) {
//   return backendClient.mutate<DELETE_PASS_RES, DELETE_PASS_VARS>({
//     mutation: DELETE_PASS,
//     variables: {
//       passId,
//     },
//   });
// }

// type minimumPass = Pick<PassInfo, 'passId'>;
// export default function registerWristbands<T extends minimumPass>(
//   numWristbands: number,
//   currentWristbands: T[],
//   passInfoId: string,
//   registrationId: string,
// ) {
//   const adjustmentNum = numWristbands - currentWristbands.length;
//   const isAdding = adjustmentNum > 0;
//   const iterationArray = isAdding
//     ? new Array<null>(Math.abs(adjustmentNum)).fill(null)
//     : currentWristbands.slice(adjustmentNum);

//   return Promise.all(
//     iterationArray.map<Promise<unknown>>(passObj => {
//       if (isAdding) {
//         return addWristband({
//           passInfoId,
//           registrationId,
//           status: 'incomplete',
//         });
//       } else if (passObj) {
//         return deleteWristband(passObj.passId);
//       }
//       return new Promise((_, reject) =>
//         reject('Something went woring. Could not determine the correct action.'),
//       );
//     }),
//   );
// }
