import React, { ReactElement, useContext, useMemo, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import useVehicleConfigs from '../../../../src/utils/useVehicleConfigs';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import PassAdderSection from '../../../components/passAdderSection/passAdderSection';
import PassSummaryCollection from '../../../components/passSummary/passSummaryCollection';
import RegistrationNavHeader from '../../../components/registrationNavHeader/registrationNavHeader';
import VehicleInfoFormCollection from '../../../components/vehicleInfoForm/vehicleInfoFormCollection';
import WarnBeforeUnload from '../warnBeforeUnload/warnBeforeUnload';
import { RecalculateStepsEvent, RegistrationPageProps } from '../wrapper/wrapper';
import style from './registerVehicle.module.css';
import useRegisterVehicle from './useRegisterVehicle';

const TITLES: Record<string, string> = {
  default: 'Register Your Vehicle',
  watercolor: 'Wristband Registration',
};
const SUBTITLES: Record<string, string> = {
  default:
    'Please register all vehicles entering the community. Failure to do so may result in fines and possible expulsion from the community',
  grandcomplex: 'Please register the vehicle entering the Grand Complex parking garage. ',
  watercolor: '',
};

export function RegisterVehicle(props: RegistrationPageProps): ReactElement {
  const { nextHref } = props;
  const { guestName } = useVehicleConfigs();
  const {
    redirect,
    doSubmit,
    alert,
    passes,
    setPasses,
    addingPass,
    doRemovePass,
    registrationDates,
    doAddPass,
    t,
  } = useRegisterVehicle(nextHref, guestName);
  const { communityId, featuresConfig } = useContext(CommunityContext);
  const hostWillPay = Boolean(featuresConfig?.host?.inviteGuest?.hostMustPay);
  const [hideSubmit, setHideSubmit] = useState<boolean>(true);
  WarnBeforeUnload();

  const somePassesToBePaid = useMemo(
    () => passes.some(p => p.paid === 'unpaid'),
    [passes],
  );

  const addPass = async () => {
    await doAddPass();
  };

  const [numAddablePasses, setNumAddablePasses] = useState<number>(0);

  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return (
    <form
      className={style.container}
      onSubmit={async (e: React.FormEvent) => {
        e.preventDefault();
        await doSubmit();
        e.target.dispatchEvent(new RecalculateStepsEvent(5000));
      }}
    >
      <div className={`${style.box} white `}>
        <RegistrationNavHeader
          // title={`${t('Step 2')}: ${t('Register Your Vehicle')}`}
          title={`${t('Step 2')}: ${t(TITLES[communityId] ?? TITLES.default)}`}
          subtitle={SUBTITLES[communityId] ?? SUBTITLES.default}
          // subtitle={
          //   communityName === 'Grand Complex'
          //     ? 'Please register the vehicle entering the Grand Complex parking garage. '
          //     : t('step2_subtitle')
          // }

          //   {t('step2_subtitle')}
        />
        <GenericAlert color="red" title={alert} hidden={!alert} />
        <div
          className={style.flexBox}
          data-only-left-box={!Boolean(numAddablePasses || somePassesToBePaid)}
        >
          {/* <div className={somePassesToBePaid ? style.boxLeft : style.boxCenter}> */}
          <div className={style.boxLeft}>
            <VehicleInfoFormCollection
              defaultPassDates={registrationDates || undefined}
              data={passes}
              setData={setPasses}
              removeHandler={doRemovePass}
              showSaveBtn={false}
              showTitle
              max={10}
              allowAdd={false}
              allowRemove={(pass, idx) => {
                if (idx === 0) return false;
                if (
                  ['carillonbeach', 'debordieu', 'pinnacleport'].includes(communityId)
                ) {
                  return true;
                }
                return pass.passType !== 'invited-guest';
              }}
              hideDates={['watercolor'].includes(communityId)}
              setHideSubmit={setHideSubmit}
            />
            {!hostWillPay ? (
              <div className={style.boxContainer}>
                <GenericButton
                  size="medium"
                  color="blue"
                  title={addingPass === true ? t('Adding Pass...') : t('+ Add Pass')}
                  type="button"
                  clickHandler={() => addPass()}
                />
              </div>
            ) : null}
          </div>
          <div className={style.boxRight}>
            {somePassesToBePaid && (
              <PassSummaryCollection
                showEditBtn={false}
                showVehicleInfo={false}
                showTotal
                hideIf0Total
                data={passes}
              />
            )}
            <PassAdderSection
              passes={passes}
              setPasses={setPasses}
              // passFilter={p => p.portal === 'guest' && p.name.includes('Staging')}
              // TODO HARDCODE - remove me
              passGroup="Guest Addons"
              hideWhenNoAddable
              defaultValues={registrationDates || undefined}
              removeHandler={doRemovePass}
              setNumAddablePasses={setNumAddablePasses}
            />
          </div>
        </div>
        <div className={style.btn}>
          <GenericButton
            hidden={hideSubmit}
            size="large"
            color="blue"
            title={t('Continue')}
            type="submit"
          />
        </div>
      </div>
    </form>
  );
}

export default RegisterVehicle;
