import { gql, useQuery } from '@apollo/client';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';
import { PassStatuses } from '../../../common_lib_front/types/passInfo';

const GET_PASSES = gql`
  query GetPasses($registrationId: String!) {
    getPassesByRegistration(registrationId: $registrationId) {
      success
      error
      data {
        passId
        passInfoId
        number
        externalCredentialNumber
        paid
        passType
        startDate
        endDate
        status
      }
    }
    getCommunityRental(registrationId: $registrationId) {
      success
      error
      data {
        guestCanEdit
        arrivalDate
        departureDate
      }
    }
  }
`;
export type GET_PASSES_VARS = {
  registrationId: string;
};
export type GET_PASSES_RES = {
  getPassesByRegistration: backendResponse<
    Array<{
      passId: string;
      passInfoId: string;
      number: string;
      externalCredentialNumber: string;
      passType: string;
      startDate: string;
      endDate: string;
      status: PassStatuses;
    }>
  >;
  getCommunityRental: backendResponse<{
    arrivalDate: string;
    departureDate: string;
    guestCanEdit: boolean;
  }>;
};

type useGetRegistrationPassesRes = {
  data: GET_PASSES_RES['getPassesByRegistration']['data'];
  error: string;
  loading: boolean;
};
export default function useGetRegistrationPasses(
  registrationId: string,
): useGetRegistrationPassesRes {
  const { data, error, loading } = useQuery<GET_PASSES_RES, GET_PASSES_VARS>(GET_PASSES, {
    fetchPolicy: 'network-only',
    variables: {
      registrationId,
    },
  });

  return {
    data: data?.getPassesByRegistration.data,
    loading,
    error:
      data?.getPassesByRegistration.error ||
      (error ? 'Something went wrong. Could not get your passes.' : ''),
  };
}
