import { QueryHookOptions, QueryResult, gql, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { CommunityContext } from '../common_lib_front/communityConfigs/communityContextProvider';
import { backendResponse } from '../common_lib_front/types/backendResponse';

const GET_GROUPS = gql`
  query GetPassGroups($communityId: String!) {
    getPassGroups(communityId: $communityId) {
      success
      error
      data {
        communityId
        name
        passGroupId
        passInfoList
      }
    }
  }
`;
type GET_GROUPS_VARS = {
  communityId: string;
};
type GET_GROUPS_RES = {
  getPassGroups: backendResponse<
    {
      communityId: string;
      name: string;
      passGroupId: string;
      passInfoList: string[];
    }[]
  >;
};

type usePassGroupsReturn = QueryResult<GET_GROUPS_RES, GET_GROUPS_VARS>;
type usePassGroupsOptions = QueryHookOptions<GET_GROUPS_RES, GET_GROUPS_VARS>;
export default function usePassGroups(
  options = {} as usePassGroupsOptions,
): usePassGroupsReturn {
  const { communityId } = useContext(CommunityContext);
  return useQuery<GET_GROUPS_RES, GET_GROUPS_VARS>(GET_GROUPS, {
    variables: {
      communityId,
    },
    ...options,
  });
}
