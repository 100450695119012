import { gql } from '@apollo/client';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddImg } from '../../assets/add.svg';
import GenericAlert from '../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import UserInfo, { newUserInfo } from '../../common_lib_front/types/userInfo';
import { backendClient } from '../../common_lib_front/utilities/BackendAPI';
import collectionTools from '../../common_lib_front/utilities/collectionTools';
import SharePassForm from './sharePassForm';
import style from './sharePassFormCollection.module.css';

const SHARE_PASS = gql`
  query SharePass(
    $phoneNumber: String!
    $email: String!
    $lastName: String!
    $firstName: String!
    $passId: String!
  ) {
    sendPass(
      phone: $phoneNumber
      email: $email
      lastName: $lastName
      firstName: $firstName
      passId: $passId
    ) {
      success
      error
    }
  }
`;

export const SharePassFormCollection = (props: { passId: string }): ReactElement => {
  const [data, setData] = useState<Array<UserInfo>>([]);
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green' | undefined>(undefined);
  const [messages, setMessages] = useState<string[]>([]);
  const { t } = useTranslation();

  const { passId } = props;

  const doShareAll = () => {
    setAlert('');
    setMessages([]);
    // Do validation
    const msgs = data.map((u: UserInfo) => {
      if (!u.firstName || !u.lastName) {
        return 'Missing first name or last name';
      }
      if (!u.email && !u.phoneNumber) {
        return 'Please provider email address or phone number';
      }

      return '';
    });
    setMessages(msgs);

    if (msgs?.find((s: string) => s)) {
      return;
    }

    Promise.all(
      data.map((u: UserInfo) =>
        backendClient
          .query({
            query: SHARE_PASS,
            variables: { ...u, passId },
          })
          .then(d => {
            if (!d.data.sendPass.success) {
              throw d.data.sendPass.error;
            }
          }),
      ),
    )
      .then(() => {
        setAlertColor('green');
        setAlert('Passes sent successfully!');
      })
      .catch(error => {
        setAlert(
          typeof error === 'string'
            ? error
            : 'Something went wrong. Please try again later',
        );
        setAlertColor('red');
      });
  };

  const { add, update, remove } = collectionTools({
    newElement: newUserInfo,
    data,
    setData,
  });
  useEffect(() => {
    if (data.length === 0) {
      add();
    }
  }, [data, add]);

  return (
    <div className={style.container}>
      <GenericAlert color={alertColor} title={alert} hidden={!alert} />
      {data.map((elem: UserInfo, idx: number) => (
        <>
          <GenericAlert color="red" title={messages[idx]} hidden={!messages[idx]} />
          <SharePassForm
            data={elem}
            idx={idx}
            update={(obj: any) => update(idx, obj)}
            remove={() => remove(idx)}
          />
        </>
      ))}
      <div className={style.btn}>
        <GenericButton
          color="yellow"
          size="large"
          title={t('Share')}
          clickHandler={doShareAll}
        />
      </div>
      <div className={style.addBtn}>
        <GenericButton
          icon={() => <AddImg className={style.addBtnImg} />}
          title=""
          clickHandler={add}
        />
        <p className={`${style.textBelow} textColorSecondary `}>{t('Add Recipient')}</p>
      </div>
    </div>
  );
};
