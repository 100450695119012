import React, { ReactElement } from 'react';
import { ReactComponent as CloseImg } from '../../assets/close.svg';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import style from './popUpSmall.module.css';

type PopUpProps = {
  children: any;
  title: string;
  close: () => void;
  isOpen: boolean;
  showCircle?: boolean;
  showTitleMiddle?: boolean;
};

export default function PopUpSmall(props: PopUpProps): ReactElement {
  const { title, isOpen, showCircle, showTitleMiddle } = props;
  let header;
  if (showTitleMiddle) {
    header = style.headerMiddle;
  } else {
    header = style.box__header;
  }

  if (isOpen) {
    return (
      <div className={`${style.greyBackGround} darkGrey `}>
        <div className={`${style.box} white `}>
          <div className={`${header} mainColor `}>
            <span className={style.box__titleLeft}>
              {showCircle && <div className={style.circle} />}
              <h2 className={`${style.box__title} textColorWhite `}>{title}</h2>
            </span>
            {showTitleMiddle ? null : (
              <div className={style.btn}>
                <GenericButton
                  icon={() => <CloseImg className={`${style.closeImg}`} />}
                  title=""
                  clickHandler={() => props.close()}
                />
              </div>
            )}
          </div>
          <div className={style.itemBox}>{props.children}</div>
        </div>
      </div>
    );
  }
  return <></>;
}

PopUpSmall.defaultProps = {
  showCircle: false,
  showTitleMiddle: false,
};
