import { ReactElement, useContext, useEffect, useState } from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { CommunityContext } from './common_lib_front/communityConfigs/communityContextProvider';
import useChatbot from './common_lib_front/hooks/useChatbot';
import * as CommonPages from './common_lib_front/pages';
import InviteAccountClaim from './common_lib_front/pages/inviteAccountClaim/inviteAccountClaim';
import store, { isLogedIn as reduxIsLogedIn } from './common_lib_front/utilities/store';
import useUserGuiding from './common_lib_front/utilities/useUserGuiding';
import { Footer } from './components/footer/footer';
import Header from './components/header/header';
import './css';
import * as Pages from './pages/index';

export default function App(): ReactElement {
  const [isLoggedIn, setIsLogedin] = useState<boolean>(reduxIsLogedIn());
  useEffect(() => store.subscribe(() => setIsLogedin(reduxIsLogedIn())), [setIsLogedin]);
  const { communityId: domain } = useContext(CommunityContext);
  useUserGuiding('vendor');
  useChatbot('guest');

  const loggedInRoutes = [
    <Route exact path="/guest/pass-details/:passId/share-history" key="share-history">
      <Pages.ShareHistory />
    </Route>,
    <Route exact path="/guest/pass-details" key="pass-details">
      <Pages.PassDetails />
    </Route>,
    <Route exact path="/guest/my-passes/share-pass" key="share-pass">
      <Pages.SharePass />
    </Route>,
    <Route exact path="/guest/my-passes/refund-pass/:passId" key="refund-pass">
      <Pages.RefundPass />
    </Route>,
    <Route exact path="/guest/my-passes/delete-pass/:passId" key="delete-pass">
      <Pages.DeletePass />
    </Route>,
    <Route
      exact
      path="/guest/my-passes/edit-pass/:passId/:passInfoId/:number"
      key="edit-pass"
    >
      <Pages.EditPass />
    </Route>,
    <Route exact path="/guest" key="guest-home">
      <Pages.GuestHome />
    </Route>,
    <Route exact path="/guest/my-passes" key="my-passes">
      <Pages.GuestPasses />
    </Route>,
    <Route exact path="/guest/edit-profile/my-profile" key="my-profile">
      <Pages.EditProfileGuest active="profile">
        <Pages.MyProfile />
      </Pages.EditProfileGuest>
    </Route>,
    <Route exact path="/guest/edit-profile/reset-password" key="reset-password">
      <Pages.EditProfileGuest active="password">
        <Pages.ResetPassword />
      </Pages.EditProfileGuest>
    </Route>,
    <Route exact path="/guest/edit-profile" key="edit-profile">
      <Redirect to="/guest/edit-profile/my-profile" />
    </Route>,
    <Route exact path="/guest/community-rules" key="community-rules">
      <Pages.CommunityRulesReview />
    </Route>,
    <Route
      path={['/guest/registration/rental', '/guest/registration/rental/:passNumber']}
      key="rental"
    >
      <Pages.RegisterRental />
    </Route>,
    <Route
      path={['/guest/registration/:registrationId', '/guest/registration']}
      key="registration"
    >
      <Pages.RegistrationWrapper />
    </Route>,
    <Route path="/" key="root-path">
      <Redirect to="/guest" />
    </Route>,
  ];

  const loggedOutRoutes = [
    <Route exact path="/create-account" key="create-account">
      <CommonPages.CreateAccount
        role="guest" // eslint-disable-line
        successHref="/guest/registration"
      />
    </Route>,
    <Route exact path="/login" key="login">
      <CommonPages.Login
        role="guest" // eslint-disable-line
        successHref="/guest"
        referedUserHref="/guest/edit-profile/reset-password"
        // disableCreation={domain === 'grandcomplex'}
        disableCreation
      />
    </Route>,
    <Route exact path="/forgot-password" key="forgot-password">
      <CommonPages.ForgotPasswordEmail />
    </Route>,
    <Route exact path="/forgot-password/verification-code" key="verification-code">
      <CommonPages.ForgotPasswordVerification />
    </Route>,
    <Route path="/forgot-password/reset-password/:identifier" key="reset-password">
      <CommonPages.ForgotPasswordReset />
    </Route>,
    <Route path="/claim-invite-account" key="claim-invite">
      <InviteAccountClaim
        role="guest" // eslint-disable-line
        successHref="/guest"
      />
    </Route>,
    <Route exact path="/magic-link" key="magic-link">
      <CommonPages.MagicLinkAuth
        defaultSuccessHref="/guest"
        portal="guest"
        registrationSuccessHref="/guest/registration"
      />
    </Route>,
    <Route path="/" key="default-logged-out">
      <CommonPages.LoginRedirect />
    </Route>,
  ];

  return (
    <div className="container">
      <Router basename={`/${domain}`}>
        <Header />
        <Switch>
          <Route path="/guest/privacy-policy">
            <CommonPages.PrivacyPolicy />
          </Route>
          <Route path="/guest/terms-conditions">
            <CommonPages.TermsAndConditions />
          </Route>
          <Route path="/guest/help">
            <CommonPages.HelpPage />
          </Route>

          {isLoggedIn
            ? loggedInRoutes.map(route => route)
            : loggedOutRoutes.map(route => route)}
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}
