import { useEffect } from 'react';
import { getCurrentPortalKey } from '../../../common_lib_front/configs/symlivUrls';
function WarnBeforeUnload(): null {
  useEffect(() => {
    if (getCurrentPortalKey() !== 'dev') {
      const handleBeforeUnload = (e: BeforeUnloadEvent) => {
        e.preventDefault();
        e.returnValue = '';
        const message =
          'Are you sure you want to leave? You may lose unsaved changes and will have to complete registration before your pass is valid.';
        // Show a confirmation dialog
        e.returnValue = message;
        return message;
      };
      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, []);
  return null;
}
export default WarnBeforeUnload;
