import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import { newGuestInfo } from '../../../common_lib_front/types/guestInfo';
import GuestInformationForm from '../../../components/guestInformationForm/guestInformationForm';
import RegistrationNavHeader from '../../../components/registrationNavHeader/registrationNavHeader';
import RentalInfoForm from '../../../components/rentalInfoForm/rentalInfoForm';
import WarnBeforeUnload from '../warnBeforeUnload/warnBeforeUnload';
import { RegistrationPageProps } from '../wrapper/wrapper';
import style from './registrationHome.module.css';
import useRegistrationHome from './useRegistrationHome';

export default function RegistrationHome(props: RegistrationPageProps): ReactElement {
  const { nextHref } = props;
  const {
    guestInfo,
    setGuestInfo,
    rentalInfo,
    setRentalInfo,
    redirect,
    alert,
    doSubmitAll,
  } = useRegistrationHome(nextHref);

  WarnBeforeUnload();

  const { t } = useTranslation();
  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return (
    <form
      className={style.container}
      onSubmit={(e: React.FormEvent) => {
        e.preventDefault();
        doSubmitAll();
      }}
    >
      <div className={`${style.box} white `}>
        <RegistrationNavHeader
          title={`${t('Step 1')}: ${t('Guest & Rental Registration')}`}
          subtitle={t('Please provide the required information below')}
        />
        <GenericAlert color="red" title={alert} hidden={!alert} />
        <div className={style.flexBox}>
          <div className={style.boxLeft}>
            <GuestInformationForm
              state={guestInfo}
              immutable={!rentalInfo.guestCanEdit}
              updateState={(key: string, val: string) => {
                // const tmp: GuestInfo = { ...guestInfo };
                // tmp[key] = val;
                // setGuestInfo(tmp);
                setGuestInfo(
                  newGuestInfo({
                    ...guestInfo,
                    [key]: val,
                  }),
                );
              }}
            />
          </div>
          <div className={style.boxRight}>
            <RentalInfoForm
              immutable={!rentalInfo.guestCanEdit}
              data={rentalInfo}
              idx={0}
              removeHandler={() => null}
              updateHandler={obj => {
                setRentalInfo({
                  ...rentalInfo,
                  ...obj,
                });
              }}
            />
          </div>
        </div>
        <div className={style.btn}>
          <GenericButton size="large" color="blue" title={t('Continue')} type="submit" />
        </div>
      </div>
    </form>
  );
}
