import { gql, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';
import PassInfo, { newPassInfo } from '../../../common_lib_front/types/passInfo';
import RentalInfo from '../../../common_lib_front/types/rentalInfo';
import VehicleInfo, { newVehicleInfo } from '../../../common_lib_front/types/vehicleInfo';
import { backendClient } from '../../../common_lib_front/utilities/BackendAPI';

const GET_ALL_PASSES = gql`
  query GetPassesByUser($passType: String) {
    getPassesByUser(passType: $passType) {
      success
      error
      data {
        passId
        number
        passType
        startDate
        endDate
        status
        shared
        url
        registrationId
        paid
        passInfoId
        externalCredentialNumber
        communityRental {
          address
        }
      }
    }
  }
`;

export type fetchedPass = {
  passId: string;
  number: string;
  passType: string;
  startDate: string;
  endDate: string;
  status: string;
  shared: boolean;
  url: string;
  registrationId: string;
  communityRental: RentalInfo;
};

type GET_ALL_PASSES_VARS = Record<string, unknown>;

type GET_ALL_PASSES_RES = {
  getPassesByUser: backendResponse<fetchedPass[]>;
};

const GET_VEHICLE = gql`
  query GetVehicle($passId: String!) {
    getVehicle(passId: $passId) {
      success
      error
      data {
        vehicleId
        make
        vehicleModel
        year
        type
        color
        licensePlate
        fleetNumber
        isRental
        primaryDriverName
      }
    }
  }
`;
type GET_VEHICLE_VARS = {
  passId: string;
};
type GET_VEHICLE_RES = {
  getVehicle: backendResponse<{
    vehicleId: string;
    make: string;
    vehicleModel: string;
    type: string;
    color: string;
    licensePlate: string;
    fleetNumber: string;
    isRental: boolean;
    primaryDriverName: string;
  }>;
};

type useGuestPassesType = {
  allPasses: Array<PassInfo>;
  activeTab: string;
  communityId: string;
  setActiveTab: (val: string) => void;
};
export default function useGuestPasses(): useGuestPassesType {
  const [allPasses, setAllPasses] = useState<Array<PassInfo>>([]);
  const { communityId } = useContext(CommunityContext);
  const [activeTab, setActiveTab] = useState<string>(
    communityId === 'watercolor' ? 'amenity' : 'Current',
  );

  // const [doQuery] = useLazyQuery(GET_PASSES, {
  const { data: passData, refetch } = useQuery<GET_ALL_PASSES_RES, GET_ALL_PASSES_VARS>(
    GET_ALL_PASSES,
    {
      fetchPolicy: 'network-only',
      variables: {
        passType: activeTab === 'amenity' ? 'wristband' : null,
      },
      onError: () => {
        if (process.env.REACT_APP_DEBUG === 'true') {
          // setAllPasses(passesList1);
        }
      },
      onCompleted: async d => {
        if (!d.getPassesByUser.success) {
          if (process.env.REACT_APP_DEBUG === 'true') {
            // setAllPasses(passesList1);
          }
          return;
        }
        if (d.getPassesByUser.data) {
          const res = d.getPassesByUser.data
            .filter(p => !['single', 'Single'].includes(p.passType))
            .map(p => newPassInfo(p as Partial<PassInfo>));
          await Promise.all(
            res.map((element, idx: number) =>
              backendClient
                .query<GET_VEHICLE_RES, GET_VEHICLE_VARS>({
                  fetchPolicy: 'network-only',
                  query: GET_VEHICLE,
                  variables: { passId: element.passId },
                })
                .then(v => {
                  res[idx].vehicle = newVehicleInfo(
                    v.data.getVehicle.data as Partial<VehicleInfo>,
                  );
                })
                .catch(e => {
                  console.log(e);
                }),
            ),
          );
          setAllPasses(res);
        }
      },
    },
  );

  useEffect(() => {
    refetch();
  }, [activeTab]);

  return {
    allPasses,
    activeTab,
    setActiveTab,
    communityId,
  };
}
