import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import ScormWrapper from '../../../common_lib_front/components/scormWrapper/scormWrapper';
import style from './communityRulesReview.module.css';

export default function CommunityRulesReview(): ReactElement {
  const history = useHistory();
  return (
    <div className={style.box}>
      <div className={style.videoContainer}>
        {/* <iframe
          className={`${style.ruleWindow} darkGrey `}
          title="Community Rules"
          src={`${process.env.PUBLIC_URL}/vocb_guest_education/story.html`}
          allowFullScreen
          frameBorder="0"
        /> */}
        <ScormWrapper
          portal="guest"
          continueHandler={() => {
            history.push('/guest');
          }}
        />
      </div>
    </div>
  );
}
