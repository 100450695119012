import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import cardStyle from './portalCard.module.css';

type PortalCardProps = {
  img: ReactElement;
  title: string;
  description: string;
  href: string;
  btnLabel: string;
};

export const PortalCard = (props: PortalCardProps): ReactElement => {
  const { href, img, title, description, btnLabel } = props;

  return (
    <Link to={href}>
      <div className={cardStyle.cardBox}>
        <div className={`${cardStyle.card} white `}>
          <div className={cardStyle.image}>{img}</div>
          <h5 className={`${cardStyle.card__title} textColorDark `}>{title}</h5>
          <p className={`${cardStyle.card__description} textColorDark `}>{description}</p>
          <div className={cardStyle.card__btn}>
            <div className={cardStyle.card__btnInnerBox}>
              <GenericButton color="blue" size="large" title={btnLabel} />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default PortalCard;
