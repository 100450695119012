import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ReactComponent as EditImg } from '../../assets/edit.svg';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import { PassPrice } from '../../common_lib_front/hooks/useOrderPrice';
import PassInfo from '../../common_lib_front/types/passInfo';
import { VehicleInfoConfig } from '../../common_lib_front/types/vehicleInfo';
import { formatDate } from '../../common_lib_front/utilities/formatDate';
import style from './passSummary.module.css';

const defaultConfig = {
  make: 'required',
  model: 'required',
  type: 'required',
  color: 'required',
  plateNumber: 'required',
  fleetNumber: 'required',
};

type PassSummaryProps = {
  showVehicleInfo: boolean;
  showEditBtn: boolean;
  data: PassInfo;
  idx: number;
  editLink?: string;
  config?: VehicleInfoConfig;
  title?: string;
  priceInfo?: PassPrice;
};

export default function PassSummary(props: PassSummaryProps): ReactElement {
  const { idx, showEditBtn, data, showVehicleInfo, editLink, config, title, priceInfo } =
    props;
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className={style.box}>
      <div className={style.titleBox}>
        <h6 className={`${style.title} textColorDark `}>
          {idx === undefined ? '' : ` #${idx + 1}`} {t(title || '')}
        </h6>
        <div className={style.editBtn}>
          {showEditBtn ? (
            <GenericButton
              shape="circle"
              icon={() => <EditImg className={style.circleImg} />}
              title=""
              clickHandler={() => {
                if (editLink) {
                  history.push(editLink);
                }
              }}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className={style.container}>
        <div className={style.tableBox}>
          <div className={style.info}>
            <ul className={style.ulBox}>
              <li className={`${style.tHead} textColorDark `}>{t('Start Date')}</li>
              <li className={`${style.tHead}  `}>
                {formatDate(new Date(data.startDate))}
              </li>
            </ul>
            <ul className={style.ulBox}>
              <li className={`${style.tHead} textColorDark `}>{t('Valid Through')}</li>
              <li className={`${style.tHead}  `}>{formatDate(new Date(data.endDate))}</li>
            </ul>
          </div>
        </div>
        {showVehicleInfo ? (
          <div className={style.vehicleInfoBox}>
            <div className={style.titleBox}>
              <h6 className={`${style.title} textColorDark `}>
                {t('Vehicle Information')}
              </h6>
            </div>
            <div className={style.tableBox}>
              <div className={style.info}>
                {config?.make !== 'disabled' ? (
                  <ul className={style.ulBox}>
                    <li className={`${style.tHeadSpace} textColorDark `}>{t('Make')}</li>

                    <li className={`${style.tHead}  `}>{data.vehicle.make}</li>
                  </ul>
                ) : null}
                {config?.model !== 'disabled' ? (
                  <ul className={style.ulBox}>
                    <li className={`${style.tHeadSpace} textColorDark `}>{t('Model')}</li>

                    <li className={`${style.tHead} `}>{data.vehicle.model}</li>
                  </ul>
                ) : null}{' '}
                {config?.type !== 'disabled' ? (
                  <ul className={style.ulBox}>
                    <li className={`${style.tHeadSpace} textColorDark `}>{t('Type')}</li>

                    <li className={`${style.tHead} `}>{data.vehicle.type}</li>
                  </ul>
                ) : null}
                {config?.color !== 'disabled' ? (
                  <ul className={style.ulBox}>
                    <li className={`${style.tHeadSpace} textColorDark `}>{t('Color')}</li>

                    <li className={`${style.tHead}  `}>{data.vehicle.color}</li>
                  </ul>
                ) : null}
                {config?.plateNumber !== 'disabled' ? (
                  <ul className={style.ulBox}>
                    <li className={`${style.tHeadSpace} textColorDark `}>
                      {t('License Plate #')}
                    </li>

                    <li className={`${style.tHead} `}>{data.vehicle.plateNumber}</li>
                  </ul>
                ) : null}
                {config?.fleetNumber !== 'disabled' ? (
                  <ul className={style.ulBox}>
                    <li className={`${style.tHeadSpace} textColorDark `}>
                      {t('Fleet #')}
                    </li>

                    <li className={`${style.tHead}  `}>{data.vehicle.fleetNumber}</li>
                  </ul>
                ) : null}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className={`${style.subtotal} textColorDark `}>
        <span>{` ${
          ['paid', 'ach-pending'].includes(data.paid) ? 'Prepaid' : `${t('Subtotal')}`
        }`}</span>
        <span>${priceInfo?.price ?? data.price}</span>
      </div>
    </div>
  );
}
PassSummary.defaultProps = {
  editLink: '',
  config: defaultConfig,
  title: 'Community Access Pass',
};
