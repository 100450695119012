import { gql } from '@apollo/client';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';

export const GET_RENATLS = gql`
  query GetPassesByStatus($status: String!) {
    getPassesByStatus(status: $status) {
      success
      error
      data {
        passId
        passInfoId
        passType
        number
        externalCredentialNumber
        startDate
        endDate
      }
    }
  }
`;
export type GET_RENATLS_VARS = {
  status: string;
};
export type GET_RENATLS_RES = {
  getPassesByStatus: backendResponse<
    {
      passId: string;
      passInfoId: string;
      passType: string;
      number: string;
      externalCredentialNumber: string;
      startDate: string;
      endDate: string;
    }[]
  >;
};

export const GET_VEHICLE = gql`
  query GetVehicle($passId: String!) {
    getVehicle(passId: $passId) {
      success
      error
      data {
        vehicleId
        make
        vehicleModel
        type
        color
        licensePlate
        fleetNumber
        isRental
      }
    }
  }
`;
export type GET_VEHICLE_VARS = {
  passId: string;
};
export type GET_VEHICLE_RES = {
  getVehicle: backendResponse<{
    vehicleId: string;
    make: string;
    vehicleModel: string;
    type: string;
    color: string;
    licensePlate: string;
    fleetNumber: string;
    isRental: boolean;
  }>;
};

export const EDIT_RENTAL = gql`
  mutation EditVehicle(
    $vehicleId: String!
    $passInfoId: String!
    $newVehicleInfo: ActuallyInputTypeVehicleInput!
    $status: String!
    $passId: String!
  ) {
    editVehicle(
      vehicleId: $vehicleId
      newVehicleInfo: $newVehicleInfo
      passInfoId: $passInfoId
    ) {
      success
      error
    }
    editPassStatus(status: $status, passId: $passId) {
      success
      error
    }
  }
`;
export type EDIT_RENTAL_VARS = {
  vehicleId: string;
  passInfoId: string;
  newVehicleInfo: {
    make: string;
    vehicleModel: string;
    type: string;
    color: string;
    licensePlate: string;
    licensePlateState: string;
    fleetNumber: string;
    isRental: boolean;
    primaryDriverName?: string;
  };
  status: string;
  passId: string;
};
export type EDIT_RENTAL_RES = {
  editVehicle: backendResponse<undefined>;
  editPassStatus: backendResponse<undefined>;
};
