import React, { ReactElement, useState, useContext } from 'react';
import { Redirect, useHistory, useLocation, withRouter } from 'react-router-dom';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import PassInfo from '../../../common_lib_front/types/passInfo';
import { PassImageDisplay } from '../../../components/passGenerator/passGenerator';
import { PopUp } from '../../../components/popUp/popUp';
import style from './accessPassDisplay.module.css';

interface LocationProps {
  data: PassInfo;
}

export function PassDetails(): ReactElement {
  const { state } = useLocation<LocationProps>();
  const history = useHistory();
  if (!state) {
    history.goBack();
  }
  const [disableBtns, setDisableBtns] = useState<boolean>(true);
  const [hideBtns, setHideBtns] = useState<boolean>(true);
  const [mounted, setMounted] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<string | { pathname: string; state: any }>('');
  const { communityId } = useContext(CommunityContext);

  // const shareLocation = {
  //   pathname: '/guest/my-passes/share-pass',
  //   state,
  // };

  if (!mounted) {
    // TODO: set styles based on pass status
    switch (state.data.status) {
      case 'expired':
        setDisableBtns(true);
        setHideBtns(true);
        // expired passes style
        break;
      case 'incomplete':
        setDisableBtns(true);
        setHideBtns(false);
        // incomplete passes style
        break;
      case 'active':
        setDisableBtns(false);
        setHideBtns(false);
        // active passes style
        break;
      case 'inactive':
        setDisableBtns(false);
        setHideBtns(false);
        // inactive passes style
        break;
      default:
        setDisableBtns(false);
        setHideBtns(false);
        break;
    }
    setMounted(true);
  }

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <PopUp
      title={state?.data?.passNumber}
      isOpen
      close={() => {
        history.goBack();
      }}
    >
      <div className={style.flexBox}>
        <div className={style.passBox}>
          <div className={style.invisibleBox} />

          {/* <img
            src={`https://access-pass.s3.us-west-2.amazonaws.com/${communityId}/${state.data.number}.png`}
            alt={`Pass Number ${state.data.number}`}
            className={style.passImg}
          /> */}
          <PassImageDisplay passId={state.data.passId} url={state.data.url} />

          <div className={style.btnBox}>
            {/* {communityId !== 'grandcomplex' && (
              <div className={style.btnShare}>
                <GenericButton
                  size="large"
                  color="yellow"
                  title="Share Pass"
                  disabled={disableBtns}
                  hidden={hideBtns}
                  clickHandler={() => setRedirect(shareLocation)}
                />
              </div>
            )} */}
            <a
              className={style.btnDownload}
              href={`https://access-pass.s3.us-west-2.amazonaws.com/${communityId}/${state?.data?.passNumber}.png`}
            >
              <GenericButton
                color="blue"
                size="large"
                title="Download"
                disabled={disableBtns}
                hidden={hideBtns}
              />
            </a>
          </div>
        </div>
      </div>
    </PopUp>
  );
}
export default withRouter(PassDetails);
