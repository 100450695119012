import { InfoConfigValues } from './InfoConfigValues';
import UserInfo, { newUserInfo } from './userInfo';

export interface GuestInfo extends UserInfo {
  // userId: string,
  // firstName: string,
  // lastName: string,
  // email: string,
  // phoneNumber: string,
  // emergencyContact: string,
  // address: string,
  // country: string,
  // city: string,
  // state: string,
  // zipCode: string,
  guestInfoId: string;
  rentalAddress: string;
  arrivalDate: string;
  departureDate: string;
  numberGuests: string;
  communityRentalId: string;
  registrationId?: string;
}
export type GuestInfoConfig = {
  [Property in keyof Omit<
    GuestInfo,
    'userId' | 'guestInfoId' | 'roles' | 'companyName'
  >]: keyof typeof InfoConfigValues;
};

export function newGuestInfo(data: Partial<GuestInfo> = {}): GuestInfo {
  return {
    ...newUserInfo(data),
    guestInfoId: data?.guestInfoId || '',
    rentalAddress: data?.rentalAddress || '',
    arrivalDate: data?.arrivalDate || '',
    departureDate: data?.departureDate || '',
    numberGuests: data?.numberGuests || '',
    communityRentalId: data?.communityRentalId || '',
    registrationId: data?.registrationId || '',
  };
}

export default GuestInfo;
