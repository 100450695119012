import React, { ReactElement, useEffect } from 'react';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import style from './numberInput.module.css';

type NumberInputProps = {
  value: number;
  id: string;
  onChange: (val: number) => void;
  min?: number;
  max?: number;
  required?: boolean;
  disabled?: boolean;
};

export const NumberInput = (props: NumberInputProps): ReactElement => {
  const { value, id, onChange, min, max, required, disabled } = props;

  useEffect(() => {
    if (Number.isNaN(value)) {
      onChange(0);
    } else if (min !== undefined && value < min) {
      onChange(min);
    } else if (max !== undefined && value > max) {
      onChange(max);
    }
  }, [value, max, min, onChange]);

  return (
    <div className={style.numberBox}>
      <GenericButton
        color="blue"
        shape="left-circle"
        title="-"
        clickHandler={() => onChange(props.value - 1)}
        disabled={disabled}
      />
      <input
        className={style.input}
        id={id}
        type="number"
        value={value}
        required={required}
        onChange={e => onChange(parseInt(e.target.value, 10))}
        disabled={disabled}
      />
      <GenericButton
        color="blue"
        shape="right-circle"
        title="+"
        clickHandler={() => onChange(props.value + 1)}
        disabled={disabled}
      />
    </div>
  );
};
NumberInput.defaultProps = {
  min: undefined,
  max: undefined,
  required: undefined,
  disabled: false,
};
