import React, { ReactElement, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import InputField from '../../common_lib_front/components/inputField/inputField';
import PhoneInputWrapper from '../../common_lib_front/components/phoneInputWrapper/phoneInputWrapper';
import { GuestInfoConfig } from '../../common_lib_front/types/guestInfo';
import UserInfo from '../../common_lib_front/types/userInfo';
import CountrySelect from '../countrySelect/CountrySelect';
import UsStateSelect from '../usStateSelect/UsStateSelect';
import style from './guestInformationForm.module.css';

type GuestInformationFormProps = {
  data?: UserInfo;
  state: UserInfo;
  updateState: (key: string, val: string) => void;
  config?: GuestInfoConfig;
  immutable?: boolean;
};

const defaultConfig: GuestInfoConfig = {
  communityCode: 'optional',
  status: 'optional',
  firstName: 'required',
  lastName: 'required',
  email: 'disabled',
  phoneNumber: 'required',
  emergencyContact: 'disabled',
  address: 'disabled',
  country: 'disabled',
  city: 'disabled',
  state: 'disabled',
  zipCode: 'disabled',
  rentalAddress: 'disabled',
  arrivalDate: 'disabled',
  departureDate: 'disabled',
  numberGuests: 'disabled',
  communityRentalId: 'disabled',
};
const sipoaConfig: GuestInfoConfig = {
  communityCode: 'optional',
  status: 'optional',
  firstName: 'required',
  lastName: 'required',
  email: 'required',
  phoneNumber: 'required',
  emergencyContact: 'disabled',
  address: 'required',
  country: 'required',
  city: 'required',
  state: 'required',
  zipCode: 'required',
  rentalAddress: 'disabled',
  arrivalDate: 'disabled',
  departureDate: 'disabled',
  numberGuests: 'disabled',
  communityRentalId: 'disabled',
};

const watercolorConfig: GuestInfoConfig = {
  communityCode: 'optional',
  status: 'optional',
  firstName: 'required',
  lastName: 'required',
  email: 'required',
  phoneNumber: 'required',
  emergencyContact: 'disabled',
  address: 'required',
  country: 'required',
  city: 'required',
  state: 'required',
  zipCode: 'required',
  rentalAddress: 'disabled',
  arrivalDate: 'disabled',
  departureDate: 'disabled',
  numberGuests: 'disabled',
  communityRentalId: 'disabled',
};

export const GuestInformationForm = (props: GuestInformationFormProps): ReactElement => {
  const { data, state, updateState, config: propsConfig, immutable } = props;
  const { communityId } = useContext(CommunityContext);
  const config = useMemo(() => {
    if (propsConfig !== undefined) return propsConfig;
    if (communityId === 'sipoa') return sipoaConfig;
    if (communityId === 'watercolor') return watercolorConfig;
    return defaultConfig;
  }, [communityId, propsConfig]);
  const { t } = useTranslation();

  if (!state.country) {
    updateState('country', 'United States');
  }

  return (
    <div className={style.infoBox}>
      <h4 className={`${style.title} textColorSuperDark `}>{t('Guest Information')}</h4>
      <p className={`${style.subTitle} textColorSuperDark `}>
        {t('Please provide the required guest information below')}
      </p>
      <div className={style.inputBox}>
        {config?.firstName !== 'disabled' ? (
          <div className={style.inputShort}>
            <InputField
              largeSize
              highlightOnFocus
              htmlFor="first-name-inpt"
              labelTitle={t('First Name')}
              inputType="text"
              inputPlaceholder={data?.firstName}
              inputValue={state.firstName}
              changeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateState('firstName', e.target.value)
              }
              required={config?.firstName === 'required'}
              disabled={immutable}
            />
          </div>
        ) : null}
        {config?.lastName ? (
          <div className={style.inputShort}>
            <InputField
              largeSize
              highlightOnFocus
              htmlFor="last-name-inpt"
              labelTitle={t('Last Name')}
              inputType="text"
              inputPlaceholder={data?.lastName}
              inputValue={state.lastName}
              changeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateState('lastName', e.target.value)
              }
              required={config?.lastName === 'required'}
              disabled={immutable}
            />
          </div>
        ) : null}

        {config?.phoneNumber !== 'disabled' ? (
          <div className={style.inputLong}>
            <PhoneInputWrapper
              standardInput
              inputTitle={t('Cell Phone Number')}
              value={state.phoneNumber}
              required={config?.phoneNumber === 'required'}
              disabled={immutable}
              changeHandler={(val: string) => {
                updateState('phoneNumber', val);
              }}
            />
          </div>
        ) : null}
        {config?.address !== 'disabled' ? (
          <div className={style.inputLong}>
            <InputField
              largeSize
              highlightOnFocus
              htmlFor="guest-home-address-inpt"
              labelTitle={t('Home Address')}
              inputType="text"
              inputPlaceholder={data?.address}
              inputValue={state.address}
              changeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateState('address', e.target.value)
              }
              required={config?.address === 'required'}
              // disabled={immutable}
            />
          </div>
        ) : null}

        {config?.city !== 'disabled' ? (
          <div className={style.inputShort}>
            <InputField
              largeSize
              highlightOnFocus
              htmlFor="city-inpt"
              labelTitle={t('City')}
              inputType="text"
              inputPlaceholder={data?.city}
              inputValue={state.city}
              changeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateState('city', e.target.value)
              }
              required={config?.city === 'required'}
              // disabled={immutable}
            />
          </div>
        ) : null}

        {config?.country !== 'disabled' ? (
          <div className={style.inputShort}>
            <CountrySelect
              title={t('Country')}
              htmlFor="country-inpt"
              value={state?.country}
              changeHandler={(e: React.ChangeEvent<HTMLSelectElement>) =>
                updateState('country', e.target.value)
              }
            />
          </div>
        ) : null}

        {config?.state !== 'disabled' ? (
          <div className={style.inputShort}>
            {!state.country || state.country === 'United States' ? (
              <UsStateSelect
                title={t('State')}
                htmlFor="state-inpt"
                value={state.state}
                changeHandler={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  updateState('state', e.target.value)
                }
                required={config?.state === 'required'}
                // disabled={immutable}
              />
            ) : (
              <InputField
                largeSize
                highlightOnFocus
                htmlFor="state-inpt"
                labelTitle={t('State')}
                inputValue={state.state}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                  updateState('state', e.target.value)
                }
              />
            )}
          </div>
        ) : null}
        {config?.zipCode !== 'disabled' ? (
          <div className={style.inputShort}>
            <InputField
              largeSize
              highlightOnFocus
              htmlFor="zip-inpt"
              labelTitle={
                !state.country || state.country === 'United States'
                  ? t('Zip Code')
                  : t('Zip/Postal code')
              }
              inputType="text"
              formatCode={state.country === 'United States' ? 'zip-strict' : ''}
              inputPlaceholder={data?.zipCode}
              inputValue={state.zipCode}
              changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (state.country !== 'United States' && e.target.validationMessage) {
                  e.target.setCustomValidity('');
                  e.target.reportValidity();
                }
                updateState('zipCode', e.target.value);
              }}
              required={config?.zipCode === 'required'}
              // disabled={immutable}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};
GuestInformationForm.defaultProps = {
  data: undefined,
  config: undefined,
  immutable: false,
};

export default GuestInformationForm;
