import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import { CommunityContext } from '../../communityConfigs/communityContextProvider';
import GenericAlert from '../../components/genericAlert/genericAlert';
import GenericButton from '../../components/genericButton/genericButton';
import InputField from '../../components/inputField/inputField';
import LoadingDiamonds from '../../components/loadingDiamonds/loadingDiamonds';
import store from '../../utilities/store';
import { getRegistration } from './getRegistration';
import style from './magicLinkAuth.module.css';
import { useConfirmCode } from './useConfirmCode';
import { useSendLoginLink } from './useSendLoginLink';
import { useURLParams } from './useURLParams';

type MagicLinkAuthProps = {
  defaultSuccessHref: string;
  registrationSuccessHref?: string;
  allowEmailLookup?: boolean;
  portal: string;
};
export default function MagicLinkAuth(props: MagicLinkAuthProps): React.ReactElement {
  const { defaultSuccessHref, allowEmailLookup, portal, registrationSuccessHref } = props;
  const { bigLogo, communityId, name: communityName } = useContext(CommunityContext);
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>('');
  const [redirect, setRedirect] = useState<string | undefined>();
  const { user, code, nextHref, registrationId } = useURLParams([
    'user',
    'code',
    'nextHref',
    'registrationId',
  ]);
  const [badCode, setBadCode] = useState<boolean>(!code);

  const [sendLoginLink, { data: sllData, loading: sllLoading, error: sllError }] =
    useSendLoginLink({});

  // check code
  const { loading: ccLoading } = useConfirmCode(portal, {
    variables: {
      code: code || '',
    },
    skip: !code,
    onError: e => {
      console.warn(e);
      setBadCode(true);
    },
    onCompleted: async d => {
      if (d.loginWithCode.error) {
        setBadCode(true);
      }
      if (d.loginWithCode.success) {
        store.dispatch({
          type: 'user/login',
          payload: {
            token: d.loginWithCode.token,
            user: d.loginWithCode.data,
          },
        });
        if (registrationId && registrationSuccessHref) {
          const registration = await getRegistration({ registrationId });
          if (registration.data.getRegistration.data?.[0]?.complete === false) {
            setRedirect(`${registrationSuccessHref}/${registrationId}`);
            return;
          }
        }
        setRedirect(nextHref || defaultSuccessHref);
      }
    },
  });

  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return (
    <div className={style.container}>
      {/* redirect if user param is missing and email lookup is not allowed */}
      {!user && !allowEmailLookup && !registrationId && badCode && (
        // <Redirect to="/login" />
        <></>
      )}
      <div className={`${style.box} white `}>
        <div className={`${style.box__left}  linerGradient `}>
          <img
            src={bigLogo}
            alt=""
            className={
              [
                'watercolor',
                'oceanlakes',
                'isleworth',
                'pinnacleport',
                'debordieu',
              ].includes(communityId)
                ? style.box__imgPostion
                : style.box__img
            }
          />
        </div>
        <form
          className={style.box__right}
          onSubmit={(e: React.FormEvent) => {
            e.preventDefault();
            // TODO: form handler
          }}
        >
          <div className={style.box__titleBoxRight}>
            <h3 className={`${style.box__mainTitleRight} textColorDark `}>
              {t(`Welcome to ${communityName} ${portal} registration.`)}
            </h3>
            {badCode && (
              <p className={`${style.box__paragraph} textColorMedium `}>
                Please click the button below, and we will send a login link to you.
              </p>
            )}
          </div>

          {/* <h5 className={`${style.box__subTitle} textColorDark `}>
            {t('Please log in to your account')}
          </h5> */}
          <div className={style.alert}>
            <div className={style.alertBox}>
              <GenericAlert
                color="green"
                title={
                  'A login email has been sent to you. If you are having trouble finding the email, please check your spam folder.'
                }
                hidden={!sllData?.sendLoginLink.data?.emailSuccess}
              />
              <GenericAlert
                color="green"
                title={'A login sms message has been sent to your device.'}
                hidden={!sllData?.sendLoginLink.data?.textSuccess}
              />
              <GenericAlert
                color="red"
                title="Something went wrong. Could not send your login link."
                hidden={!sllError}
              />
              <GenericAlert
                color="red"
                title={sllData?.sendLoginLink.error}
                hidden={!sllData?.sendLoginLink.error}
              />
            </div>
          </div>
          {badCode && (
            <div className={style.inputLong}>
              {!user && allowEmailLookup && (
                <div className={style.box__inputLong}>
                  <div className={style.box__inputField}>
                    <InputField
                      smallSize
                      htmlFor="email-inpt"
                      labelTitle="Email Address"
                      inputType="email"
                      inputValue={email}
                      highlightOnFocus
                      changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </div>
                </div>
              )}
              <div className={style.box__inputLong}>
                <div className={style.box__inputField}>
                  {ccLoading || sllLoading ? (
                    <>
                      <LoadingDiamonds />
                      <br />
                    </>
                  ) : (
                    <GenericButton
                      size="large"
                      color="blue"
                      title="Get new link"
                      type="button"
                      clickHandler={() => {
                        sendLoginLink({
                          variables: {
                            userId: user || undefined,
                            registrationId: registrationId || undefined,
                          },
                        });
                      }}
                    />
                  )}
                </div>
              </div>
              <p>
                Already have an account and know your password?{' '}
                <Link to="/login">Login here.</Link>
              </p>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}
