import React, { ReactElement } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

type LoginRedirectProps = {
  loginHref?: string;
};
export default function LoginRedirect(props: LoginRedirectProps): ReactElement {
  const { loginHref } = props;
  const location = useLocation();
  sessionStorage.setItem('login-next-href', location.pathname);

  return <Redirect to={loginHref || '/login'} />;
}
LoginRedirect.defaultProps = {
  loginHref: undefined,
};
