import React, { ReactElement } from 'react';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import PassInfo from '../../../common_lib_front/types/passInfo';
import { PassImageDisplay } from '../../../components/passGenerator/passGenerator';
import { PopUp } from '../../../components/popUp/popUp';
import { SharePassFormCollection } from '../../../components/sharePassForm/sharePassFormCollection';
import style from './accessPassDisplay.module.css';

interface LocationProps {
  data: PassInfo;
}

export function SharePass(): ReactElement {
  const { state } = useLocation<LocationProps>();
  const history = useHistory();

  if (!state) {
    history.goBack();
  }

  window.scrollTo(0, 0);
  return (
    <PopUp
      title={`${state?.data?.passNumber}`}
      isOpen
      close={() => {
        history.goBack();
      }}
    >
      <div className={style.flexBox}>
        <div className={style.passForm}>
          <div className={style.innerBox}>
            <SharePassFormCollection passId={state?.data?.passId} />
          </div>
        </div>
        <div className={style.passBox}>
          {/* <PassGenerator pass={state.data} /> */}
          <PassImageDisplay
            passId={state?.data?.passId}
            url={state?.data?.actions?.url}
          />
        </div>
      </div>
    </PopUp>
  );
}
export default withRouter(SharePass);
