import { gql, useQuery } from '@apollo/client';
import { ReactElement, createContext, useContext, useEffect, useMemo } from 'react';
import { Redirect, Route, Switch, useLocation, useParams } from 'react-router-dom';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';
import PassInfo, { newPassInfo } from '../../../common_lib_front/types/passInfo';
import CommunityRules from '../communityRules/communityRules';
import CreateRegistration from '../createRegistration/createRegistration';
import Receipt from '../receipt/receipt';
import RegisterVehicle from '../registerVehicle/registerVehicle';
import RegistrationHome from '../registrationHome/registrationHome';
import RequestWristband from '../requestWristband/requestWristband';
import SecureCheckout from '../secureCheckout/secureCheckout';

export class RecalculateStepsEvent extends Event {
  static label = 'recalculate-registration-steps';
  readonly wait: number;

  constructor(wait = 0) {
    super(RecalculateStepsEvent.label, {
      bubbles: true,
    });
    this.wait = wait;
  }
}

const GET_REGISTRATION = gql`
  query GetRegistration($registrationId: String!) {
    getRegistration(registrationId: $registrationId) {
      success
      error
      data {
        complete
        stepNumber
      }
    }
    getPassesByRegistration(registrationId: $registrationId) {
      success
      error
      data {
        passId
        paid
      }
    }
  }
`;

type GET_REGISTRATION_VARS = {
  registrationId: string;
};

type GET_REGISTRATION_RES = {
  getRegistration: backendResponse<{
    complete: boolean;
    stepNumber: number;
  }>;
  getPassesByRegistration: backendResponse<
    {
      passId: string;
      paid: string;
    }[]
  >;
};

export interface RegistrationPageProps {
  nextHref: string;
}

export type PageOptions =
  | 'init'
  | 'guest'
  | 'vehicle'
  | 'wristband'
  | 'education'
  | 'documents'
  | 'checkout'
  // | 'passGeneration'
  | 'receipt';

const defaultSteps: Array<PageOptions> = [
  'init',
  'guest',
  'vehicle',
  // 'wristband',
  'education',
  'documents',
  'checkout',
  'receipt',
];

type PageConfigsType = Record<
  PageOptions,
  {
    href: string;
    path: string;
    patern: RegExp;
    component: (props: RegistrationPageProps) => ReactElement;
  }
>;

type RegistrationWrapperContext = {
  steps: Array<PageOptions>;
  currentStep?: PageOptions;
  pageConfigs?: PageConfigsType;
};

export const RegistrationContext = createContext<RegistrationWrapperContext>({
  steps: defaultSteps,
});

export default function RegistrationWrapper(): ReactElement {
  const { registrationId } = useParams<{ registrationId?: string }>();
  const location = useLocation();

  const { data, refetch } = useQuery<GET_REGISTRATION_RES, GET_REGISTRATION_VARS>(
    GET_REGISTRATION,
    {
      fetchPolicy: 'network-only',
      variables: {
        registrationId: registrationId || '',
      },
      skip: !registrationId,
    },
  );

  const steps = useMemo<PageOptions[]>(() => {
    const res: PageOptions[] = ['guest'];
    res.push('vehicle');
    res.push('education');
    if (
      !data?.getPassesByRegistration.data?.length ||
      data?.getPassesByRegistration.data?.some(
        val => !['paid', 'ach-pending'].includes(val.paid),
      )
    ) {
      res.push('checkout');
      // return ['init', 'guest', 'vehicle', 'education', 'passGeneration', 'receipt'];
      // } else {
      //   res.push('passGeneration');
    }
    res.push('receipt');
    // default
    // return ['init', 'guest', 'wristband', 'vehicle', 'education', 'checkout', 'receipt'];
    return res;
  }, [data]);

  const pageConfigs = useMemo(
    (): PageConfigsType => ({
      init: {
        href: '/guest/registration',
        path: '/guest/registration',
        patern: /guest\/registration($|\/$|#)/,
        component: CreateRegistration,
      },
      guest: {
        href: `/guest/registration/${registrationId}`,
        path: '/guest/registration/:registrationId',
        patern: /guest\/registration\/.*($|\/$|#)/,
        component: RegistrationHome,
      },
      vehicle: {
        href: `/guest/registration/${registrationId}/vehicle`,
        path: '/guest/registration/:registrationId/vehicle',
        patern: /guest\/registration\/.*\/vehicle($|\/$|#)/,
        component: RegisterVehicle,
      },
      wristband: {
        href: `/guest/registration/${registrationId}/wristband`,
        path: '/guest/registration/:registrationId/wristband',
        patern: /guest\/registration\/.*\/wristband($|\/$|#)/,
        component: RequestWristband,
      },
      education: {
        href: `/guest/registration/${registrationId}/community-rules`,
        path: '/guest/registration/:registrationId/community-rules',
        patern: /guest\/registration\/.*\/community-rules($|\/$|#)/,
        component: CommunityRules,
      },
      documents: {
        href: `/guest/registration/${registrationId}/documents`,
        path: '/guest/registration/:registrationId/documents',
        patern: /guest\/registration\/.*\/documents($|\/$|#)/,
        // eslint-disable-next-line react/display-name
        component: () => <></>,
      },
      checkout: {
        href: `/guest/registration/${registrationId}/secure-checkout`,
        path: '/guest/registration/:registrationId/secure-checkout',
        patern: /guest\/registration\/.*\/secure-checkout($|\/$|#)/,
        component: SecureCheckout,
      },
      // passGeneration: {
      //   href: `/guest/registration/${registrationId}/generating-passes`,
      //   path: '/guest/registration/:registrationId/generating-passes',
      //   patern: /guest\/registration\/.*\/generating-passes($|\/$|#)/,
      //   component: GeneratingPasses,
      // },
      receipt: {
        href: `/guest/registration/${registrationId}/receipt`,
        path: '/guest/registration/:registrationId/receipt',
        patern: /guest\/registration\/.*\/receipt($|\/$|#)/,
        component: Receipt,
      },
    }),
    [registrationId],
  );

  const activePage = useMemo<PageOptions>(() => {
    let res: PageOptions = 'init';
    Object.keys(pageConfigs).forEach(p => {
      if (pageConfigs[p as PageOptions].patern.test(location.pathname)) {
        res = p as PageOptions;
      }
    });
    return res;
  }, [location, pageConfigs]);

  const contextVal = useMemo<Required<RegistrationWrapperContext>>(
    () => ({
      steps,
      currentStep: activePage,
      pageConfigs,
    }),
    [activePage, pageConfigs, steps],
  );

  useEffect(() => {
    const handler = (ev: Event) => {
      if (ev instanceof RecalculateStepsEvent) {
        console.log('refetching passes');
        setTimeout(() => refetch(), ev.wait);
      }
    };

    document.addEventListener(RecalculateStepsEvent.label, handler);
    return () => document.removeEventListener(RecalculateStepsEvent.label, handler);
  }, [refetch]);

  return (
    <RegistrationContext.Provider value={contextVal}>
      <Switch>
        {steps.map((step, stepIdx) => (
          <Route
            key={step}
            exact
            path={pageConfigs[step].path}
            component={() =>
              pageConfigs[step].component({
                nextHref: steps[stepIdx + 1] ? pageConfigs[steps[stepIdx + 1]].href : '/',
              })
            }
          />
        ))}
        {/* backup no matching url route */}
        <Route path="/">
          <Redirect to="/" />
        </Route>
      </Switch>
    </RegistrationContext.Provider>
  );
}

// helper to read and cache pass payment info
export class PassPaymentCache {
  static storageKey = 'pass-pre-payment-info';

  static push(data: PassInfo[]): void {
    sessionStorage.setItem(this.storageKey, JSON.stringify(data));
  }

  static read(): PassInfo[] | null {
    const res = JSON.parse(sessionStorage.getItem(this.storageKey) || '');
    if (res instanceof Array) {
      return res.map(val => newPassInfo(val));
    }
    return null;
  }
}
