import { gql, useQuery } from '@apollo/client';
import html2canvas from 'html2canvas';
import QRCode from 'qrcode';
import React, { ReactElement, createRef, useContext, useEffect, useState } from 'react';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import GenericAlert from '../../common_lib_front/components/genericAlert/genericAlert';
import LoadingDiamonds from '../../common_lib_front/components/loadingDiamonds/loadingDiamonds';
import useGetPassImage from '../../common_lib_front/hooks/useGetPassImage';
import PassInfo from '../../common_lib_front/types/passInfo';
import { formatDate } from '../../common_lib_front/utilities/formatDate';
import { passCredOrNumber } from '../../common_lib_front/utilities/passCredOrNumber';
import style from './passGenerator.module.css';

const GET_GUEST_INFO = gql`
  query GetGuestInfo($registrationId: String!) {
    getGuestInfoData(registrationId: $registrationId) {
      success
      error
      data {
        firstName
        lastName
      }
    }
  }
`;

interface Props {
  pass: PassInfo;
}

function useQrImg(
  data: string,
  setLoadingState: React.Dispatch<React.SetStateAction<boolean>>,
) {
  const [img, setImg] = useState('');
  useEffect(() => {
    setLoadingState(true);
    QRCode.toDataURL(String(data), (err, url) => {
      if (err) {
        console.warn(err); // eslint-disable-line no-console
      }
      setImg(url);
      setLoadingState(false);
    });
  }, [data, setLoadingState]);
  return [img];
}

function BackupPassGenerator(props: Props): ReactElement {
  const { pass } = props;
  const { passLogo, name: communityName } = useContext(CommunityContext);
  const [imageLoadingState, setImageLoadingState] = useState<boolean>(false);
  const [qrImg] = useQrImg(passCredOrNumber(pass), setImageLoadingState);
  const { data } = useQuery(GET_GUEST_INFO, {
    variables: {
      registrationId: pass.registrationId,
    },
  });
  if (imageLoadingState) {
    return <LoadingDiamonds />;
  }

  return (
    <div className={style.container}>
      <div className={`${style.pass} white `}>
        <div className={style.logoBox}>
          <img className={style.logo} src={passLogo} alt="Community Access Pass" />
        </div>
        <h3 className={`${style.title} textColorSuperDark `}>
          {communityName === 'Grand Complex'
            ? 'Pass Type:Grand Complex Garage Parking Pass'
            : 'Pass Type: Community Access Pass'}
        </h3>
        <p className={style.subtitle}>Authorized by {communityName}</p>
        <div className={style.boxTop}>
          <img className={style.qrCode} src={qrImg} alt="" />
        </div>
        <div className={style.timeBox}>
          <div className={style.validFromBox}>
            <h5 className={style.validFrom}>Valid From:</h5>
            <h5 className={`${style.validFromItems} textColorSuperDark `}>
              {formatDate(new Date(pass.startDate))}
            </h5>
          </div>
          <div className={style.validToBox}>
            <h5 className={style.validFrom}>Valid To:</h5>
            <p className={`${style.validFromItems} textColorSuperDark `}>
              {formatDate(new Date(pass.endDate))}
            </p>
          </div>
        </div>
        <div className={style.infoBox}>
          <div className={style.Row}>
            <div className={style.info}>
              <h3 className={style.name}>Name:</h3>
              <h3 className={`${style.nameitem} textColorSuperDark `}>{`${
                data?.getGuestInfoData?.data?.firstName || ''
              } ${data?.getGuestInfoData?.data?.lastName || ''}`}</h3>
            </div>
            <div className={style.invisiableBox} />
            <div className={style.info}>
              <h3 className={style.contentTitle}>License Plate:</h3>
              <p className={`${style.content} textColorSuperDark `}>
                {pass.vehicle?.plateNumber}
              </p>
            </div>
          </div>
          <div className={style.Row}>
            <div className={style.info}>
              <h3 className={style.contentTitle}>Make:</h3>
              <p className={`${style.content} textColorSuperDark `}>
                {pass.vehicle?.make}
              </p>
            </div>
            <div className={style.invisiableBox} />
            <div className={style.info}>
              <h3 className={style.contentTitle}>Type:</h3>
              <p className={`${style.content} textColorSuperDark `}>
                {pass.vehicle?.type}
              </p>
            </div>
          </div>
          <div className={style.Row}>
            <div className={style.info}>
              <h3 className={style.contentTitle}>Color:</h3>
              <p className={`${style.content} textColorSuperDark `}>
                {pass.vehicle.color}
              </p>
            </div>
            <div className={style.invisiableBox} />
            <div className={style.info}>
              <h3 className={style.contentTitle}>Pass Number:</h3>
              <p className={`${style.content} textColorSuperDark `}>
                {passCredOrNumber(pass)}
              </p>
            </div>
          </div>
        </div>
        <p className={`${style.footerTitle} textColorMedium `}>
          {' '}
          {communityName} reserves the right to exclude any person or vehicle from
          property at any time
        </p>
      </div>
    </div>
  );
}

export default function PassGenerator(props: Props): ReactElement {
  const { pass } = props;
  const [useUrl, setUseUrl] = useState<boolean>(true);
  const [imageLoadingState, setImageLoadingState] = useState<boolean>(false);
  const [imageData] = useQrImg(pass.url, setImageLoadingState);
  if (pass.url && useUrl) {
    if (imageLoadingState) {
      return <LoadingDiamonds />;
    }
    return (
      <div className={style.passImgBox}>
        <img
          src={imageData}
          alt="My access pass"
          className={style.passImg}
          onError={() => {
            setUseUrl(false);
          }}
        />
      </div>
    );
  }
  return <BackupPassGenerator pass={pass} />;
}

export function PassImageDisplay(props: {
  passId: string;
  url?: string;
  clickDownload?: boolean;
  hideImage?: boolean;
}): ReactElement {
  const { passId, url, clickDownload, hideImage } = props;
  const { data, loading, error } = useGetPassImage(passId, !!url);
  const passUrl = url || data?.url || '';

  if (loading) {
    return <LoadingDiamonds />;
  }
  if (hideImage) {
    return <></>;
  }

  return (
    <div className={style.passImgBoxs}>
      <GenericAlert title={error} color="red" hidden={!error} />
      <a
        href={passUrl}
        download
        className={clickDownload ? '' : style.noClick}
        onClick={e => {
          if (!clickDownload || !passUrl) {
            e.preventDefault();
          }
        }}
      >
        <img
          src={passUrl === data?.url ? `${passUrl}?${Date.now()}` : passUrl}
          alt="My access pass"
          className={style.passImg}
        />
      </a>
    </div>
  );
}

export function downloadablePassGenerator(props: Props): [ReactElement, () => void] {
  const ref = createRef<HTMLDivElement>();
  // const [image, takeScreenshot] = useScreenshot();
  const { pass } = props;
  return [
    <div id="download-pass-container" ref={ref} key="download-container">
      {/* <PassGenerator pass={pass} /> */}
      <PassImageDisplay passId={pass.passId} url={pass.url} />
    </div>,
    () => {
      const container = document.getElementById('download-pass-container');
      if (container) {
        html2canvas(container).then(canvas => {
          const link = document.createElement('a');
          link.download = 'access-pass.png';
          canvas.toBlob(blob => {
            if (!blob) return;
            link.href = URL.createObjectURL(blob);
            link.click();
          });
        });
      }
    },
  ];
}
