import { gql, useLazyQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import useContainerBlur from '../../common_lib_front/utilities/useContainerBlur';
import { SearchSelectorProps } from './searchSelector';

const ADDRESSES_VALIDATION = gql`
  query AddressValidation($communitySymbol: String!, $address: String!) {
    addressValidation(communitySymbol: $communitySymbol, address: $address) {
      success
      error
      data {
        address
      }
    }
  }
`;

export default function useSearchSelector(props: SearchSelectorProps): {
  open: boolean;
  setOpen: (val: boolean) => void;
  optionsList: Array<string>;
  parentId: string;
  showWarning: boolean;
  checkWarning: () => void;
  optionSelectHandler: (val: string) => void;
  innerChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
} {
  const { htmlFor, value, changeHandler } = props;
  const { communityId } = useContext(CommunityContext);

  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [optionsList, setOptionsList] = useState<Array<string>>([]);
  const [isValidated, setIsValidated] = useState<boolean>(false);
  const {
    open,
    setOpen,
    containerId: parentId,
  } = useContainerBlur(`container-parent-${htmlFor}`);

  const optionSelectHandler = (val: string) => {
    changeHandler(val);
    setOpen(false);
    setIsValidated(true);
    setShowWarning(false);
  };

  const innerChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOpen(true);
    changeHandler(e.target.value);
    setIsValidated(false);
  };

  const checkWarning = () => {
    let localIsVal = isValidated;
    if (optionsList.some((elem: string) => elem === value)) {
      setIsValidated(true);
      localIsVal = true;
    }
    if (localIsVal) {
      setShowWarning(false);
    } else {
      setShowWarning(true);
    }
  };

  const addressList1: any = [];

  const [getAddresses] = useLazyQuery(ADDRESSES_VALIDATION, {
    fetchPolicy: 'no-cache',
    variables: {
      communitySymbol: process.env.REACT_APP_DEBUG === 'true' ? 'tc' : communityId,
      address: value,
    },
    onError: () => {
      if (process.env.REACT_APP_DEBUG === 'true') {
        setOptionsList(addressList1.filter((a: string) => a.includes(value)));
      }
    },
    onCompleted: d => {
      if (d.addressValidation.success) {
        const res = d.addressValidation.data.map(
          (elem: { address: string }) => elem.address,
        );
        setOptionsList(res);
      } else {
        setOptionsList([]);
      }
    },
  });

  useEffect(() => {
    if (value.length > 1) {
      getAddresses();
      // .catch(reason => {
      //   console.warn('Caught error: ', reason);
      // });
    }
  }, [value, getAddresses]);

  return {
    open,
    setOpen,
    optionsList,
    parentId,
    showWarning,
    checkWarning,
    optionSelectHandler,
    innerChangeHandler,
  };
}
