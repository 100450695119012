import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import MyInfoForm from '../../../components/myInfoForm/myInfoForm';
import style from './myProfile.module.css';

export default function MyProfile(): ReactElement {
  const { t } = useTranslation();
  return (
    <div>
      <div className={`${style.subHeader} mainColor `}>
        <div className={`${style.circle} white `} />
        <h1 className={`${style.title} textColorWhite `}>{t('My Profile')}</h1>
      </div>
      <div className={style.form}>
        <MyInfoForm />
      </div>
    </div>
  );
}
