import { gql } from '@apollo/client';
import { useCallback, useState } from 'react';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';
import { backendClient } from '../../../common_lib_front/utilities/BackendAPI';

const EDIT_VEHICLE = gql`
  mutation EditVehicle(
    $vehicleId: String!
    $passInfoId: String!
    $newVehicleInfo: ActuallyInputTypeVehicleInput!
  ) {
    editVehicle(
      vehicleId: $vehicleId
      newVehicleInfo: $newVehicleInfo
      passInfoId: $passInfoId
    ) {
      success
      error
    }
  }
`;

type EDIT_VEHICLE_VARS = {
  vehicleId?: string;
  newVehicleInfo: {
    make?: string;
    type?: string;
    color?: string;
    licensePlate?: string;
    year?: number;
    isPassEditPage?: boolean;
    primaryDriverName?: string;
  };
  passInfoId: string;
};

type EDIT_VEHICLE_RES = {
  editVehicle: backendResponse<undefined>;
};

type editPassData = {
  primaryDriverName?: string;
  plateNumber?: string;
  color?: string;
  type?: string;
  make?: string;
  year?: number;
  licensePlate?: string;
  vehicleId?: string;
  isPassEditPage?: boolean;
  passId?: string;
  startDate?: string;
  endDate?: string;
  firstName?: string;
  lastName?: string;
};

type usePassEdit = {
  submit: (
    data: editPassData,
  ) => Promise<EDIT_VEHICLE_RES | REGISTER_VEHICLE_RES | AMENITY_PASS__RES>;
  loading: boolean;
  error?: string;
};

function mutateWrapper(data: EDIT_VEHICLE_VARS): Promise<EDIT_VEHICLE_RES> {
  return backendClient
    .mutate<EDIT_VEHICLE_RES, EDIT_VEHICLE_VARS>({
      mutation: EDIT_VEHICLE,
      variables: data,
    })
    .then((res: any) => res.data);
}

export const REGISTER_VEHICLE = gql`
  mutation SaveVehicle(
    $passId: String!
    $passInfoId: String!
    $vehicleInfo: ActuallyInputTypeVehicleInput!
  ) {
    registerVehicle(passId: $passId, passInfoId: $passInfoId, vehicleInfo: $vehicleInfo) {
      success
      error
    }
  }
`;

export type REGISTER_VEHICLE_VARS = {
  passId: string;
  passInfoId: string;
  vehicleInfo: Partial<editPassData>;
};

export type REGISTER_VEHICLE_RES = {
  registerVehicle: backendResponse<undefined>;
};

function mutateWrapperForVehiclePass(
  data: REGISTER_VEHICLE_VARS,
): Promise<REGISTER_VEHICLE_RES> {
  return backendClient
    .mutate<REGISTER_VEHICLE_RES, REGISTER_VEHICLE_VARS>({
      mutation: REGISTER_VEHICLE,
      variables: data,
    })
    .then((res: any) => res.data);
}

const EDIT_AMENITY_PASS = gql`
  mutation editPass($passId: String!, $newInfo: PassInput) {
    editPass(passId: $passId, newInfo: $newInfo) {
      success

      error
    }
  }
`;

type EDIT_EMENITY_PASS_VAR = {
  newInfo: {
    startDate: string;
    endDate: string;
    passInfoId: string;
  };
  passId: any;
};

type EDIT_VEHICLE_PASS_VAR = {
  newInfo: {
    startDate: string;
    endDate: string;
    passInfoId: string;
  };
  passId: any;
};

type AMENITY_PASS__RES = {
  editPass: backendResponse<undefined>;
  editVehicle: backendResponse<undefined>;
};

function mutateWrapperForAmenityPass(
  data: EDIT_EMENITY_PASS_VAR,
): Promise<AMENITY_PASS__RES> {
  return new Promise(async resolve => {
    await backendClient
      .mutate<AMENITY_PASS__RES, EDIT_VEHICLE_PASS_VAR>({
        mutation: EDIT_AMENITY_PASS,
        variables: {
          passId: data.passId,
          newInfo: data.newInfo,
        },
      })
      .then((res: any) => resolve(res.data));
  });
}

export default function usePassEdit(props: {
  passInfoId: string;
  passId: string;
  state: {
    data: {
      activeTab: string;
    };
  };
}): usePassEdit {
  const { passInfoId, passId, state } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();

  const submit: usePassEdit['submit'] = useCallback(
    async (data: editPassData) => {
      setLoading(true);
      if (state.data.activeTab !== 'amenity') {
        if (data.vehicleId) {
          return mutateWrapper({
            vehicleId: data.vehicleId,
            newVehicleInfo: {
              make: data.make || '',
              type: data.type || '',
              color: data.color || '',
              licensePlate: data.licensePlate || '',
              year: data.year || 0,
              isPassEditPage: true,
            },
            passInfoId: passInfoId,
          })
            .then(res => {
              if (!res) {
                setError('Result does not exist. Somehting went wrong');
                // throw Error('Result does not exist. Somehting went wrong');
              }
              if (res.editVehicle.error) {
                setError(res.editVehicle.error);
                throw res.editVehicle.error;
              } else {
                setError(undefined);
              }
              return res;
            })
            .catch(res => {
              setError(
                typeof res === 'string'
                  ? res
                  : 'Something went wrong. Changes could not be saved.',
              );
              // return res;
              throw res;
            })
            .finally(() => setLoading(false));
        } else {
          return mutateWrapperForVehiclePass({
            vehicleInfo: {
              make: data.make || '',
              type: data.type || '',
              color: data.color || '',
              licensePlate: data.licensePlate || '',
              year: data.year || 0,
              isPassEditPage: true,
            },
            passInfoId: passInfoId,
            passId: passId,
          })
            .then(res => {
              if (!res) {
                setError('Result does not exist. Somehting went wrong');
                // throw Error('Result does not exist. Somehting went wrong');
              }
              if (res.registerVehicle.error) {
                setError(res.registerVehicle.error);
                throw res.registerVehicle.error;
              } else {
                setError(undefined);
              }
              return res;
            })
            .catch(res => {
              setError(
                typeof res === 'string'
                  ? res
                  : 'Something went wrong. Changes could not be saved.',
              );
              throw res;
            })
            .finally(() => setLoading(false));
        }
      } else {
        return mutateWrapperForAmenityPass({
          newInfo: {
            startDate: data.startDate || '',
            endDate: data.endDate || '',
            passInfoId: passInfoId,
          },
          passId: passId,
        })
          .then(res => {
            if (!res) {
              setError('Result does not exist. Somehting went wrong');
              // throw Error('Result does not exist. Somehting went wrong');
            }
            if (res?.editPass?.error) {
              setError(res.editPass.error);
              throw res.editPass.error;
            } else {
              setError(undefined);
            }
            if (data.vehicleId) {
              return mutateWrapper({
                vehicleId: data.vehicleId,
                newVehicleInfo: {
                  primaryDriverName: data.primaryDriverName || '',
                },
                passInfoId: passInfoId,
              })
                .then(res => {
                  if (!res) {
                    setError('Result does not exist. Somehting went wrong');
                    // throw Error('Result does not exist. Somehting went wrong');
                  }
                  if (res.editVehicle.error) {
                    setError(res.editVehicle.error);
                    throw res.editVehicle.error;
                  } else {
                    setError(undefined);
                  }
                  return res;
                })
                .catch(res => {
                  setError(
                    typeof res === 'string'
                      ? res
                      : 'Something went wrong. Changes could not be saved.',
                  );
                  // return res;
                  throw res;
                })
                .finally(() => setLoading(false));
            }
            return res;
          })
          .catch(res => {
            // console.log(res);
            setError(
              typeof res === 'string'
                ? res
                : 'Something went wrong. Changes could not be saved.',
            );
            throw res;
          })
          .finally(() => setLoading(false));
      }
    },
    [setLoading],
  );

  return {
    loading,
    submit,
    error,
  };
}
