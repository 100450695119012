import React, { ReactElement } from 'react';
import { PopUp } from '../../../components/popUp/popUp';
import style from './shareHistory.module.css';
import useShareHistory from './useShareHistory';

export default function ShareHistory(): ReactElement {
  const { history, data } = useShareHistory();

  return (
    <PopUp title="Pass Shared" close={() => history.goBack()} isOpen>
      <div className={style.shareBox}>
        <h1 className={style.title}>This pass has been shared with:</h1>
        {data?.sharePassHistory?.data?.length > 0
          ? data?.sharePassHistory?.data.map(
              (elem: {
                firstName: string;
                lastName: string;
                email: string;
                phone: string;
              }) => (
                <div key={elem.email} className={style.name}>
                  {`${elem.firstName} ${elem.lastName}`}
                </div>
              ),
            )
          : 'This pass has not been shared yet'}
      </div>
    </PopUp>
  );
}
