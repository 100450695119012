import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseImg } from '../../assets/close.svg';
import userSharePass from '../../assets/userSharePass.svg';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import InputField from '../../common_lib_front/components/inputField/inputField';
import PhoneInputWrapper from '../../common_lib_front/components/phoneInputWrapper/phoneInputWrapper';
import UserInfo from '../../common_lib_front/types/userInfo';
import style from './sharePassFormCollection.module.css';

type SharePassFormProps = {
  data: UserInfo;
  update: (obj: any) => void;
  remove: () => void;
  idx: number;
};

export const SharePassForm = (props: SharePassFormProps): ReactElement => {
  const [disablePhone, setDisablePhone] = useState(true);
  const [disableEmail, setDisableEmail] = useState(false);
  const { t } = useTranslation();

  const { data, update, remove, idx } = props;

  let exit;
  if (idx === 0) {
    exit = style.exitHidden;
  }
  if (idx !== 0) {
    exit = style.exit;
  }

  const checkHandler = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    if (key === 'phone') {
      setDisablePhone(!e.target.checked);
      if (!e.target.checked) {
        update({ phone: '' });
      }
    } else if (key === 'email') {
      setDisableEmail(!e.target.checked);
      if (!e.target.checked) {
        update({ email: '' });
      }
    }
  };
  return (
    <div className={`${style.box} white `}>
      <div className={`${style.header} mainColor `}>
        <img src={userSharePass} alt="" />
        <h5 className={`${style.title} textColorWhite `}>
          {t('Recipient')} {idx + 1}
        </h5>
        <div className={exit}>
          <GenericButton
            icon={() => <CloseImg className={style.exitBtnSmallImg} />}
            title=""
            clickHandler={() => {
              remove();
            }}
          />
        </div>
      </div>
      <div className={style.infoBox}>
        <h5 className={style.text}>{t('pass_share_prompt')}</h5>
        <div className={style.inputArea}>
          <div className={style.inputBox}>
            <div className={style.checkBoxInvisible} />
            <div className={style.inputShort}>
              <InputField
                smallSize
                highlightOnFocus
                htmlFor={`first-name-inpt-${idx}`}
                labelTitle={t('First Name')}
                inputType="text"
                inputValue={data.firstName}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  update({ firstName: e.target.value });
                }}
              />
            </div>
            <div className={style.inputShort}>
              <InputField
                smallSize
                highlightOnFocus
                htmlFor={`last-name-inpt-${idx}`}
                labelTitle={t('Last Name')}
                inputType="text"
                inputValue={data.lastName}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  update({ lastName: e.target.value });
                }}
              />
            </div>
          </div>
          <div className={style.inputLongBox}>
            <form>
              <input
                className={style.checkBox}
                checked={!disableEmail}
                type="checkbox"
                id="email-check-enable"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  checkHandler(e, 'email')
                }
              />
            </form>
            <div className={style.inputLong}>
              <InputField
                smallSize
                highlightOnFocus
                htmlFor={`email-inpt-${idx}`}
                labelTitle={t('Email Address')}
                inputType="email"
                disabled={disableEmail}
                inputValue={data.email}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  update({ email: e.target.value });
                }}
              />
            </div>
          </div>
          <div className={style.inputLongBox}>
            <form>
              <input
                className={style.checkBox}
                checked={!disablePhone}
                type="checkbox"
                id="phone-check-enable"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  checkHandler(e, 'phone')
                }
              />
            </form>
            <div className={style.inputLong}>
              {/* <InputField
                smallSize
                highlightOnFocus
                htmlFor="phone-inpt"
                labelTitle={t('Phone Number')}
                inputType="tel"
                formatCode="tel-strict"
                disabled={disablePhone}
                inputValue={data.phone}
                changeHandler={(e:React.ChangeEvent<HTMLInputElement>) => {
                  update({ phone: e.target.value });
                }}
              /> */}
              <PhoneInputWrapper
                smallStandardInput
                inputTitle="Phone Number"
                value={data.phoneNumber}
                changeHandler={(val: string) => {
                  update({ phoneNumber: val });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SharePassForm;
