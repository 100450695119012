import { QueryHookOptions, gql, useQuery } from '@apollo/client';
import { backendTokenResponse } from '../../types/backendResponse';

export const CONFIRM_CODE = (portal: string) => gql`
  query ConfirmCode($code: String!) {
    loginWithCode(code: $code) {
      success
      error
      token
      data {
        userId
        firstName
        lastName
        email
        roles
        ${portal === 'guest' ? 'isReferredUser' : ''}
        ${portal === 'resident' ? 'fastPassUser' : ''}
        ${portal === 'resident' ? 'profileStatus' : ''}
        ${portal === 'resident' ? 'applicationId' : ''}
      }
    }
  }
`;

export type CONFIRM_CODE_VARS = {
  code: string;
};
export type CONFIRM_CODE_RES = {
  loginWithCode: backendTokenResponse<{
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    roles: string[];
    isReferredUser?: boolean;
    fastPassUser?: boolean;
    applicationId?: string;
    profileStatus?: string;
  }>;
};

type optionsType = QueryHookOptions<CONFIRM_CODE_RES, CONFIRM_CODE_VARS>;

export const useConfirmCode = (portal: string, options: optionsType) =>
  useQuery<CONFIRM_CODE_RES, CONFIRM_CODE_VARS>(CONFIRM_CODE(portal), {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    ...options,
  });
