import { ApolloQueryResult } from '@apollo/client';
import React, { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import useOrderPrice from '../../common_lib_front/hooks/useOrderPrice';
import PassInfo from '../../common_lib_front/types/passInfo';
import { exportPdf } from '../../utils/pdf';
import style from './orderSummary.module.css';

type OrderSummaryProps = {
  passNumbers: Array<string>;
  date: string;
  total?: number;
  method?: string;
  data: PassInfo[];
  refetch: () => Promise<ApolloQueryResult<PassInfo>>;
};

export const OrderSummary = (props: OrderSummaryProps): ReactElement => {
  const { passNumbers, date, method, data, refetch } = props;
  const { totalPrice } = useOrderPrice(
    data.filter(p => !['ach-pending', 'paid'].includes(p.paid)),
  );
  const { t } = useTranslation();
  const { name: communityName, communityId } = useContext(CommunityContext);
  const standardConfirmationEmailMessage =
    'A confirmation email has been sent to your email along with your Community Access Pass';

  const printPdf = async () => {
    refetch().then(async (response) => {
      if (response.data.getPassesByRegistration
        && response.data.getPassesByRegistration.data
        && response.data.getPassesByRegistration.data.length > 0) {
        const pdf = await
        exportPdf(response.data.getPassesByRegistration.data.map((p: PassInfo) => p.url));
        if (pdf) {
          const blob = pdf.output('blob');
          const nextWindow = window.open(URL.createObjectURL(blob), '_blank');
          nextWindow?.focus();
          nextWindow?.print();
        }
      }
    });
  };

  return (
    <div className={style.infoBox}>
      <h3 className={`${style.title} textColorSuperDark `}>{t('Order Summary')}</h3>
      <p className={`${style.text} textColorSuperDark `}>
        {communityName === 'Grand Complex'
          ? 'A confirmation email has been sent to your email along with your Garage Parking Pass'
          : t(standardConfirmationEmailMessage)}
      </p>
      <table className={style.tableBox}>
        <tr>
          <th>
            {communityName === 'Grand Complex'
              ? 'Parking Pass Number:'
              : `${t('Community Pass Numbers')}:`}
          </th>
          <td>
            <ul>
              {passNumbers.map(elem => (
                <li key={elem} className={style.passNum}>
                  {elem}
                </li>
              ))}
            </ul>
          </td>
        </tr>
        <tr>
          <th>{`${t('Date')}:`}</th>
          <td>{date}</td>
        </tr>
        {typeof totalPrice === 'number' && (
          <tr>
            <th>{`${t('Purchase Total')}:`}</th>
            <td>{`$${totalPrice}`}</td>
          </tr>
        )}
        {typeof method === 'string' && (
          <tr>
            <th>{`${t('Payment Method')}:`}</th>
            <td>{method}</td>
          </tr>
        )}
      </table>
      <div className={style.btnPrint}>
        <div>
          {/* <Link to={`/guest/registration/${registrationId}/receipt/download`}> */}
          <GenericButton
            outline="large"
            title={t('Print Passes & Receipt')}
            clickHandler={() => {
              printPdf().then();
            }}
          />
          {/* </Link> */}
        </div>
      </div>
      <div className={style.btnDownload}>
        {/* <GenericButton
          size="large"
          color="blue"
          title={t('Download Access Pass')}
          clickHandler={() => {
            window.open(data ? data[0].url : '');
          }}
        /> */}
        {communityId !== 'watercolor' && <p>Click the passes below to download.</p>}
      </div>
    </div>
  );
};
