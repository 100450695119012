import { gql, useMutation, useQuery } from '@apollo/client';
import React, { ReactElement, useCallback, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';
import { RegistrationPageProps } from '../wrapper/wrapper';

const GET_REGISTRATION = gql`
  query GetRegistration {
    getRegistrations(complete: false) {
      success
      error
      data {
        registrationId
      }
    }
  }
`;

type GET_REGISTRATION_RES = {
  getRegistrations: backendResponse<
    Array<{
      registrationId: string;
    }>
  >;
};

const CREATE_REGISTRATION = gql`
  mutation CreateRegistration {
    createRegistration {
      success
      error
      data {
        registrationId
      }
    }
  }
`;

type CREATE_REGISTRATION_RES = {
  createRegistration: backendResponse<
    Array<{
      registrationId: string;
    }>
  >;
};

export default function CreateRegistration(props: RegistrationPageProps): ReactElement {
  const { nextHref } = props;
  const [redirect, setRedirect] = useState<string>('');
  // helper to acurately insert registrationId into nexthref
  const parseNextHref = useCallback(
    (registrationId: string): string => {
      // undefined may be a default in the passed href for registrationId
      if (nextHref.includes('undefined')) {
        return nextHref.replace('undefined', registrationId);
      }
      // work with array instead of string
      const link = nextHref.split('/');
      // find location of registrationId based
      const ridLoc = link.indexOf('registration') + 1;
      if (link.length === ridLoc) {
        link.push(registrationId);
        return link.toString();
      }
      if (link.length > ridLoc) {
        link[ridLoc] = registrationId;
      }
      return nextHref;
    },
    [nextHref],
  );

  const [doCreateRegistration, { error }] = useMutation<CREATE_REGISTRATION_RES>(
    CREATE_REGISTRATION,
    {
      fetchPolicy: 'no-cache',
      variables: {},
      onError: () => {
        if (process.env.REACT_APP_DEBUG === 'true') {
          setRedirect(parseNextHref('000'));
        }
      },
      onCompleted: d => {
        if (d.createRegistration.data && d.createRegistration.data.length > 0) {
          setRedirect(parseNextHref(d.createRegistration.data[0].registrationId));
        } else {
          throw console.error(d.createRegistration.error);
        }
      },
    },
  );

  useQuery<GET_REGISTRATION_RES>(GET_REGISTRATION, {
    variables: {},
    fetchPolicy: 'no-cache',
    onError: () => {
      doCreateRegistration();
    },
    onCompleted: d => {
      // console.log(d);
      if (d.getRegistrations.data && d.getRegistrations.data?.length > 0) {
        setRedirect(parseNextHref(d.getRegistrations.data[0].registrationId));
      } else {
        doCreateRegistration();
      }
    },
  });

  if (redirect) return <Redirect to={redirect} />;
  if (error) return <div>{`Error! ${error.message}`}</div>;
  return <div>Loading ...</div>;
}
