import { ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as UserImg } from '../../assets/userMobile.svg';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import symlivUrls from '../../common_lib_front/configs/symlivUrls';
import store from '../../common_lib_front/utilities/store';
import { logout } from '../../common_lib_front/utilities/thunks';
import useMediaQuery from '../../common_lib_front/utilities/useMediaQuery';
import style from './userProfileButton.module.css';

export default function UserProfileButton(): ReactElement {
  const [open, setOpen] = useState<boolean>(false);
  const [user, setUser] = useState<any>(store.getState().user);
  useEffect(() => store.subscribe(() => setUser(store.getState().user)), [setUser]);
  const smallScreen = useMediaQuery('(max-width: 767px)');
  const { t } = useTranslation();
  const { communityId } = useContext(CommunityContext);

  useEffect(() => {
    const handler = (ev: MouseEvent) => {
      let tmp: HTMLElement | null = ev.target as HTMLElement;
      const parent = document.getElementById('open-parent-container');
      if (parent) {
        while (tmp) {
          if (open && tmp.tagName === 'BUTTON') {
            setOpen(false);
          }
          if (tmp === parent) {
            if (!open) {
              setOpen(true);
            }
            return;
          }
          tmp = tmp.parentElement;
        }
        if (open) {
          setOpen(false);
        }
      }
    };
    document.addEventListener('click', handler);
    return () => document.removeEventListener('click', handler);
  }, [open, setOpen]);

  return (
    <div id="open-parent-container" className={style.box}>
      <GenericButton
        color={smallScreen ? 'white' : 'yellow'}
        icon={() => (smallScreen ? <UserImg className={style.userImg} /> : <></>)}
        shape="circle"
        title={smallScreen ? '' : user?.firstName.slice(0, 1).toUpperCase()}
      />
      {open ? (
        <div className={`${style.itemsBox} white`}>
          <h4
            className={`${style.name} textColorMedium`}
          >{`${user.firstName} ${user.lastName}`}</h4>
          <p className={`${style.email} textColorMedium`}>{user.email}</p>
          <div className={style.profileBox}>
            <Link to="/guest/edit-profile" className={`${style.profile} textColorMedium`}>
              <button className={`${style.btn} textColorMedium white`}>
                <div className={style.imgUser} /> {t('My Profile')}
              </button>
            </Link>
          </div>
          {store.getState().user?.roles.includes('admin') ? (
            <div className={style.profileBox}>
              <a
                href={`${symlivUrls.admin.front}/${communityId}`}
                className={`${style.profile} textColorMedium`}
              >
                <button className={`${style.btn} textColorMedium white`}>
                  <div className={style.imgAdimin} /> {t('Admin Panel')}
                </button>
              </a>
            </div>
          ) : null}
          <button
            onClick={() => {
              store.dispatch(logout);
            }}
            className={`${style.btn} textColorMedium white`}
          >
            <div className={style.imgSignout} />
            {t('Sign Out')}
          </button>
          <Link to="/guest/help">
            <p className={`${style.help} textColorMedium`}>{t('Help')}</p>
          </Link>
        </div>
      ) : null}
    </div>
  );
}
