import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import RegistrationNavHeader from '../../../components/registrationNavHeader/registrationNavHeader';
import WristbandForm from '../../../components/wristbandForm/wristbandForm';
import useVehicleConfigs from '../../../utils/useVehicleConfigs';
import registerWristbands from './registerWristbands';
import style from './requestWristband.module.css';
import useGetRegistrationPasses from './useGetRegistrationPasses';

type RequestWristbandProps = {
  nextHref: string;
};

export function RequestWristband(props: RequestWristbandProps): ReactElement {
  const { nextHref } = props;
  const [wristbands, setWristbands] = useState<number>(1);
  const { registrationId } = useParams<{ registrationId: string }>();
  const { data, error } = useGetRegistrationPasses(registrationId);
  const [redirect, setRedirect] = useState<string>('');
  const [alert, setAlert] = useState<string>('');
  const { vehicleConfig } = useVehicleConfigs();
  const passInfoId = useMemo(() => {
    const [id] =
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Object.entries(vehicleConfig).find(([_, config]) =>
        Object.values(config).every(val => val === 'disabled'),
      ) || [];
    return id;
  }, [vehicleConfig]);
  useEffect(() => {
    if (data?.length) {
      setWristbands(data.length);
    }
  }, [data]);

  if (redirect) return <Redirect to={redirect} />;
  return (
    <form
      className={style.container}
      onSubmit={(e: React.FormEvent) => {
        e.preventDefault();
        setAlert('');
        if (!passInfoId) {
          setAlert('Could not find valid wristband pass config. Please try again later.');
          return;
        }
        registerWristbands(wristbands, passInfoId, registrationId)
          .then(() => setRedirect(nextHref))
          .catch(err => {
            console.log(err);
            if (typeof err === 'string') {
              setAlert(err);
              // } else if (typeof err.message === 'string') {
              //   setAlert(err.message);
            } else {
              setAlert('Something went wrong. Changes may not have been saved.');
            }
          });
      }}
    >
      <div className={`${style.box} white `}>
        <RegistrationNavHeader title={'Step 2: Request Wristbands '} subtitle={''} />
        <GenericAlert title={error} color="red" hidden={!error} />
        <GenericAlert title={alert} color="red" hidden={!alert} />
        <div className={style.flexBox}>
          <div className={style.boxLeft}>
            <WristbandForm value={wristbands} setValue={setWristbands} />
          </div>
          <div className={style.boxRight}>
            {/* <PassSummaryCollection
              showEditBtn={false}
              showVehicleInfo={false}
              showTotal
              data={passes}
            /> */}
          </div>
        </div>
        <div className={style.btn}>
          <GenericButton size="large" color="blue" title={'Continue'} type="submit" />
        </div>
      </div>
    </form>
  );
}

export default RequestWristband;
