import { ApolloError, gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

const GET_HISTORY = gql`
  query GetSharePassHistory($passId: String!) {
    sharePassHistory(passId: $passId) {
      success
      error
      data {
        firstName
        lastName
        email
        phone
      }
    }
  }
`;

type ShareHistoryType = {
  history: any;
  passId: string;
  data: any;
  loading: boolean;
  error: ApolloError | undefined;
};
export default function useShareHistory(): ShareHistoryType {
  const history = useHistory();
  const { passId } = useParams<{ passId: string }>();

  const { data, loading, error } = useQuery(GET_HISTORY, {
    variables: {
      passId,
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return {
    history,
    passId,
    data:
      error && process.env.REACT_APP_DEBUG === 'true'
        ? { sharePassHistory: { data: {} } }
        : data,
    loading,
    error,
  };
}
