import { ReactElement, useContext, useState } from 'react';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import PassInfo from '../../common_lib_front/types/passInfo';
import { passCredOrNumber } from '../../common_lib_front/utilities/passCredOrNumber';
import tableStyle from './incompletePassTable.module.css';
import IncompletePassTableData from './incompletePassTableData';

type IncompletePassTableProps = {
  data: Array<PassInfo>;
  // eslint-disable-next-line react/require-default-props
  title?: string;
  // eslint-disable-next-line react/require-default-props
  showCompleteBtn?: string | ((p: PassInfo) => string);
};

export default function IncompletePassTable(
  props: IncompletePassTableProps,
): ReactElement {
  const { data, title, showCompleteBtn } = props;

  let triangleUp;
  let triangleDown;
  const { name: communityName } = useContext(CommunityContext);

  const [sortParams, setSortParams] = useState<{ idxs: Array<string>; reverse: boolean }>(
    {
      idxs: [],
      reverse: true,
    },
  );

  const genSortParams = (idxs: Array<string>) =>
    setSortParams({
      idxs,
      reverse:
        sortParams.idxs[sortParams.idxs.length - 1] === idxs[idxs.length - 1]
          ? !sortParams.reverse
          : true,
    });

  const testActive = (lastIdx: string, reversed: boolean) => {
    if (
      sortParams.idxs[sortParams.idxs.length - 1] === lastIdx &&
      sortParams.reverse === reversed
    ) {
      return tableStyle.triangleActive;
    }
    return '';
  };

  if (communityName === 'Grand Complex') {
    triangleUp = tableStyle.triangleUpGc;
    triangleDown = tableStyle.triangleDownGc;
  } else {
    triangleUp = tableStyle.triangleUp;
    triangleDown = tableStyle.triangleDown;
  }

  return (
    <div className={tableStyle.box}>
      {title ? (
        <div className={tableStyle.tableTitleBox}>
          <h5 className={`${tableStyle.tableTitle} textColorDark`}>{title}</h5>
        </div>
      ) : null}
      <div className={tableStyle.borderBox}>
        <table width="100%" cellSpacing="0px" className={tableStyle.table}>
          <tr className={`${tableStyle.tableHeader} mainColor `}>
            <th
              onClick={() => genSortParams(['startDate'])}
              className={`${tableStyle.tableHeader__item} textColorWhite ${tableStyle.hoverPointer}`}
            >
              <div className={tableStyle.itemBox}>
                Start date
                <span className={tableStyle.triangleBox}>
                  <span className={`${triangleUp} ${testActive('startDate', true)}`} />
                  <span className={`${triangleDown} ${testActive('startDate', false)}`} />
                </span>
              </div>
            </th>
            <th
              onClick={() => genSortParams(['endDate'])}
              className={`${tableStyle.tableHeader__item} textColorWhite ${tableStyle.hoverPointer}`}
            >
              <div className={tableStyle.itemBox}>
                Valid Through
                <span className={tableStyle.triangleBox}>
                  <span className={`${triangleUp} ${testActive('endDate', true)}`} />
                  <span className={`${triangleDown} ${testActive('endDate', false)}`} />
                </span>
              </div>
            </th>
            <th
              className={`${tableStyle.tableHeader__item} textColorWhite ${tableStyle.hoverPointer}`}
            >
              <div className={tableStyle.itemBox}>{/* Null title */}</div>
            </th>
          </tr>
          {/* This will render data */}
          {data.map((elem: PassInfo) => (
            <IncompletePassTableData
              key={passCredOrNumber(elem)}
              data={elem}
              showCompleteBtn={showCompleteBtn}
            />
          ))}
        </table>
      </div>
    </div>
  );
}
