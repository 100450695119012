import { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import {
  PageOptions,
  RegistrationContext,
} from '../../pages/registration/wrapper/wrapper';
import style from './registrationNavHeader.module.css';
import RegistrationNavTile from './registrationNavTile';

const mkTileConfig: (communityId: string) => Record<
  PageOptions,
  null | {
    imgName: string;
    title: string;
    text: string;
  }
> = communityId => ({
  init: null,
  guest: {
    imgName: 'DocumentsImg',
    title: 'Step 1',
    text: 'Guest Registration',
  },
  // vehicle: {
  //   imgName: 'VehicleImg',
  //   title: 'Step 2',
  //   text: 'Register Your Vehicle',
  // },
  wristband: {
    imgName: 'WristbandImg',
    title: 'Step 2',
    text: 'Request Wristbands',
  },
  vehicle: {
    imgName: ['watercolor'].includes(communityId) ? 'WristbandImg' : 'VehicleImg',
    title: 'Step 2',
    text: ['watercolor'].includes(communityId)
      ? 'Wristband Registration'
      : 'Register Your Vehicle',
  },
  education: {
    imgName: 'RulesImg',
    title: 'Step 3',
    text: 'Community Orientation',
  },
  documents: null,
  checkout: {
    imgName: 'CreditCardImg',
    title: 'Step 4',
    text: 'Secure Checkout',
  },
  // passGeneration: null,
  receipt: null,
});

type RegistrationNavHeaderProps = {
  title: string;
  subtitle: string;
};

export default function RegistrationNavHeader(
  props: RegistrationNavHeaderProps,
): ReactElement {
  const { title, subtitle } = props;
  const { name: communityName, communityId } = useContext(CommunityContext);
  const [activeNum, setActiveNum] = useState<number>(0);
  const { steps, currentStep, pageConfigs } = useContext(RegistrationContext);
  const tileConfig = useMemo(() => mkTileConfig(communityId), [communityId]);
  // update active num
  useEffect(() => {
    setActiveNum(steps.findIndex(step => step === currentStep));
  }, [currentStep, steps]);

  const { t } = useTranslation();

  return (
    <div className={style.navHeaderBox}>
      <div className={style.textBox}>
        <h2 className={`${style.titleGrey} textColorSuperDark `}>
          {t('Welcome to')}{' '}
          <p className={`${style.titleBlue} textColorMain `}>{communityName}</p>
        </h2>
        <p className={`${style.text} textColorSuperDark `}>
          {communityName === 'Grand Complex' ? (
            <span>
              A community pass is required to use the Grand Complex parking garage. Please
              follow the instructions below to purchase your community pass.
              <span className={style.bold}>
                Please note that you are only allowed to park one vehicle in the parking
                garage during your stay.
              </span>{' '}
              Your host should have previously emailed you your login information. If you
              have not received or have lost your login information, please contact your
              host. The price per pass is $10.00 if the pass has not been purchased
              previously by your host.
            </span>
          ) : (
            t('registration_instructions')
          )}
        </p>
      </div>
      <div className={style.iconBox}>
        {steps.map(
          (step, stepIdx) =>
            tileConfig[step] && (
              <RegistrationNavTile
                key={step}
                href={pageConfigs?.[step].href || '/'}
                num={stepIdx}
                activeNum={activeNum}
                completeNum={activeNum}
                completeAll={false}
                title={t(tileConfig[step]?.title || '')}
                text={t(tileConfig[step]?.text || '')}
                imgName={tileConfig[step]?.imgName || ''}
              />
            ),
        )}
      </div>
      <h2 className={`${style.title} textColorSuperDark `}>{title}</h2>
      <p className={`${style.subTitle} textColorSuperDark `}>{subtitle}</p>
    </div>
  );
}
