import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as LockImg } from '../../../assets/lockSmall.svg';
import { ReactComponent as ProfileImg } from '../../../assets/profileSmall.svg';
import style from './editProfileGuest.module.css';

interface EditProfileGuestProps {
  children?: ReactElement | ReactElement[];
  active?: string;
}

export default function EditProfileGuest(props: EditProfileGuestProps): ReactElement {
  const { children, active } = props;
  const { t } = useTranslation();

  // TODO: remove this it is only for example
  const tmpStyles: any = {
    profile: {},
    password: {},
  };

  switch (active) {
    case 'profile':
      // TODO: add styles for active 'my profile' tab
      tmpStyles.profile.backgroundColor = 'var(--thirdColor)';
      break;
    case 'password':
      // TODO: add styles for active 'reset password' tab
      tmpStyles.password.backgroundColor = 'var(--thirdColor)';
      break;
    default:
    // TODO: add default styles
  }

  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>
        <div className={`${style.subNav} darkGrey `}>
          <div className={style.btnBox}>
            <Link to="/guest/edit-profile/my-profile">
              <button
                style={tmpStyles.profile}
                className={`${style.btn} textColorWhite darkGrey `}
              >
                <div>
                  {' '}
                  <ProfileImg />
                </div>
                <p className={style.text}>{t('My Profile')}</p>
              </button>
            </Link>
            <Link to="/guest/edit-profile/reset-password">
              <button
                style={tmpStyles.password}
                className={`${style.btn} textColorWhite darkGrey `}
              >
                <div>
                  {' '}
                  <LockImg />
                </div>
                <p className={style.text}>{t('Reset Password')}</p>
              </button>
            </Link>
          </div>
        </div>
        <div className={style.infoBox}>{children}</div>
      </div>
    </div>
  );
}
EditProfileGuest.defaultProps = {
  children: undefined,
  active: '',
};
