import { gql } from '@apollo/client';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';
import { PassStatuses } from '../../../common_lib_front/types/passInfo';

type PBVehicleType = {
  vehicleId: string;
  make: string;
  vehicleModel: string;
  type: string;
  color: string;
  licensePlate: string;
  fleetNumber: string;
  licensePlateState: string;
  primaryDriverName: string;
  licensePrimaryDriver: string;
  isRental: boolean;
};

export const STEP2_QUERY = gql`
  query Step2($registrationId: String!) {
    getPassesByRegistration(registrationId: $registrationId) {
      success
      error
      data {
        passId
        passInfoId
        number
        externalCredentialNumber
        paid
        passType
        startDate
        endDate
        status
      }
    }
    getCommunityRental(registrationId: $registrationId) {
      success
      error
      data {
        guestCanEdit
        arrivalDate
        departureDate
      }
    }
  }
`;
export type STEP2_QUERY_VARS = {
  registrationId: string;
};
export type STEP2_QUERY_RES = {
  getPassesByRegistration: backendResponse<
    Array<{
      passId: string;
      passInfoId: string;
      number: string;
      externalCredentialNumber: string;
      passType: string;
      startDate: string;
      endDate: string;
      status: PassStatuses;
    }>
  >;
  getCommunityRental: backendResponse<{
    arrivalDate: string;
    departureDate: string;
    guestCanEdit: boolean;
  }>;
};

export const GET_VEHICLE = gql`
  query GetVehicle($passId: String!) {
    getVehicle(passId: $passId) {
      success
      error
      data {
        vehicleId
        make
        vehicleModel
        type
        color
        licensePlate
        fleetNumber
        isRental
        licensePlateState
        primaryDriverName
        licensePrimaryDriver
      }
    }
  }
`;
export type GET_VEHICLE_VARS = {
  passId: string;
};
export type GET_VEHICLE_RES = {
  getVehicle: backendResponse<PBVehicleType>;
};

export const STEP_TWO_SUMBIT = gql`
  mutation Step2($stepNumber: Float!, $registrationId: String!) {
    editRegistrationStepNumber(stepNumber: $stepNumber, registrationId: $registrationId) {
      success
      error
    }
  }
`;

export const SAVE_PASS = gql`
  mutation SavePass(
    $passInfoId: String!
    $registrationId: String!
    $status: String
    $startDate: DateTime
    $endDate: DateTime
  ) {
    registerPass(
      passInfoId: $passInfoId
      registrationId: $registrationId
      status: $status
      startDate: $startDate
      endDate: $endDate
    ) {
      success
      error
      data {
        passId
        passInfoId
      }
    }
  }
`;
export type SAVE_PASS_REQ = {
  passInfoId: string;
  registrationId: string;
  status: PassStatuses;
  startDate?: Date;
  endDate?: Date;
};
export type SAVE_PASS_RES = {
  registerPass: {
    success: boolean;
    error: string;
    data?: null | Array<{
      passId: string;
      passInfoId: string;
    }>;
  };
};

export const SAVE_VEHICLE = gql`
  mutation SaveVehicle(
    $passId: String!
    $passInfoId: String!
    $vehicleInfo: ActuallyInputTypeVehicleInput!
  ) {
    registerVehicle(passId: $passId, passInfoId: $passInfoId, vehicleInfo: $vehicleInfo) {
      success
      error
    }
  }
`;
export type SAVE_VEHICLE_VARS = {
  passId: string;
  passInfoId: string;
  vehicleInfo: Partial<PBVehicleType>;
};
export type SAVE_VEHICLE_RES = {
  registerVehicle: backendResponse<undefined>;
};

export const EDIT_VEHICLE = gql`
  mutation EditVehicle(
    $vehicleId: String!
    $newVehicleInfo: ActuallyInputTypeVehicleInput!
    $status: String!
    $passId: String!
  ) {
    editVehicle(vehicleId: $vehicleId, newVehicleInfo: $newVehicleInfo) {
      success
      error
    }
    editPassStatus(status: $status, passId: $passId) {
      success
      error
    }
  }
`;
export type EDIT_VEHICLE_VARS = {
  vehicleId: string;
  status: PassStatuses;
  passId: string;
  newVehicleInfo: Omit<PBVehicleType, 'vehicleId'>;
};
export type EDIT_VEHICLE_RES = {
  editVehicle: backendResponse<undefined>;
  editPassStatus: backendResponse<undefined>;
};

export const DELETE_PASS = gql`
  mutation DeletePassAndVehicle($passId: String!, $vehicleId: String!) {
    deletePass(passId: $passId) {
      success
      error
    }
    deleteVehicle(vehicleId: $vehicleId) {
      success
      error
    }
  }
`;
export type DELETE_PASS_VARS = {
  passId: string;
  vehicleId: string;
};
export type DELETE_PASS_RES = {
  deletePass: backendResponse<undefined>;
  deleteVehicle: backendResponse<undefined>;
};

export const GET_DURATION_INFO = gql`
  query getPassInfo($passInfoId: String!) {
    getPassInfo(passInfoId: $passInfoId) {
      success
      error
      data {
        passInfoId
        durationInfo {
          type
          duration1
        }
      }
    }
  }
`;

export type GET_DURATION_INFO_VARS = {
  passInfoId: string;
};
export type GET_DURATION_INFO_RES = {
  getPassInfo: backendResponse<any>;
};

export const ADD_PASS = gql`
  mutation Mutation($passInfoId: String!, $registrationId: String!) {
    addPassToInvite(passInfoId: $passInfoId, registrationId: $registrationId) {
      error
      success
      data {
        passes {
          name
          number
          paid
          passId
          passInfoId
          passType
          endDate
          startDate
        }
      }
    }
  }
`;
export type ADD_PASS_VARS = {
  passInfoId: string;
  registrationId: string;
};
export type ADD_PASS_RES = {
  addPassToInvite: backendResponse<
    Array<{
      passes: Array<{
        passId: string;
        passInfoId: string;
        number: string;
        externalCredentialNumber: string;
        passType: string;
        startDate: string;
        endDate: string;
        status: PassStatuses;
      }>;
    }>
  >;
};
