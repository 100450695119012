import React, { ReactElement } from 'react';
import { NumberInput } from '../numberInput/numberInput';
import style from './wristbandForm.module.css';

type WristbandFormProps = {
  value: number;
  setValue: (val: number) => void;
};

export default function WristbandForm(props: WristbandFormProps): ReactElement {
  const { value, setValue } = props;
  return (
    <div className={style.infoBox}>
      <h3 className={`${style.title} textColorSuperDark `}>{'Request Wristbands'}</h3>
      <p className={`${style.subTitle} textColorSuperDark `}>
        {`Access Credentials (wristbands) are required for each Guest in the rental home. 
           The number of wristbands is limited to the Maximum Occupancy for the rental home.`}
      </p>
      <div className={style.wristbandBox}>
        <h4 className={style.wrstbandTitle}>
          Please select the number of wristbands you would like to request from the
          community.
        </h4>
        <p className={style.wristbandSubtitile}>
          Guests are required to return all wristbands; lost or replacement wristbands are
          $50 each. For additional wristbands, please contact your Rental Management
          Company.
        </p>
        <p className={style.wristbandAlert}>
          Anyone 5 years or older is required to wear a wristband.
        </p>
        <div className={style.wristbandNumberBox}>
          <label htmlFor={'wristband-num'} className={style.numberBox}>
            <h3 className={`${style.numberTitle} textColorSuperDark `}>
              Number of Guests
            </h3>
            <div className={style.numberBox}>
              <NumberInput
                id={'wristband-num'}
                min={1}
                required
                value={value}
                onChange={val => {
                  setValue(val);
                }}
              />
            </div>
          </label>
        </div>
      </div>
    </div>
  );
}
