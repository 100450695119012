import { gql } from '@apollo/client';
import GuestInfo from '../../../common_lib_front/types/guestInfo';
import RentalInfo from '../../../common_lib_front/types/rentalInfo';

export const REGISTER_COMMUNITY_RENTAL = gql`
  mutation RegisterCommunityRental(
    $registrationId: String
    $newCommunityRental: CommunityRentalInput
  ) {
    registerCommunityRental(
      registrationId: $registrationId
      newCommunityRental: $newCommunityRental
    ) {
      success
      error
    }
  }
`;
export type REGISTER_COMMUNITY_RENTAL_QUERY = {
  registrationId: string;
  newCommunityRental: Partial<{
    numberOfPets: number;
    departureDate: string;
    arrivalDate: string;
    address: string;
    country: string;
    city: string;
    state: string;
    zipCode: string;
  }>;
};
export type REGISTER_COMMUNITY_RENTAL_RESPONSE = {
  registerCommunityRental: {
    success: boolean;
    error: string;
  };
};

export const EDIT_COMMUNITY_RENTAL = gql`
  mutation EditCommunityRental(
    $numberOfPets: Float!
    # $departureDate: DateTime
    # $arrivalDate: DateTime
    $address: String!
    $communityRentalId: String!
  ) {
    editCommunityRental(
      numberOfPets: $numberOfPets
      # departureDate: $departureDate
      # arrivalDate: $arrivalDate
      address: $address
      communityRentalId: $communityRentalId
    ) {
      success
      error
    }
  }
`;
export type EDIT_COMMUNITY_RENTAL_QUERY = {
  numberOfPets: number;
  // departureDate: string,
  // arrivalDate: string,
  address: string;
  communityRentalId: string;
};
export type EDIT_COMMUNITY_RENTAL_RESPONSE = {
  registerCommunityRental: {
    success: boolean;
    error: string;
  };
};

// const DELETE_COMMUNITY_RENTAL = gql`
//   mutation DeleteCommunityRental (
//     $communityRentalId: String
//   ) {
//     deleteCommunityRental (
//       communityRentalId: $communityRentalId
//     ) {
//       success
//       error
//     }
//   }
// `;
export const GET_STEP_ONE = gql`
  query GetStep1($registrationId: String!) {
    getUserInfo {
      success
      error
      data {
        firstName
        lastName
        email
        phoneNumber
        address
        country
        city
        state
        zipCode
      }
    }
    getGuestInfoData(registrationId: $registrationId) {
      success
      error
      data {
        guestInfoId
        userId
        registrationId
        firstName
        lastName
        phoneNumber
        address
        country
        city
        state
        zipCode
      }
    }
    getCommunityRental(registrationId: $registrationId) {
      success
      error
      data {
        communityRentalId
        address
        arrivalDate
        departureDate
        guestCanEdit
      }
    }
  }
`;
export type GET_STEP_ONE_QUERY = {
  registrationId: string;
};
export type GET_STEP_ONE_RESPONSE = {
  getCommunityRental: {
    success: boolean;
    error: string;
    data: null | Partial<RentalInfo>;
  };
  getGuestInfoData: {
    success: boolean;
    error: string;
    data: null | Partial<GuestInfo>;
  };
  getUserInfo: {
    success: boolean;
    error: string;
    data: null | Partial<GuestInfo>;
  };
};

export const STEP_ONE_SUBMIT = gql`
  mutation Step1($registrationId: String!, $stepNumber: Float!) {
    editRegistrationStepNumber(stepNumber: $stepNumber, registrationId: $registrationId) {
      success
      error
    }
  }
`;
export type STEP_ONE_SUBMIT_QUERY = {
  registrationId: string;
  stepNumber: number;
};
export type STEP_ONE_SUBMIT_RESPONSE = {
  editRegistrationStepNumber: {
    success: boolean;
    error: string;
  };
};

export const UPDATE_USER = gql`
  mutation UpdateUser($userInfo: UserInput!) {
    editUserInfo(userInfo: $userInfo) {
      success
      error
    }
  }
`;
export type UPDATE_USER_QUERY = {
  userInfo: Partial<{
    zipCode: string;
    city: string;
    state: string;
    country: string;
    address: string;
    phoneNumber: string;
    lastName: string;
    firstName: string;
  }>;
};
export type UPDATE_USER_RESPONSE = {
  editUserInfo: {
    success: boolean;
    error: string;
  };
};
export const CREATE_GUEST = gql`
  mutation CreateGuest($newGuestInfo: GuestInfoInput, $registrationId: String) {
    registerGuestInfo(newGuestInfo: $newGuestInfo, registrationId: $registrationId) {
      success
      error
    }
  }
`;
export type CREATE_GUEST_QUERY = {
  registrationId: string;
  newGuestInfo: {
    zipCode: string;
    city: string;
    state: string;
    country: string;
    address: string;
    phoneNumber: string;
    lastName: string;
    firstName: string;
  };
};
export type CREATE_GUEST_RESPONSE = {
  registerGuestInfo: {
    success: boolean;
    error: string;
  };
};

export const UPDATE_GUEST = gql`
  mutation UpdateGuest($guestInfo: GuestInfoInput!, $registrationId: String!) {
    editGuestInfo(guestInfo: $guestInfo, registrationId: $registrationId) {
      success
      error
    }
  }
`;
export type UPDATE_GUEST_QUERY = {
  registrationId: string;
  guestInfo: {
    zipCode: string;
    city: string;
    state: string;
    country: string;
    address: string;
    phoneNumber: string;
    lastName: string;
    firstName: string;
  };
};
export type UPDATE_GUEST_RESPONSE = {
  registerGuestInfo: {
    success: boolean;
    error: string;
  };
};
