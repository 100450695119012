import React, { ReactElement, useCallback, useMemo } from 'react';
import RDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { ReactComponent as LeftNav1 } from '../../assets/leftNav1.svg';
import { ReactComponent as LeftNav2 } from '../../assets/leftNav2.svg';
import { ReactComponent as RightNav1 } from '../../assets/rightNav1.svg';
import { ReactComponent as RightNav2 } from '../../assets/rightNav2.svg';
import {
  convertLocalToUTCDate,
  convertUTCToLocalDate,
} from '../../common_lib_front/utilities/formatDate';
import styles from './datePicker.module.css';

const Wrapper = styled.div`
  .react-datepicker {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: none;
    padding: 20px;
  }
  .react-datepicker__header {
    background-color: #ffffff;
    border-bottom: none;
  }
  .react-datepicker__day-name {
    color: #848a95;
  }
  .react-datepicker__day {
    border: 0.5px solid #707070;
    border-radius: 4px;
    color: #636365;
  }
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    background: #f2f2f2;
  }
  .react-datepicker__day--selected {
    color: #ffffff;
    background: #636365;
  }
  .react-datepicker__month {
    margin: 0;
  }
  .react-datepicker__day--disabled {
    color: #ccc;
  }
`;

interface IProps {
  date: Date | null;
  setDate: (date: Date) => void;
  minDate?: Date | null;
  maxDate?: Date | null;
  customInput?: ReactElement;
  disabled?: boolean;
}

function DatePicker({
  date: dateProp,
  setDate,
  minDate: minDateProp,
  maxDate: maxDateProp,
  customInput,
  disabled,
}: IProps): ReactElement {
  const date = useMemo(() => convertUTCToLocalDate(dateProp), [dateProp]);
  const minDate = useMemo(() => convertUTCToLocalDate(minDateProp), [minDateProp]);
  const maxDate = useMemo(() => convertUTCToLocalDate(maxDateProp), [maxDateProp]);

  const months = useMemo(
    () => [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    [],
  );

  const onChangeDate = useCallback(
    (d: Date) => {
      setDate(convertLocalToUTCDate(d) as Date);
    },
    [setDate],
  );

  const renderCustomHeader = ({
    date: d,
    changeYear,
    decreaseMonth,
    increaseMonth,
  }: {
    date: Date;
    changeYear: any;
    changeMonth: any;
    decreaseMonth: any;
    increaseMonth: any;
    prevMonthButtonDisabled: any;
    nextMonthButtonDisabled: any;
  }) => (
    <div className={styles.header__container}>
      <div>
        <button
          type="button"
          onClick={() => changeYear(d?.getFullYear() - 1)}
          className={styles.nav__button}
        >
          <LeftNav2 />
        </button>
        <button type="button" onClick={decreaseMonth} className={styles.nav__button}>
          <LeftNav1 />
        </button>
      </div>
      <div>
        <span>{`${months[d?.getMonth()]} ${d?.getFullYear()}`}</span>
      </div>
      <div>
        <button type="button" onClick={increaseMonth} className={styles.nav__button}>
          <RightNav1 />
        </button>
        <button
          type="button"
          onClick={() => changeYear(d?.getFullYear() + 1)}
          className={styles.nav__button}
        >
          <RightNav2 />
        </button>
      </div>
    </div>
  );

  const handleChange = useCallback(
    (d: any, e: any) => {
      if (e && typeof e.preventDefault === 'function') {
        e.preventDefault();
      }
      if (onChangeDate) {
        onChangeDate(d);
      }
    },
    [onChangeDate],
  );

  return (
    <Wrapper>
      <RDatePicker
        renderCustomHeader={renderCustomHeader}
        selected={date}
        onChange={handleChange}
        minDate={minDate}
        maxDate={maxDate}
        customInput={customInput}
        disabledKeyboardNavigation
        disabled={disabled}
      />
    </Wrapper>
  );
}

DatePicker.defaultProps = {
  minDate: null,
  maxDate: null,
  customInput: null,
  disabled: false,
};

export default DatePicker;
