import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo } from 'react';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import PassInfo, { newPassInfo } from '../../common_lib_front/types/passInfo';
import usePassGroups from '../../utils/usePassGroups';
import useVehicleConfigs, { PassInfoData } from '../../utils/useVehicleConfigs';
import style from './passAdderSection.module.css';

export type PassAdderSectionProps = {
  passFilter?: (passInfo: PassInfoData) => boolean;
  passGroup?: string;
  passes: PassInfo[];
  setPasses: Dispatch<SetStateAction<PassInfo[]>>;
  hideWhenNoAddable?: boolean;
  defaultValues?: Partial<PassInfo>;
  removeHandler?: (o: { variables: { passId: string; vehicleId: string } }) => void;
  setNumAddablePasses?: Dispatch<SetStateAction<number>>;
};

export default function PassAdderSection(
  props: PassAdderSectionProps,
): React.ReactElement {
  const {
    passFilter,
    passGroup,
    passes,
    setPasses,
    hideWhenNoAddable,
    defaultValues,
    removeHandler,
    setNumAddablePasses,
  } = props;
  const { passInfoDatas } = useVehicleConfigs();
  const { data: passGroupData } = usePassGroups();
  const { name: communityName } = useContext(CommunityContext);

  const addablePassInfoDatas = useMemo(() => {
    let res = passInfoDatas;
    if (passGroup) {
      const group = passGroupData?.getPassGroups.data?.find(
        pg => pg.name === passGroup || pg.passGroupId === passGroup,
      );
      res = res.filter(p => group?.passInfoList.includes(p.passInfoId));
    }
    if (passFilter) {
      res = res.filter(passFilter);
    }
    return res;
  }, [passInfoDatas, passFilter, passGroupData, passGroup]);

  useEffect(() => {
    if (setNumAddablePasses) {
      setNumAddablePasses(addablePassInfoDatas.length);
    }
  }, [addablePassInfoDatas, setNumAddablePasses]);

  if (hideWhenNoAddable && addablePassInfoDatas.length === 0) {
    return <></>;
  }
  return (
    <div className={style.wrapper}>
      {addablePassInfoDatas.map(passInfo => (
        <>
          <h2>Request {passInfo.name}</h2>
          {passInfo.description && (
            <p className={style.description}>{passInfo.description}</p>
          )}
          <div className={style.container}>
            <div key={passInfo.passInfoId}>
              <h4>Please select the number of credentials you would like to request</h4>
              {passInfo.customInstructions && (
                <p>
                  <b>Instructions From {communityName}:</b>
                  <br />
                  {passInfo.customInstructions}
                </p>
              )}
              <label
                htmlFor={`${passInfo.passInfoId}-num-select`}
                className={style.numSelectorLabel}
              >
                {passInfo.name}
              </label>
              <div className={style.numSelector}>
                <button
                  type="button"
                  onClick={() =>
                    setPasses(prev => {
                      const res = [...prev];
                      let removedPass: PassInfo | undefined = undefined;
                      // remove last instance of pass type from array
                      for (let i = res.length - 1; i > 0; i -= 1) {
                        if (res[i]?.passInfoId === passInfo.passInfoId) {
                          removedPass = res.splice(i, 1)[0];
                          // after removing, break out of loop
                          break;
                        }
                      }
                      if (
                        removeHandler &&
                        removedPass?.passId &&
                        !removedPass.passId.startsWith('temp_id_')
                      ) {
                        removeHandler({
                          variables: {
                            passId: removedPass.passId,
                            vehicleId: removedPass.vehicle.vehicleId,
                          },
                        });
                      }
                      return res;
                    })
                  }
                >
                  -
                </button>
                <input
                  id={`${passInfo.passInfoId}-num-select`}
                  disabled
                  value={passes.reduce(
                    (ct, pass) => ct + Number(pass.passInfoId === passInfo.passInfoId),
                    0,
                  )}
                />
                <button
                  type="button"
                  onClick={() =>
                    setPasses(prev => [
                      ...prev,
                      newPassInfo({
                        ...defaultValues,
                        passInfoId: passInfo.passInfoId,
                        paid: passInfo?.priceInfo?.isFree ? '' : 'unpaid',
                        passId: `temp_id_${passInfo.passInfoId}_${passes.length}`,
                      }),
                    ])
                  }
                >
                  +
                </button>
              </div>
            </div>
          </div>
        </>
      ))}
    </div>
  );
}
