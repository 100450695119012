import { ReactElement } from 'react';
import useOrderPrice from '../../common_lib_front/hooks/useOrderPrice';
import PassInfo from '../../common_lib_front/types/passInfo';
// import useOrderPrice from '../../hooks/useOrderPrice';
import useVehicleConfigs from '../../utils/useVehicleConfigs';
import PassSummary from './passSummary';
import style from './passSummaryCollection.module.css';

type PassSummaryCollectionProps = {
  showVehicleInfo: boolean;
  showEditBtn: boolean;
  showTotal: boolean;
  data: Array<PassInfo>;
  editLink?: string;
  hideIf0Total?: boolean;
};

export const PassSummaryCollection = (
  props: PassSummaryCollectionProps,
): ReactElement => {
  const { data, showTotal, editLink, hideIf0Total } = props;
  const { passInfoDatas, vehicleConfig } = useVehicleConfigs();

  const { totalPrice, passes } = useOrderPrice(
    data.filter(p => !['paid', 'ach-pending'].includes(p.paid)),
  );

  if (hideIf0Total && totalPrice === 0) {
    return <></>;
  }
  return (
    <div className={style.infoBox}>
      {data.map((elem: PassInfo, idx: number) => (
        <div className={style.summaryBox} key={idx}>
          <PassSummary
            data={elem}
            idx={idx}
            showVehicleInfo={props.showVehicleInfo}
            showEditBtn={props.showEditBtn}
            editLink={editLink}
            title={
              passInfoDatas.find(pid => pid.passInfoId === elem.passInfoId)?.name ||
              'Access Pass'
            }
            config={vehicleConfig[elem.passInfoId]}
            priceInfo={passes?.find(p => p.passId === elem.passId)}
          />
        </div>
      ))}
      {showTotal ? (
        <div className={style.totalBox}>
          <h1 className={`${style.total}`}>
            <h4 className={`${style.textLeft} textColorDark `}> Total</h4>
            <h4 className={`${style.textRight} textColorDark `}>${totalPrice}</h4>
          </h1>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
PassSummaryCollection.defaultProps = {
  editLink: '',
};

export default PassSummaryCollection;
