import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import MyPassGrid from '../../../components/myPassGrid/myPassGrid';
import MyPassesTab from '../../../components/myPasssesTab/myPassesTab';
import style from './guestPasses.module.css';
import useGuestPasses from './useGuestPasses';

export default function GuestPasses(): ReactElement {
  const { allPasses, activeTab, setActiveTab, communityId } = useGuestPasses();
  const { t } = useTranslation();

  return (
    <div className={style.container}>
      <div className={style.box}>
        <div className={`${style.box__subHeader} mainColor `}>
          <div className={`${style.box__circle} white `} />
          <h1 className={`${style.box__title} textColorWhite `}>{t('My Passes')}</h1>
        </div>
        <div className={`${style.box__flexBox} white `}>
          <div className={style.box__tabBox}>
            {communityId !== 'watercolor' && (
              <MyPassesTab
                title={t('Vehicle Passes')}
                clickHandler={() => {
                  setActiveTab('Current');
                }}
                active={activeTab === 'Current'}
                highlightOnFocus={activeTab === 'Current'}
              />
            )}
            <MyPassesTab
              title={t('Amenity Passes')}
              clickHandler={() => {
                setActiveTab('amenity');
              }}
              active={activeTab === 'amenity'}
              highlightOnFocus={activeTab === 'amenity'}
            />
          </div>
          <div className={`${style.box__innerBox} white `}>
            <MyPassGrid data={allPasses} activeTab={activeTab} />
          </div>
        </div>
      </div>
    </div>
  );
}
