import { gql } from '@apollo/client';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';

export const STEP_FOUR_QUERY = gql`
  query Step4Query($registrationId: String!) {
    getPassesByRegistration(registrationId: $registrationId) {
      success
      error
      data {
        passId
        passInfoId
        number
        externalCredentialNumber
        startDate
        endDate
        status
        paid
      }
    }
    getGuestInfoData(registrationId: $registrationId) {
      success
      error
      data {
        firstName
        lastName
        address
        phoneNumber
      }
    }
  }
`;
export type STEP_FOUR_QUERY_PASS = {
  passId: string;
  passInfoId: string;
  number: string;
  externalCredentialNumber: string;
  startDate: string;
  endDate: string;
  status: string;
  paid: string;
};
export type STEP_FOUR_QUERY_VARS = {
  registrationId: string;
};
export type STEP_FOUR_QUERY_RES = {
  getPassesByRegistration: backendResponse<STEP_FOUR_QUERY_PASS[]>;
  getGuestInfoData: backendResponse<{
    firstName: string;
    lastName: string;
    address: string;
    phoneNumber: string;
  }>;
};

export const STEP_FOUR_SUMBIT = gql`
  mutation Step4(
    $registrationId: String!
    $successURL: String!
    $cancelURL: String!
    $forceNew: Boolean
  ) {
    doStripeCharge(
      registrationId: $registrationId
      successURL: $successURL
      cancelURL: $cancelURL
      forceNew: $forceNew
    ) {
      url
      error
      success
    }
  }
`;
export type STEP_FOUR_SUMBIT_VARS = {
  registrationId: string;
  successURL: string;
  cancelURL: string;
  forceNew: boolean;
};
export type STEP_FOUR_SUMBIT_RES = {
  doStripeCharge: {
    url: string;
    error: string;
    success: boolean;
  };
};

export const GET_VEHICLE = gql`
  query GetVehicle($passId: String!) {
    getVehicle(passId: $passId) {
      success
      error
      data {
        vehicleId
        make
        vehicleModel
        type
        color
        licensePlate
        fleetNumber
        isRental
      }
    }
  }
`;
export type GET_VEHICLE_VARS = {
  passId: string;
};
export type GET_VEHICLE_RES = {
  getVehicle: backendResponse<{
    vehicleId: string;
    make: string;
    vehicleModel: string;
    type: string;
    color: string;
    licensePlate: string;
    fleetNumber: string;
    isRental: boolean;
  }>;
};
