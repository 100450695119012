import { gql, useMutation } from '@apollo/client';
import React, { ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import ScormWrapper from '../../../common_lib_front/components/scormWrapper/scormWrapper';
import RegistrationNavHeader from '../../../components/registrationNavHeader/registrationNavHeader';
import WarnBeforeUnload from '../warnBeforeUnload/warnBeforeUnload';
import { RegistrationPageProps } from '../wrapper/wrapper';
import style from './communityRules.module.css';

const STEP_THREE_SUMBIT = gql`
  mutation Step3($stepNumber: Float!, $registrationId: String!) {
    editRegistrationStepNumber(stepNumber: $stepNumber, registrationId: $registrationId) {
      success
      error
    }
  }
`;

export default function CommunityRules(props: RegistrationPageProps): ReactElement {
  const { nextHref } = props;
  const [redirect, setRedirect] = useState<string>('');
  const { registrationId } = useParams<{ registrationId: string }>();
  const [showBackup, setShowBackup] = useState<boolean>(false);
  const { t } = useTranslation();

  const [doSubmit] = useMutation(STEP_THREE_SUMBIT, {
    onError: () => {
      if (process.env.REACT_APP_DEBUG === 'true') {
        setRedirect(nextHref);
      }
    },
    onCompleted: d => {
      if (d.editRegistrationStepNumber.success) {
        setRedirect(nextHref);
      }
    },
  });

  const submitHandler = useCallback(() => {
    if ((window as any).registrationLockStepNum >= 4) {
      setRedirect(nextHref);
      return;
    }
    doSubmit({
      variables: {
        stepNumber: 4,
        registrationId,
      },
    });
  }, [doSubmit, nextHref, registrationId, setRedirect]);

  WarnBeforeUnload();

  if (showBackup) {
    console.warn('Scorm load error');
  }
  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>
        <RegistrationNavHeader title="" subtitle="" />
        {/* <GenericAlert
          color="red"
          hidden={!showBackup}
          title="Could not load education. Please contact your host to review community rules."
        /> */}
        <div className={style.videoBox}>
          <div className={style.videoContainer}>
            <ScormWrapper
              continueHandler={() => submitHandler()}
              portal="guest"
              loadFailureHandler={() => setShowBackup(true)}
            />
          </div>
        </div>
        {/* {showBackup && ( */}
        <div className={style.btn}>
          <GenericButton
            color="blue"
            size="large"
            title={t('Continue')}
            clickHandler={() => submitHandler()}
          />
        </div>
        {/* )} */}
      </div>
    </div>
  );
}
