import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as CreditCardImg } from '../../assets/creditCardSmallStylable.svg';
import { ReactComponent as DocumentsImg } from '../../assets/documentsSmallStylable.svg';
import { ReactComponent as RulesImg } from '../../assets/rulesSmallStylable.svg';
import { ReactComponent as VehicleImg } from '../../assets/vehicleSmallStylable.svg';
import { ReactComponent as WristbandImg } from '../../assets/wristband.svg';
import style from './registrationNavTile.module.css';

const ImgSelector = (imgName: string, className: any): ReactElement => {
  if (imgName === 'DocumentsImg') {
    return <DocumentsImg className={className} />;
  }
  if (imgName === 'VehicleImg') {
    return <VehicleImg className={className} />;
  }
  if (imgName === 'WristbandImg') {
    return <WristbandImg className={className} />;
  }
  if (imgName === 'RulesImg') {
    return <RulesImg className={className} />;
  }
  if (imgName === 'CreditCardImg') {
    return <CreditCardImg className={className} />;
  }
  return <></>;
};

type RegistrationNavTileProps = {
  imgName: string;
  title: string;
  text: string;
  href: string;
  num: number;
  activeNum: number;
  completeNum: number;
  completeAll: boolean | null;
};

const RegistrationNavTile = (props: RegistrationNavTileProps): ReactElement => {
  const { imgName, title, text, href, num, activeNum, completeNum, completeAll } = props;

  const active = activeNum === num;
  const locked = (() => {
    if (process.env.REACT_APP_DEBUG === 'true' || completeAll) {
      return false;
    }
    return completeNum < num;
  })();
  const complete = (() => {
    if (completeAll) {
      return true;
    }
    if (!completeNum && process.env.REACT_APP_DEBUG === 'true') {
      return activeNum > num;
    }
    return completeNum > num;
  })();

  let clickedStyle = 'textColorSpecial';
  let clickedLine = style.lineInvisible;
  let svgStyle = style.defaultSvg;
  if (active) {
    // TODO set styles for the currently active tab
    clickedStyle = 'textColorThird';
    clickedLine = style.line;
    svgStyle = style.activeSvg;
  } else if (complete) {
    // TODO set styles for previously completed tabs
    clickedStyle = 'textColorSucess';
    svgStyle = style.successSvg;
  } else if (locked) {
    // TODO set styles for locked tabs
  } else {
    // TODO set styles for default
  }

  const clickHandler = (e: any) => {
    if (locked && activeNum < num) {
      e.preventDefault();
    }
  };
  return (
    <Link to={href} onClick={clickHandler}>
      <div className={`${style.icon} ${'clickedImgStyle'}`}>
        {ImgSelector(imgName, svgStyle)}
      </div>
      <h2 className={`${style.title} ${clickedStyle}`}>{title}</h2>
      <p className={`${style.text} ${clickedStyle}`}>{text}</p>
      <div className={clickedLine} />
    </Link>
  );
};

export default RegistrationNavTile;
