import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import InputField from '../../common_lib_front/components/inputField/inputField';
import { GuestInfoConfig } from '../../common_lib_front/types/guestInfo';
import RentalInfo from '../../common_lib_front/types/rentalInfo';
import { NumberInput } from '../numberInput/numberInput';
import SearchSelector from '../searchSelector/searchSelector';
import style from './rentalInfoForm.module.css';

type RentalInfoFormProps = {
  data: RentalInfo;
  idx: number;
  removeHandler: () => void;
  updateHandler: (obj: any) => void;
  config?: GuestInfoConfig;
  immutable?: boolean;
};

const defaultConfig: GuestInfoConfig = {
  communityCode: 'optional',
  status: 'optional',
  firstName: 'required',
  lastName: 'required',
  email: 'disabled',
  phoneNumber: 'required',
  emergencyContact: 'disabled',
  address: 'disabled',
  country: 'disabled',
  city: 'disabled',
  state: 'disabled',
  zipCode: 'disabled',
  rentalAddress: 'required',
  arrivalDate: 'required',
  departureDate: 'required',
  numberGuests: 'disabled',
  communityRentalId: 'disabled',
};

export default function RentalInfoForm(props: RentalInfoFormProps): ReactElement {
  const { data, idx, updateHandler, config, immutable } = props;
  const [open, setOpen] = useState(true);
  const { t } = useTranslation();

  return (
    <div className={style.infoBox}>
      <h4 className={`${style.title} textColorSuperDark `}>
        {t('Community Rental Information')}
      </h4>
      <p className={`${style.subTitle} textColorSuperDark `}>
        {t('Enter rental details below for each property being registered')}
      </p>
      <div className={style.infoFormBox}>
        <div className={`${style.header} superDarkGrey `}>
          <div className={style.btnDown}>
            <GenericButton
              color="transparent"
              shape="drop-down"
              title={open ? '<' : '>'}
              clickHandler={() => setOpen(!open)}
            />
          </div>
          <strong className={`${style.header} textColorWhite `}>{`${t(
            'Community Rental',
          )} #${idx + 1}`}</strong>
          {/* <div className={style.btnRemove}>
            <GenericButton
              color="transparent"
              title={t('Remove')}
              clickHandler={() => removeHandler()}
            />

          </div> */}
        </div>

        {open ? (
          <div className={`${style.inputLong} `}>
            {config?.rentalAddress !== 'disabled' ? (
              <div className={style.rentalAddress}>
                <SearchSelector
                  title={t('Address Or Rental Unit Search')}
                  required={config?.rentalAddress === 'required'}
                  disabled={immutable}
                  htmlFor={`rental-address-inpt-${idx}`}
                  value={data.address}
                  changeHandler={(val: string) => {
                    updateHandler({ address: val });
                  }}
                />
                {/* <InputField
                        closedInput
                        required={config?.rentalAddress === 'required'}
                        htmlFor={`rental-address-inpt-${idx}`}
                        labelTitle={t('Address Or Rental Unit Search')}
                        inputValue={data.address}
                        changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                          updateHandler({ address: e.target.value });
                        }}
                      /> */}
              </div>
            ) : null}
            <div className={style.inputShortBox}>
              {config?.arrivalDate !== 'disabled' ? (
                <div className={style.inputShort}>
                  <InputField
                    closedInput
                    required={config?.arrivalDate === 'required'}
                    // disabled={immutable}
                    disabled
                    htmlFor={`rental-arrival-inpt-${idx}`}
                    labelTitle={t('Arrival Date')}
                    inputType="date"
                    inputValue={data.arrivalDate}
                    max={data.departureDate}
                    changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                      updateHandler({ arrivalDate: e.target.value });
                    }}
                  />
                </div>
              ) : null}
              {config?.departureDate !== 'disabled' ? (
                <div className={style.inputShort}>
                  <InputField
                    closedInput
                    required={config?.departureDate === 'required'}
                    // disabled={immutable}
                    disabled
                    htmlFor={`rental-departure-inpt-${idx}`}
                    labelTitle={t('Departure Date')}
                    inputType="date"
                    inputValue={data.departureDate}
                    min={data.arrivalDate}
                    changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                      updateHandler({ departureDate: e.target.value });
                    }}
                  />
                </div>
              ) : null}
            </div>
            {config?.numberGuests !== 'disabled' ? (
              <label htmlFor={`rental-num-in-${idx}`} className={style.numberBox}>
                <input disabled hidden />
                <h4 className={`${style.numberTitle} textColorSuperDark `}>
                  {t('Number of Guests')}
                </h4>
                <NumberInput
                  id={`rental-num-in-${idx}`}
                  min={1}
                  required={config?.numberGuests === 'required'}
                  disabled={immutable}
                  value={data.numberOfGuests || 1}
                  onChange={(e: number) => {
                    updateHandler({ numberOfGuests: e });
                  }}
                />
              </label>
            ) : null}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
RentalInfoForm.defaultProps = {
  config: defaultConfig,
  immutable: false,
};
