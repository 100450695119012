import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import PasswordResetForm from '../../../components/passwordResetForm/passwordResetForm';
import style from './resetPassword.module.css';

export default function ResetPassword(): ReactElement {
  const { t } = useTranslation();
  return (
    <div>
      <div className={`${style.subHeader} mainColor `}>
        <div className={`${style.circle} white `} />
        <h1 className={`${style.title} textColorWhite `}>{t('Reset Password')}</h1>
      </div>
      <div className={style.form}>
        <PasswordResetForm
          onSubmit={() => {
            console.log('submitting nothing');
          }}
        />
      </div>
    </div>
  );
}
