const es = {
  otherLanguage: 'english',
  'Welcome to Community Guest Registration Portal':
    'Bienvenido al Portal de Registración Para huéspedes de la comunidad',
  'Registration is required for all guests entering the community. ':
    'Es requerido que todos los huéspedes se registren para entrar a la comunidad. ',
  'Please create an account below to begin your registration and receive your community access pass.':
    'Por favor crea una cuenta abajo para iniciar su registración y permiso para acceder la comunidad.',
  'Please create your account': 'Por favor crea tu cuenta',
  'First Name': 'Primer Nombre',
  'Last Name': 'Appellido',
  'Email Address': 'Correo Electrónico',
  Password: 'Contraseña',
  'Verify Password': 'Verifica contraseña',
  'Do you already have an account?': '¿Ya tienes una cuenta?',
  'Create Account': 'Crea una Cuenta',
  Login: 'Acceso',
  'If you have previously created an account, please login below to register a new visit and receive your community access pass.':
    'Si usted previamente creo una cuenta, por favor inicie abajo para registrar una visita nueva y para recibir su permiso de acceso a la comunidad.',
  'Please log in to your account': 'Por favor inicie su cuenta',
  "Don't have an account?": '¿No tienes una cuenta?',
  'Forgot Password?': '¿Olvido Contraseña?',
  "Enter your email address and we'll send you a link to reset your password":
    'Ingrese su correo electrónico y l mandaremos un enlace para restablecer su contraseña',
  Enter: 'Ingrese',
  'Access My Passes': 'Acede Mis Permisos',
  'View all your current access passes.': 'Ver todos tus permisos de acceso',
  'Manage Passes': 'Administrar Permisos',
  'Purchase New Pass': 'Compre Permiso Nuevo',
  'Buy new Community Access Passes and update billing information.':
    'Compre permisos nuevos de Acceso a la comunidad y actualice información de pago',
  'Buy a New Pass': 'Compre un permiso nuevo',
  'My Profile': 'Mi Perfil',
  'Access and edit your profile information including name, address, and contact.':
    'Acede y edita la información de tu perfil incluyendo nombre, domicilio, y contacto',
  'Edit Info': 'Edita informacion',
  'Register Rental Car': 'Registre Vehiculo Alquilado',
  'Return and register your rental car information if you were unable to before.':
    'Regrese y registre la informacion de su vehiculo alquilado si no pudo hacerlo anteriormente',
  'Register My Rental Car': 'Registrar Mi Vehículo Alquilado ',
  'Review Community Rules': 'Revisa Las Reglas De La Comunidad',
  'Review community policies, rules, and procedures before entering the community.':
    'Revisa las pólizas, reglas, y procedimientos antes de entrar a la comunidad',
  'Review Rules': 'Revisa las reglas',
  'My Passes': 'Mis Permisos',
  'Purchase Pass': 'Comprar Permiso',
  Help: 'Ayudar',
  'Welcome to': 'Bienvenidos a',
  registration_instructions:
    'Por favor sigue las instrucciones abajo para acceder el Permiso de Acceso a la Comunidad. Debes registrar and revisar las reglas de la comunidad antes de finalizar su compra. La registración tomara aproximadamente 5 a 10 minutos en completarse. Por favor tenga en cuanta: No podrás entrar a la comunidad o acceder las comodidades sin un Permiso de Acceso a la Comunidad.',
  'Step 1': 'Primer Paso',
  'Step 2': 'Segundo paso ',
  'Step 3': 'Tercer Paso',
  'Step 4': 'Cuarto Paso',
  'Guest Registration': 'Registración de huésped ',
  'Register Your Vehicle': 'Registre su vehículo',
  'Community Orientation': 'Orientación Comunitaria',
  'Secure Checkout': 'Pago Seguro',
  'Guest & Rental Registration': 'Registración de huésped',
  'Please provide the required information below':
    'Por favor provee la información requerida abajo',
  'Please completely fill out guest information!':
    '¡Por favor complete completamente la información del huésped!',
  'Please completely fill out information for at least one community rental':
    'Complete completamente la información para al menos un alquiler comunitario',
  'Guest Information': 'Información del huésped',
  'Please provide the required guest information below':
    'Por favor provee la información requerida abajo',
  'Cell Phone Number': 'Numero de Celular',
  'Home Address': 'Domicilio',
  Country: 'País',
  City: 'Ciudad',
  State: 'Estado',
  'Select Your State': 'Seleccione su estado',
  'Zip Code': 'Codigo Postal',
  'Community Rental Information': 'Información de alquiler comunitario',
  'Enter rental details below for each property being registered':
    'Ingrese los detalles de alquiler para cada propiedad que está registrando',
  'Add a Rental': 'Eliminar Alquiler',
  'Community Rental': 'Alquiler de la comunidad',
  'Address Or Rental Unit Search': 'Dirección o búsqueda de propiedad alquilada',
  Remove: 'Eliminar',
  'Arrival Date': 'Fecha de Llegada',
  'Departure Date': 'Fecha de Salida',
  'Number of Guests': 'Cuantos huéspedes',
  Continue: 'Continuar',
  address_no_match:
    'Esta dirección no coincide con un alquiler conocido. Recomendamos que selecciona su dirección si existe en la lista provista. O si no ve su dirección de alquiler, puede continuar con el ingresado actualmente.',
  step2_subtitle:
    'Por favor, registre todos los vehículos que ingresan a la comunidad. Fallo de hacerlo resultará en multas y posible expulsión de la comunidad.',
  'Pass Details': 'Detalles de Permiso',
  'Enter pass details below for each pass being purchased':
    'Ingrese los detalles abajo para cada permiso comprado',
  'Community Access Pass': 'Permiso de Acceso de la Comunidad',
  Make: 'Marca',
  Type: 'Tipo',
  'License Plate #': 'Número de placa',
  'Start Date': 'Fecha de inicio',
  'Valid Through': 'Válida a través de',
  'This vehicle is a rental': 'Este vehiculo esta alquilado',
  rental_prompt:
    'Una vez que tenga la información de su auto de alquiler, regrese a este sistema y haga clic en el botón "Registrar auto de alquiler" para registrar su auto de alquiler y activar completamente su pase.',
  'Add a Pass': 'Agregue un permiso',
  'Save Changes': 'Guardar Cambios',
  'Vehicle Information': 'Información del Vehículo',
  Subtotal: 'Total Parcial',
  step4_subtitle:
    'Por favor verifique toda la informacion presentada que este al corriente y precisa antes de someter su compra',
  'Credit Card': 'Tarjeta de Credito',
  'Name on Card': 'Nombre en tarjeta',
  'Card Number': 'Numero de tarjeta',
  'EXP. Date': 'Fecha de vencemiento',
  price_info:
    'Precios en dolar Estado Unidense (EU). Permisos de acceso a la comunidad no son reembolsables. Total calculado a finalizacion de compra',
  'All major credit and debit cards are accepted':
    'Se aceptan las principales tarjetas de crédito y débito',
  'Review Information': 'Revise Informacion',
  'Please verify all information below is correct':
    'Por favor verifique que toda la informacion abajo este correcta',
  'Personal Information': 'Informacion personal',
  Edit: 'Editar',
  NAME: 'NOMBRE',
  ADDRESS: 'DIRECCIÓN',
  'PHONE #': 'NÚMERO DE TELÉFONO',
  'Thank you for registering at Crystal Beach!':
    'Gracias por su registracion con Crystal Beach!',
  'We hope you enjoy your stay!': 'Esperamos que disfrute su estancia!',
  'Order Summary': 'Resumen de Orden',
  'A confirmation email has been sent to your email along with your Community Access Pass':
    'Una confirmacion se ha mandado a su correo electronico junto con su Permiso de Acceso a la comunidad',
  'Community Pass Numbers': 'Numero de Permiso a la Comunidad',
  Date: 'Fecha',
  'Purchase Total': 'Compra Total',
  'Payment Method': 'Metodo de pago',
  'Print Passes & Receipt': 'Imprimir Pases y Recibos',
  'Download Access Pass': 'Descargue Permiso de Acceso',
  'Terms of use': 'Términos de Uso',
  Copyright: 'Derechos de autor',
  'Powered by': 'Energizado por',
  'Privacy Policy': 'Política de privacidad',
  'Terms and Conditions': 'Términos y condiciones',
  'Incomplete Passes (Rental Cars)': 'Permisos Incompletos (Autos de alquiler)',
  'Expired Passes': 'Permisos Vencidos',
  'Click here to complete information': 'Seleccione aquí para completar',
  'Ready to Scan': 'Lista para escanear',
  'Activated Passes': 'Permisos Activos',
  View: 'Ver',
  Share: 'Cuota',
  Complete: 'Completa',
  'Plate Number': 'Numero de placa',
  'Pass #': '# de Permiso',
  'Pass Shared': 'Pase compartido',
  Recipient: 'Recipiente',
  pass_share_prompt:
    'Para compartir un permiso, por favor ingrese el nombre del recipiente, numero de telefono y/o correo eletcronico.',
  'Add Recipient': 'Agregue recipiente',
  'Please verify that the above information is current and accurate.':
    'Verifique que la información anterior sea actual y precisa.',
  'Reset Password': 'Restablecer Contraseña',
  'Sign Out': 'Desconectar',
  'Enter your old password': 'Ingrese su contraseña anterior',
  'Enter your new password': 'Ingrese su nueva contraseña',
  'Confirm your new password': 'Confirme su nueva contraseña',
  'Driver Information (optional)': 'Información del conductor (Opcional)',
  'Register a Rental Car': 'Registrar Vehículo de Alquiler',
  'You have': 'Tienes',
  'incomplete passes': 'permisos incompletos',
  'If you have not yet purchased a pass, please click':
    'Si aún no ha comprado un pase, por favor haga clic',
  Here: 'Aquí.',
  'Current Passes': 'Pases actuales',
};

export default es;
