import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import alertPic from '../../assets/alert.svg';
import styles from './searchSelector.module.css';
import useSearchSelector from './useSearchSelector';

export type SearchSelectorProps = {
  htmlFor: string;
  title: string;
  value: string;
  changeHandler: (val: string) => void;
  required?: boolean;
  disabled?: boolean;
};

export default function SearchSelector(props: SearchSelectorProps): ReactElement {
  const { htmlFor, title, value, required, disabled } = props;

  const {
    open,
    setOpen,
    optionsList,
    parentId,
    showWarning,
    checkWarning,
    optionSelectHandler,
    innerChangeHandler,
  } = useSearchSelector(props);

  const { t } = useTranslation();

  return (
    <div id={parentId} className={styles.searchBox}>
      <label htmlFor={htmlFor}>
        {title}
        <input
          className={styles.searchSelector}
          type="text"
          required={required && !disabled}
          disabled={disabled}
          value={value}
          onChange={innerChangeHandler}
          onFocus={() => {
            setOpen(true);
          }}
          onBlur={checkWarning}
        />
      </label>
      {
        // TODO: adjust these styles coppied from 'createAccount.tsx'
        showWarning ? (
          <div className={styles.box__passwordAlert}>
            <ul id="password-reqs-list" className={styles.box__passwordInner}>
              <div className={styles.triangle} />
              <li className={`${styles.box__passwordText} backgroungColor textColorDark`}>
                <img src={alertPic} alt="" className={styles.alert} />
                {t('address_no_match')}
              </li>
            </ul>
          </div>
        ) : null
        // END TODO
      }{' '}
      {open && optionsList.length > 0 ? (
        <div className={styles.searchList}>
          {optionsList.map((o: string) => (
            <button onClick={() => optionSelectHandler(o)} key={o}>
              {o}
            </button>
          ))}
        </div>
      ) : null}
    </div>
  );
}
SearchSelector.defaultProps = {
  required: false,
  disabled: false,
};
