import { gql, useLazyQuery } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { CommunityContext } from '../../communityConfigs/communityContextProvider';
import ForgetPWPagesBackground from '../../components/forgetPWPagesBackground/forgetPWPagesBackground';
import GenericAlert from '../../components/genericAlert/genericAlert';
import GenericButton from '../../components/genericButton/genericButton';
import InputField from '../../components/inputField/inputField';
import PhoneInputWrapper from '../../components/phoneInputWrapper/phoneInputWrapper';
import { backendTokenResponse } from '../../types/backendResponse';
import store from '../../utilities/store';
import useTextSelection, { textOptionsType } from '../../utilities/useTextSelection';
import style from './inviteAccountClaim.module.css';

const SET_REFERED_USER_PASSWORD = gql`
  query SetReferedUserPassword(
    $confirmPassword: String
    $email: String
    $hasEmail: Boolean
    $password: String
    $phoneNumber: String
    $referralCode: String
  ) {
    setReferredUserPassword(
      confirmPassword: $confirmPassword
      email: $email
      hasEmail: $hasEmail
      password: $password
      phoneNumber: $phoneNumber
      referralCode: $referralCode
    ) {
      success
      error
      token
      data {
        userId
        firstName
        lastName
        email
        phoneNumber
        roles
      }
    }
  }
`;
type SET_REFERED_USER_PASSWORD_VARS = {
  confirmPassword: string;
  email: string;
  hasEmail: boolean;
  password: string;
  phoneNumber: string;
  referralCode: string;
};
type SET_REFERED_USER_PASSWORD_RES = {
  setReferredUserPassword: backendTokenResponse<{
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    roles: string[];
    userId: string;
  }>;
};

type textConfigType = {
  title: (communityName: string) => string;
  subtitle: string;
};
const textConfig: textOptionsType<textConfigType> = {
  default: {
    default: {
      title: (communityName: string) => `Welcome to ${communityName}.`,
      subtitle: 'Before you continue, please set your password.',
    },
  },
};

interface Props {
  role: 'guest' | 'resident' | 'admin' | 'vendor' | 'symliv';
  successHref: string;
}

export default function InviteAccountClaim(props: Props): React.ReactElement {
  const { role, successHref } = props;
  const [alert, setAlert] = useState<string>('');
  const [redirect, setRedirect] = useState<string>('');
  const { title, subtitle } = useTextSelection(textConfig, role);
  const { name: communityName } = useContext(CommunityContext);
  const [email, setEmail] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const { hash } = useLocation();
  const queryHashSplit = hash.split('?');
  const referralCode = queryHashSplit[0].startsWith('#')
    ? queryHashSplit[0].slice(1)
    : queryHashSplit[0];
  const queryParams = new URLSearchParams(queryHashSplit[1]);
  const hasEmail = queryParams.get('email') === 'true';
  const hasPhone = queryParams.get('hasPhone') === 'true';

  const [doSetPassword] = useLazyQuery<
    SET_REFERED_USER_PASSWORD_RES,
    SET_REFERED_USER_PASSWORD_VARS
  >(SET_REFERED_USER_PASSWORD, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: () => {
      setAlert('Something went wrong. Please ensure all data is correct');
    },
    onCompleted: d => {
      if (d.setReferredUserPassword.error) {
        setAlert(d.setReferredUserPassword.error);
        return;
      }
      store.dispatch({
        type: 'user/login',
        payload: {
          token: d.setReferredUserPassword.token,
          user: d.setReferredUserPassword.data,
        },
      });
      setRedirect(successHref);
    },
  });

  if (redirect) return <Redirect to={redirect} />;
  return (
    <ForgetPWPagesBackground>
      <div>
        <h3 className={`${style.box__mainTitleRight} textColorMain `}>
          {title(communityName)}
        </h3>
        <h5 className={`${style.box__subTitle} textColorDark `}>{subtitle}</h5>
        <form
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            if (password !== confirmPassword) {
              setAlert('Please check your password. These do not match.');
              return;
            }
            doSetPassword({
              variables: {
                confirmPassword,
                email,
                hasEmail,
                password,
                phoneNumber,
                referralCode,
              },
            });
          }}
        >
          <div className={style.alert}>
            <div className={style.alertBox}>
              <GenericAlert color="red" title={alert} hidden={!alert} />
            </div>
          </div>
          {!hasEmail && (
            <div className={style.box__inputLong}>
              <div className={style.box__inputField}>
                <PhoneInputWrapper
                  inputTitle={`${hasPhone ? 'Confirm ' : ''}Phone Number`}
                  value={phoneNumber}
                  changeHandler={e => setPhoneNumber(e)}
                  smallStandardInput
                  required={hasPhone}
                />
              </div>
            </div>
          )}
          <div className={style.box__inputLong}>
            <div className={style.box__inputField}>
              <InputField
                smallSize
                highlightOnFocus
                htmlFor="email-inpt"
                labelTitle={hasEmail ? 'Confirm Email' : 'Enter Email Address'}
                inputType="email"
                inputValue={email}
                changeHandler={e => setEmail(e.target.value)}
                required
              />
            </div>
          </div>
          <div className={style.box__inputLong}>
            <div className={style.box__inputField}>
              <InputField
                smallSize
                highlightOnFocus
                htmlFor="pass-inpt"
                labelTitle="New Password"
                inputType="password"
                inputValue={password}
                changeHandler={e => setPassword(e.target.value)}
                required
              />
            </div>
          </div>
          <div className={style.box__inputLong}>
            <div className={style.box__inputField}>
              <InputField
                smallSize
                highlightOnFocus
                htmlFor="pass-inpt-confirm"
                labelTitle="Confirm Password"
                inputType="password"
                inputValue={confirmPassword}
                changeHandler={e => setConfirmPassword(e.target.value)}
                required
              />
            </div>
          </div>
          <div className={style.box__btnRight}>
            <div className={style.box__btnRightInnerBox}>
              <GenericButton size="medium" color="blue" title="Enter" type="submit" />
            </div>
          </div>
        </form>
      </div>
    </ForgetPWPagesBackground>
  );
}
