import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export function useURLParams<T extends string>(keys: T[]): Record<T, string | null> {
  const { search } = useLocation();

  return useMemo(() => {
    const params = new URLSearchParams(search);
    return keys.reduce((res, key) => {
      res[key] = params.get(key);
      return res;
    }, {} as Record<T, string | null>);
  }, [search, ...keys]); // eslint-disable-line react-hooks/exhaustive-deps
}
