import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../common_lib_front/components/inputField/inputField';
import style from './driverInfoForm.module.css';

export default function DriverInfoForm(): ReactElement {
  const { t } = useTranslation();
  return (
    <div className={style.driverFormBox}>
      <p className={`${style.title}`}>{t('Driver Information (optional)')}</p>
      <div className={style.inputShortBox}>
        <div className={style.inputShort}>
          <InputField closedInput htmlFor="fname-inpt" labelTitle={t('First Name')} />
        </div>
        <div className={style.inputShort}>
          <InputField closedInput htmlFor="lname-inpt" labelTitle={t('Last Name')} />
        </div>
      </div>
      <div className={style.inputShortBox}>
        <div className={style.inputShort}>
          <InputField closedInput htmlFor="email-inpt" labelTitle={t('Email Address')} />
        </div>
        <div className={style.inputShort}>
          <InputField closedInput htmlFor="phone-inpt" labelTitle={t('Phone Number')} />
        </div>
      </div>
    </div>
  );
}
