import React, { ReactElement, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import GuestInfo, { GuestInfoConfig } from '../../common_lib_front/types/guestInfo';
import store from '../../common_lib_front/utilities/store';
import style from './personalInformationReview.module.css';

type PersonalInformationReviewProps = {
  data: GuestInfo;
  editLink?: string;
  config?: GuestInfoConfig;
};

const defaultConfig: GuestInfoConfig = {
  communityCode: 'optional',
  status: 'optional',
  firstName: 'required',
  lastName: 'required',
  email: 'required',
  phoneNumber: 'required',
  emergencyContact: 'required',
  address: 'required',
  country: 'required',
  city: 'required',
  state: 'required',
  zipCode: 'required',
  rentalAddress: 'required',
  arrivalDate: 'required',
  departureDate: 'required',
  numberGuests: 'required',
  communityRentalId: 'disabled',
};

export const PersonalInformationReview = (
  props: PersonalInformationReviewProps,
): ReactElement => {
  const [editMode, setEditMode] = useState(false);
  const { communityId } = useContext(CommunityContext);
  const { data, editLink, config } = props;
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <div className={style.infoFormBox}>
      <h3 className={`${style.reviewTitle} textColorSuperDark `}>
        {t('Review Information')}
      </h3>
      <p className={`${style.subTitle} textColorSuperDark `}>
        {t('Please verify all information below is correct')}
      </p>
      <div className={`${style.header} superDarkGrey `}>
        <h5 className={`${style.title} textColorWhite `}>{t('Personal Information')}</h5>
        <div className={style.btnEdit}>
          <GenericButton
            color="transparent"
            title={t('Edit')}
            clickHandler={() => {
              if (editLink) {
                history.push(editLink);
              } else {
                setEditMode(!editMode);
              }
            }}
          />
        </div>
      </div>
      <table className={style.tableBox}>
        <tr>
          <th>{t('NAME')}</th>
          <td>
            <li className={`${style.input} textColorDark `}>{data.firstName}</li>
            <li className={`${style.input} textColorDark `}>{data.lastName} </li>
          </td>
          <td />
        </tr>
        {config?.address !== 'disabled' && communityId !== 'watercolor' ? (
          <tr>
            <th>{t('ADDRESS')}</th>
            <td>
              <li className={`${style.input} textColorDark `}>{data.address}</li>
            </td>
          </tr>
        ) : null}
        {config?.phoneNumber !== 'disabled' ? (
          <tr>
            <th>{t('PHONE#')}</th>
            <td>
              <li className={`${style.input} textColorDark `}>{data.phoneNumber}</li>
            </td>
          </tr>
        ) : null}
        <tr>
          <th>EMAIL</th>
          <td>
            <li className={`${style.input} textColorDark `}>
              {' '}
              {data.email ? data.email : store.getState().user?.email}
            </li>
          </td>
        </tr>
      </table>
    </div>
  );
};
PersonalInformationReview.defaultProps = {
  editLink: '',
  config: defaultConfig,
};

export default PersonalInformationReview;
