import { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CreditCardImg } from '../../../assets/creditCard.svg';
import { ReactComponent as ProfileImg } from '../../../assets/profile.svg';
import { ReactComponent as RulesImg } from '../../../assets/rules.svg';
import { ReactComponent as TicketImg } from '../../../assets/ticket.svg';
import { ReactComponent as VehicleImg } from '../../../assets/vehicle.svg';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import PassInfo from '../../../common_lib_front/types/passInfo';
import IncompletePassTable from '../../../components/incompletePassTable/incompletePassTable';
import PopUpSmall from '../../../components/popUp/popUpSmall';
import PortalCard from '../../../components/portalCard/portalCard';
import useGuestPasses from '../../passes/guestPasses/useGuestPasses';
import style from './guestHome.module.css';

export default function Guest(): ReactElement {
  const { t } = useTranslation();
  const {
    name: communityName,
    communityId,
    featuresConfig,
  } = useContext(CommunityContext);

  // get guest passes
  const { allPasses } = useGuestPasses();
  const [groupedPasses, setGroupedPasses] = useState<Array<PassInfo>>([]);

  // open incomplete popup
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const showIncompletePasses = (() => {
    if (isOpen) {
      return (
        <PopUpSmall
          title="You have incomplete passes. Please select your pass(es) from this list to complete"
          isOpen={isOpen}
          close={() => setIsOpen(false)}
          showCircle
        >
          <div className={style.popUpInnerBox}>
            {/* Host invites */}
            <div className={style.notification}>
              <p>
                Host Invites - Please complete this invite from your short-term rental
                host to receive your pass to access the community.
              </p>
            </div>
            <IncompletePassTable
              data={
                communityId === 'watercolor'
                  ? groupedPasses
                  : allPasses.filter(
                      (p: PassInfo) => p.status.toLowerCase() === 'incomplete',
                    )
              }
              title="Host Invites"
              showCompleteBtn={(p: PassInfo) => `/guest/registration/${p.registrationId}`}
            />
            {communityId !== 'watercolor' ||
            allPasses.filter(p => p.status.toLowerCase() === 'incomplete-rental-car')
              .length > 0 ? (
              <>
                <div className={style.notification}>
                  <p>
                    Rental Cars - Please enter your rental car information to activate
                    your community access pass.
                  </p>
                </div>
                <IncompletePassTable
                  data={allPasses.filter(
                    (p: PassInfo) => p.status.toLowerCase() === 'incomplete-rental-car',
                  )}
                  title="Rental Cars"
                  showCompleteBtn="/guest/registration/rental"
                />
              </>
            ) : null}
          </div>
        </PopUpSmall>
      );
    }
    return <></>;
  })();

  const checkIncompletePasses = useCallback(() => {
    if (
      allPasses.filter((p: PassInfo) => p.status.toLowerCase() === 'incomplete').length >
        0 ||
      allPasses.filter(
        (p: PassInfo) => p.status.toLowerCase() === 'incomplete-rental-car',
      ).length > 0
    ) {
      setIsOpen(true);
    }
  }, [allPasses, setIsOpen]);

  useEffect(() => {
    checkIncompletePasses();
    const grouped = Array<PassInfo>();
    allPasses
      .filter((p: PassInfo) => p.status.toLowerCase() === 'incomplete')
      .forEach((p: PassInfo) => {
        if (
          !grouped.filter((gp: PassInfo) => gp.registrationId === p.registrationId).length
        ) {
          grouped.push(p);
        }
      });
    setGroupedPasses(grouped);
  }, [allPasses, checkIncompletePasses]);

  return (
    <div className={style.container}>
      <div className={style.container__innerContainer}>
        {/* when popup open, this will disappear */}
        {
          // btnNotifyIncompletePass
        }
        <div className={style.box}>
          <div className={style.box__innerBox} hidden={true}>
            <PortalCard
              img={<CreditCardImg />}
              title={t('Purchase New Pass')}
              description={t(
                'Buy new Community Access Passes and update billing information.',
              )}
              href="/guest/registration"
              btnLabel={t('Buy a New Pass')}
            />
          </div>
          <div className={style.box__innerBox}>
            <PortalCard
              img={<ProfileImg />}
              title={t('My Profile')}
              description={t(
                'Access and edit your profile information including name, address, and contact.',
              )}
              href="/guest/edit-profile"
              btnLabel={t('Edit Info')}
            />
          </div>
          <div className={style.box__innerBox}>
            <PortalCard
              img={<TicketImg />}
              title={t('Access My Passes')}
              description={t('View all your current access passes.')}
              href="/guest/my-passes"
              btnLabel={t('Manage Passes')}
            />
          </div>
          {communityId !== 'watercolor' && (
            <div className={style.box__innerBox}>
              <PortalCard
                img={<VehicleImg />}
                title={t('Register Rental Car')}
                description={t(
                  'Return and register your rental car information if you were unable to before.',
                )}
                href="/guest/registration/rental"
                btnLabel={t('Register My Rental Car')}
              />
            </div>
          )}
          {communityName === 'Grand Complex' ? null : (
            <div className={style.box__innerBox}>
              <PortalCard
                img={<RulesImg />}
                title={t('Review Community Rules')}
                description={t(
                  'Review community policies, rules, and procedures before entering the community.',
                )}
                href="/guest/community-rules"
                btnLabel={t('Review Rules')}
              />
            </div>
          )}
        </div>
      </div>
      {/* Show incomplete pass */}
      {showIncompletePasses}
    </div>
  );
}
