import html2canvas from 'html2canvas';
import React, { ReactElement, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import PassInfo from '../../../common_lib_front/types/passInfo';
import { formatDate } from '../../../common_lib_front/utilities/formatDate';
import { PassImageDisplay } from '../../../components/passGenerator/passGenerator';
import { PopUp } from '../../../components/popUp/popUp';
import style from './receiptDownload.module.css';

interface ReceiptDownloadProps {
  passes: PassInfo[];
}

export default function ReceiptDownload(props: ReceiptDownloadProps): ReactElement {
  const history = useHistory();
  const { registrationId } = useParams<{ registrationId: string }>();
  const { passes } = props;
  const { passLogo, name: communityName } = useContext(CommunityContext);

  const doDownload = () => {
    const container = document.getElementById('receipt-download-container');
    if (container) {
      html2canvas(container, {
        allowTaint: true,
        useCORS: true,
      }).then(canvas => {
        const link = document.createElement('a');
        link.download = 'receipt.png';
        canvas.toBlob(blob => {
          if (!blob) return;
          link.href = URL.createObjectURL(blob);
          link.click();
        });
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PopUp title="Print Passes and Receipt" isOpen close={() => history.goBack()}>
      <div className={style.box}>
        {/* print container */}
        <div id="receipt-download-container">
          {/* receipt */}
          <div className={style.flexBox}>
            <div className={`${style.receiptBox} white `}>
              <div className={`${style.shapeBig}`} />
              <div className={`${style.shapeSmall}`} />
              <div className={`${style.circle} white`}>
                <img src={passLogo} alt="" className={style.logo} />
              </div>
              <div className={style.infoBox}>
                <h5 className={`${style.receiptTitle} textColorSuperDark`}>
                  Receipt from
                  <span className={style.namePicker}>{communityName}</span>
                </h5>
                <p className={`${style.receiptNumber} textColorMedium`}>
                  Receipt # {registrationId}
                </p>
                <div className={style.infoDetailBox}>
                  <ul className={style.infoTitle}>
                    AMMOUNT PAID
                    <li>${passes.length * 10}</li>
                  </ul>

                  <ul className={style.infoTitle}>
                    DATE PAID
                    <li>{formatDate()}</li>
                  </ul>

                  <ul className={style.infoTitle}>
                    PAYMENT METHOD{' '}
                    <li>
                      <span className={style.cardName}>Visa</span> - 0000
                    </li>
                  </ul>
                </div>
                <h6 className={`${style.summary} textColorMedium`}>SUMMARY</h6>
                <table
                  cellSpacing="0px"
                  className={`${style.tableSummary} backgroungColor`}
                >
                  <tbody>
                    <tr>
                      <th className={style.thWithLine}>
                        Payment to
                        <span className={style.namePickerSmall}> {communityName}</span>
                      </th>
                      <td className={style.thWithLine}>${passes.length * 10}</td>
                    </tr>
                    <tr>
                      <th>Ammount Paid</th>
                      <td>${passes.length * 10}</td>
                    </tr>
                  </tbody>
                </table>
                <div className={`${style.line} lightGrey`} />
                <p className={`${style.contactText} textColorMedium`}>
                  If you have any questions, contact us at{' '}
                  <a
                    href="CrystalBeach@stripe.com"
                    className={`${style.linkText} textColorMain`}
                  >
                    {communityName}
                    @stripe.com
                  </a>{' '}
                  or call at{' '}
                  <p className={`${style.linkText} textColorMain`}>+1 555-123-4562</p>.
                </p>
                <div className={`${style.line} lightGrey`} />
                <p className={`${style.problemText} textColorMedium`}>
                  Something wrong with with the email?{' '}
                  <a href="test.ex.com" className={`${style.linkText} textColorMain`}>
                    View it in your browser
                  </a>
                </p>
                <p className={`${style.problemText} textColorMedium`}>
                  You are receiving this email because you made a purchase with{' '}
                  {communityName}. {communityName} partners with Stripe to provide secure
                  invoicing and payment processing.
                </p>
              </div>
            </div>
            {/* passes */}
            <div className={style.passCollectionBox}>
              {passes.map((elem: PassInfo) => (
                // <PassGenerator key={elem.passId} pass={elem} />
                <PassImageDisplay key={elem.passId} passId={elem.passId} url={elem.url} />
              ))}
            </div>
          </div>
        </div>
        <div className={style.btnBox}>
          <div className={style.btn}>
            <GenericButton
              size="large"
              color="blue"
              title="Print Passes &amp; Receipt"
              clickHandler={doDownload}
            />
          </div>
        </div>
      </div>
    </PopUp>
  );
}
