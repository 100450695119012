import { gql, useQuery } from '@apollo/client';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CommunityContext } from '../common_lib_front/communityConfigs/communityContextProvider';
import { backendResponse } from '../common_lib_front/types/backendResponse';
import { InfoConfigValues } from '../common_lib_front/types/InfoConfigValues';
import {
  VehicleInfoConfig,
  newVehicleInfoConfig,
} from '../common_lib_front/types/vehicleInfo';
import { backendClient } from '../common_lib_front/utilities/BackendAPI';
import {
  GET_DURATION_INFO,
  GET_DURATION_INFO_RES,
  GET_DURATION_INFO_VARS,
} from '../pages/registration/registerVehicle/registerVehicleRequests';
import {
  GET_STEP_ONE,
  GET_STEP_ONE_QUERY,
  GET_STEP_ONE_RESPONSE,
} from '../pages/registration/registrationHome/registrationHomeRequests';
const GET_COMMUNITY_PASSES = gql`
  query GetPassInfosByCommunity($communityId: String!) {
    getPassInfosByCommunity(complete: true, communityId: $communityId) {
      success
      error
      data {
        passInfoId
        portal
        name
        description
        price
        customInstructions
      }
    }
  }
`;

const GET_VEHICLE = gql`
  query GetVehicleInfoConfig($passInfoId: String!) {
    getVehicleInfoConfig(passInfoId: $passInfoId) {
      success
      error
      data {
        color
        fleetNumber
        licensePlate
        licensePlateState
        licensePrimaryDriver
        make
        passInfoId
        primaryDriverName
        type
        vehicleModel
        name
        completeLater
      }
    }
  }
`;

type GET_VEHICLE_VARS = {
  passInfoId: string;
};

type GET_VEHICLE_RES = {
  getVehicleInfoConfig: backendResponse<{
    color: keyof typeof InfoConfigValues;
    fleetNumber: keyof typeof InfoConfigValues;
    licensePlate: keyof typeof InfoConfigValues;
    licensePlateState: keyof typeof InfoConfigValues;
    licensePrimaryDriver: keyof typeof InfoConfigValues;
    make: keyof typeof InfoConfigValues;
    passInfoId: string;
    primaryDriverName: keyof typeof InfoConfigValues;
    type: keyof typeof InfoConfigValues;
    vehicleModel: keyof typeof InfoConfigValues;
  }>;
};

interface IPriceInfo {
  passInfoId: string;
  isFree: boolean;
  price: number;
  chargePerDay?: boolean;
}

interface IDurationInfo {
  type: number;
  duration1: number;
}

export interface PassInfoData {
  passInfoId: string;
  portal: string;
  name: string;
  description: string;
  price: number;
  priceInfo?: IPriceInfo | null;
  durationInfo?: IDurationInfo | null;
  customInstructions?: string;
}

export default function useVehicleConfigs(): {
  passInfoDatas: PassInfoData[];
  vehicleConfig: { [passInfoId: string]: VehicleInfoConfig };
  guestName: string;
} {
  // eslint-disable-line indent
  const [vehicleConfig, setVehicleConfig] = useState<{
    [passInfoId: string]: VehicleInfoConfig;
  }>({});
  const [guestName, setGuestName] = useState<string>('');
  const [passInfoDatas, setPassInfoDatas] = useState<PassInfoData[]>([]);
  const { communityId } = useContext(CommunityContext);
  const { registrationId } = useParams<{ registrationId: string }>();

  // helper to fetch vehicle config
  const fetchVehicle = (
    passInfoId: string,
  ): Promise<{
    [passInfoId: string]: VehicleInfoConfig;
  }> =>
    backendClient
      .query<GET_VEHICLE_RES, GET_VEHICLE_VARS>({
        // query options
        query: GET_VEHICLE,
        variables: {
          passInfoId,
        },
      })
      // process successful results
      .then((d): { [passInfoId: string]: VehicleInfoConfig } => {
        if (d.data.getVehicleInfoConfig.data) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { passInfoId: _, ...validAttrs } = d.data.getVehicleInfoConfig.data;

          return {
            [passInfoId]: newVehicleInfoConfig(
              validAttrs as unknown as VehicleInfoConfig,
            ),
          };
        }
        return {
          [passInfoId]: newVehicleInfoConfig(),
        };
      })
      // catch error with default
      .catch((): { [passInfoId: string]: VehicleInfoConfig } => ({
        [passInfoId]: newVehicleInfoConfig(),
      }));

  // get community pass info configs
  useQuery<{
    getPassInfosByCommunity: {
      success: boolean;
      error: string;
      data?: PassInfoData[];
    };
  }>(GET_COMMUNITY_PASSES, {
    // query options
    variables: {
      communityId,
    },
    onCompleted: async d => {
      if (d.getPassInfosByCommunity.data) {
        // get vehicle for each passInfoId fetched
        Promise.all(
          d.getPassInfosByCommunity.data
            .filter(p => p.portal === 'guest')
            .map(p => fetchVehicle(p.passInfoId)),
        ).then(res => {
          // consolidate results into single object
          const final = {};
          res.forEach(r => {
            Object.assign(final, r);
          });
          setVehicleConfig(final);
        });
      }

      const res = d?.getPassInfosByCommunity.data || [];
      // I managed to use the result variable instead of changing res variable due to
      // res is not allowed to be extensible
      const result = [];

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < res?.length; i++) {
        const p = {
          ...res[i],
        };
        result.push(p);
      }

      // * Get passes' durationInfos by passInfoId
      try {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < result?.length; i++) {
          const p = result[i];
          // eslint-disable-next-line no-await-in-loop
          const durationInfoRes = await backendClient.query<
            GET_DURATION_INFO_RES,
            GET_DURATION_INFO_VARS
          >({
            query: GET_DURATION_INFO,
            variables: { passInfoId: p?.passInfoId },
          });
          p.durationInfo = durationInfoRes?.data?.getPassInfo?.data?.durationInfo;
        }
      } catch (error: any) {
        console.log(error);
      }

      setPassInfoDatas(result);
    },
  });
  useQuery<GET_STEP_ONE_RESPONSE, GET_STEP_ONE_QUERY>(GET_STEP_ONE, {
    variables: {
      registrationId,
    },
    fetchPolicy: 'network-only',
    onCompleted: d => {
      if (d.getGuestInfoData.data) {
        const fname = d.getGuestInfoData.data?.firstName || '';
        const lname = d.getGuestInfoData.data?.lastName || '';
        setGuestName(`${fname} ${lname}`);
      }
    },
    onError: e => {
      console.log(e);
    },
  });
  return {
    passInfoDatas,
    vehicleConfig,
    guestName,
  };
}
