import React, { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import PassSummaryCollection from '../../../components/passSummary/passSummaryCollection';
import PersonalInformationReview from '../../../components/personalInformationReview/personalInformationReview';
import RegistrationNavHeader from '../../../components/registrationNavHeader/registrationNavHeader';
// import WarnBeforeUnload from '../warnBeforeUnload/warnBeforeUnload';
import { RegistrationContext, RegistrationPageProps } from '../wrapper/wrapper';
import style from './secureCheckout.module.css';
import useSecureCheckout from './useSecureCheckout';

export default function SecureCheckout(props: RegistrationPageProps): ReactElement {
  const { nextHref } = props;
  const { steps, pageConfigs } = useContext(RegistrationContext);
  const { userInfo, passes, redirect, submitHandler, alert, loading } =
    useSecureCheckout(nextHref);
  const { t } = useTranslation();
  const { name: communityName } = useContext(CommunityContext);

  // WarnBeforeUnload();

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>
        <RegistrationNavHeader
          title={`${t('Step 4')}: ${t('Secure Checkout')}`}
          subtitle={t('step4_subtitle')}
        />
        <GenericAlert title={alert} hidden={!alert} color="red" />
        <div className={style.flexBox}>
          {pageConfigs && steps.includes('guest') && (
            <div className={style.boxLeft}>
              <PersonalInformationReview
                data={userInfo}
                editLink={pageConfigs.guest.href}
              />
            </div>
          )}
          {pageConfigs && steps.includes('vehicle') && (
            <div className={style.boxRight}>
              <PassSummaryCollection
                showVehicleInfo
                showEditBtn
                showTotal
                data={passes}
                editLink={pageConfigs.vehicle.href}
              />
            </div>
          )}
        </div>
        <div className={style.btn}>
          <GenericButton
            size="large"
            color="blue"
            title={communityName === 'Grand Complex' ? 'Confirm Purchase' : t('Continue')}
            clickHandler={submitHandler}
            disabled={loading}
          />
        </div>
      </div>
    </div>
  );
}
