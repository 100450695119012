import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { GuestInfo, newGuestInfo } from '../../../common_lib_front/types/guestInfo';
import RentalInfo, { newRentalInfo } from '../../../common_lib_front/types/rentalInfo';
import {
  CREATE_GUEST,
  CREATE_GUEST_QUERY,
  CREATE_GUEST_RESPONSE,
  EDIT_COMMUNITY_RENTAL,
  EDIT_COMMUNITY_RENTAL_RESPONSE,
  EDIT_COMMUNITY_RENTAL_QUERY,
  GET_STEP_ONE,
  GET_STEP_ONE_QUERY,
  GET_STEP_ONE_RESPONSE,
  REGISTER_COMMUNITY_RENTAL,
  REGISTER_COMMUNITY_RENTAL_QUERY,
  REGISTER_COMMUNITY_RENTAL_RESPONSE,
  STEP_ONE_SUBMIT,
  STEP_ONE_SUBMIT_QUERY,
  STEP_ONE_SUBMIT_RESPONSE,
  UPDATE_GUEST,
  UPDATE_GUEST_QUERY,
  UPDATE_GUEST_RESPONSE,
  UPDATE_USER,
  UPDATE_USER_QUERY,
  UPDATE_USER_RESPONSE,
} from './registrationHomeRequests';

type Params = {
  role: string;
  registrationId: string;
};
export default function useRegistrationHome(nextHref: string): {
  guestInfo: GuestInfo;
  setGuestInfo: (data: GuestInfo) => void;
  rentalInfo: RentalInfo;
  setRentalInfo: (data: RentalInfo) => void;
  redirect: string;
  registrationId: string;
  alert: string;
  doSubmitAll: () => Promise<void>;
} {
  // eslint-disable-line indent
  const [redirect, setRedirect] = useState<string>('');
  const { registrationId } = useParams<Params>();
  const [guestInfo, setGuestInfo] = useState<GuestInfo>(newGuestInfo());
  const [rentalInfo, setRentalInfo] = useState<RentalInfo>(newRentalInfo());
  const [alert, setAlert] = useState<string>('');

  const [doRegisterGuest] = useMutation<CREATE_GUEST_RESPONSE, CREATE_GUEST_QUERY>(
    CREATE_GUEST,
    {
      onError: () => {
        // Required to catch errors
      },
    },
  );
  const [doEditGuest] = useMutation<UPDATE_GUEST_RESPONSE, UPDATE_GUEST_QUERY>(
    UPDATE_GUEST,
    {
      onError: () => {
        // Required to catch errors
      },
    },
  );
  const [doUpdateUser] = useMutation<UPDATE_USER_RESPONSE, UPDATE_USER_QUERY>(
    UPDATE_USER,
    {
      onError: () => {
        //
      },
    },
  );
  const [doRegisterRental] = useMutation<
    REGISTER_COMMUNITY_RENTAL_RESPONSE,
    REGISTER_COMMUNITY_RENTAL_QUERY
  >(REGISTER_COMMUNITY_RENTAL, {
    onError: () => {
      // Required to catch errors
    },
  });

  const [doEditRental] = useMutation<
    EDIT_COMMUNITY_RENTAL_RESPONSE,
    EDIT_COMMUNITY_RENTAL_QUERY
  >(EDIT_COMMUNITY_RENTAL, {
    onError: () => {
      // Required to catch errors
    },
  });

  useQuery<GET_STEP_ONE_RESPONSE, GET_STEP_ONE_QUERY>(GET_STEP_ONE, {
    variables: {
      registrationId,
    },
    fetchPolicy: 'network-only',
    onCompleted: d => {
      if (d.getCommunityRental.success) {
        setRentalInfo(newRentalInfo(d.getCommunityRental.data || {}));
      }
      if (d.getGuestInfoData.data) {
        setGuestInfo(newGuestInfo(d.getGuestInfoData.data));
      } else {
        setGuestInfo(newGuestInfo(d.getUserInfo.data || undefined));
      }
    },
    onError: e => {
      console.log(e);
    },
  });

  const [doSubmit] = useMutation<STEP_ONE_SUBMIT_RESPONSE, STEP_ONE_SUBMIT_QUERY>(
    STEP_ONE_SUBMIT,
    {
      onError: e => {
        if (process.env.REACT_APP_DEBUG === 'true') {
          setRedirect(nextHref);
        } else {
          setAlert(e.message);
        }
      },
      onCompleted: d => {
        if (d.editRegistrationStepNumber.success) {
          setRedirect(nextHref);
        } else {
          setAlert(d.editRegistrationStepNumber.error);
        }
      },
    },
  );

  /*
   * Wrapper function to do all submit actions
   */
  const doSubmitAll = async (): Promise<void> => {
    // Check that guest info is completely filled out
    if (guestInfo.guestInfoId) {
      doEditGuest({
        variables: {
          registrationId,
          guestInfo: {
            lastName: guestInfo.lastName,
            firstName: guestInfo.firstName,
            phoneNumber: guestInfo.phoneNumber,
            address: guestInfo.address,
            city: guestInfo.city,
            state: guestInfo.state,
            country: guestInfo.country,
            zipCode: guestInfo.zipCode,
          },
        },
      });
      // If guest information does not exist, create it
    } else {
      doRegisterGuest({
        variables: {
          registrationId,
          newGuestInfo: {
            lastName: guestInfo.lastName,
            firstName: guestInfo.firstName,
            phoneNumber: guestInfo.phoneNumber,
            address: guestInfo.address,
            city: guestInfo.city,
            state: guestInfo.state,
            country: guestInfo.country,
            zipCode: guestInfo.zipCode,
          },
        },
      });
    }
    // update user information to match guest info
    doUpdateUser({
      variables: {
        userInfo: {
          lastName: guestInfo.lastName,
          firstName: guestInfo.firstName,
          phoneNumber: guestInfo.phoneNumber,
          address: guestInfo.address,
          city: guestInfo.city,
          state: guestInfo.state,
          country: guestInfo.country,
          zipCode: guestInfo.zipCode,
        },
      },
    });
    if (rentalInfo.communityRentalId) {
      // Edit rental
      doEditRental({
        variables: {
          communityRentalId: rentalInfo.communityRentalId,
          // arrivalDate: rentalInfo.arrivalDate,
          // departureDate: rentalInfo.departureDate,
          address: rentalInfo.address,
          numberOfPets: rentalInfo.numberPets,
        },
      });
      // register rental
    } else {
      doRegisterRental({
        variables: {
          registrationId,
          newCommunityRental: {
            // arrivalDate: rentalInfo.arrivalDate,
            // departureDate: rentalInfo.departureDate,
            address: rentalInfo.address,
          },
        },
      });
    }
    if ((window as any).registrationLockStepNum >= 2) {
      // eslint-disable-line
      setRedirect(`/guest/registration/${registrationId}/vehicle`);
      return;
    }
    doSubmit({
      variables: { registrationId, stepNumber: 2 },
    }).then(() => {
      setRedirect(`/guest/registration/${registrationId}/vehicle`);
    });
  };

  return {
    guestInfo,
    setGuestInfo,
    rentalInfo,
    setRentalInfo,
    redirect,
    registrationId,
    alert,
    doSubmitAll,
  };
}
