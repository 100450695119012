import Tippy from '@tippyjs/react';
import React, { useState, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ActionImg } from '../../../assets/actionIcon.svg';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import isRefundOptionAllow from '../../../common_lib_front/utilities/refundOptionConfig';
// import { exportPdf } from '../../../common_lib_front/utilities/pdf';
import style from './actionPopUp.module.css';

interface ActionCellProps {
  data: {
    passId: string;
    passStatus: string;
    passType: string;
    url: string;
    passInfoId: string;
    passNumber: string;
    paid: string;
    registrationId: string;
    activeTab: string;
    guestRegistrationId: string;
  };
}

/* eslint-disable react/prop-types */
const ActionCellPopup: React.FC<ActionCellProps> = ({ data }) => {
  const tippyRef = useRef<Element>(null);
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);
  const history = useHistory();
  const { featuresConfig } = useContext(CommunityContext);
  const hostWillPay = Boolean(featuresConfig?.host?.inviteGuest?.hostMustPay);

  // const downloadPdf = async () => {
  //     const pdf: any = await exportPdf([data?.url]);
  //     if (pdf) {
  //         pdf.save('access-passes.pdf');
  //     }
  // };

  // const printPdf = async () => {
  //     const pdf: any = await exportPdf([data?.url]);
  //     if (pdf) {
  //         const blob = pdf.output('blob');
  //         const nextWindow = window.open(URL.createObjectURL(blob), '_blank');
  //         nextWindow?.focus();
  //         nextWindow?.print();
  //     }
  // };

  const openLinkHandler = (option: string) => {
    hide();
    if (option === 'edit') {
      history.push(
        `../guest/my-passes/edit-pass/${data.passId}/${data.passInfoId}/${data.passNumber}`,
        { data },
      );
    }
    if (option === 'view') {
      history.push('../guest/pass-details', { data });
    }
    // if (option === 'download') {
    //     downloadPdf();
    // }
    // if (option === 'print') {
    //     printPdf();
    // }
    if (option === 'share') {
      history.push('../guest/my-passes/share-pass', { data });
    }
    if (option === 'refund') {
      history.push(`../guest/my-passes/refund-pass/${data.passId}`);
    }
    if (option === 'delete') {
      history.push(`../guest/my-passes/delete-pass/${data.passId}`);
    }
    if (option === 'complete') {
      if(data.passStatus === 'incomplete-rental-car') {
        history.push(`../guest/registration/${data?.registrationId}/vehicle`);
      } else {
        history.push(`../guest/registration/${data?.registrationId}`);
      }
    }
    if (option === 'addPass') {
      history.push(`../guest/registration/${data?.registrationId}/vehicle`);
    }
  };

  const dropDownContent = (
    <div className={style.menuContainer} role="menu">
      <div
        onClick={() => openLinkHandler('edit')}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            openLinkHandler('edit');
          }
        }}
        role="menuitem"
        tabIndex={1}
        className={style.menuItem}
      >
        <span className={style.itemText}>Edit</span>
      </div>
      <div
        onClick={() => openLinkHandler('view')}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            openLinkHandler('view');
          }
        }}
        role="menuitem"
        tabIndex={2} // Add tabIndex to make it focusable
        className={style.menuItem}
      >
        <span className={style.itemText}>View / Download</span>
      </div>
      <div
        onClick={() => openLinkHandler('share')}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            openLinkHandler('share');
          }
        }}
        role="menuitem"
        tabIndex={3}
        className={style.menuItem}
      >
        <span className={style.itemText}>Share</span>
      </div>
      {isRefundOptionAllow(data.passStatus, data.paid) ? (
        <div
          onClick={() => openLinkHandler('refund')}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              openLinkHandler('refund');
            }
          }}
          role="menuitem"
          tabIndex={4}
          className={style.menuItem}
        >
          <span className={style.itemText}>Refund</span>
        </div>
      ) : null}
      {['incomplete','incomplete-rental-car'].indexOf(data?.passStatus) > -1 ? (
        <div
          onClick={() => openLinkHandler('complete')}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              openLinkHandler('complete');
            }
          }}
          role="menuitem"
          tabIndex={5}
          className={style.menuItem}
        >
          <span className={style.itemText}>Complete</span>
        </div>
      ) : null}
      {['incomplete'].indexOf(data?.passStatus) > -1 && !hostWillPay ? (
        <div
          onClick={() => openLinkHandler('addPass')}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              openLinkHandler('addPass');
            }
          }}
          role="menuitem"
          tabIndex={6}
          className={style.menuItem}
        >
          <span className={style.itemText}>Add a Pass</span>
        </div>
      ) : null}

      {/* <div
                onClick={() => openLinkHandler('print')}
                onKeyDown={e => {
                    if (e.key === 'Enter') {
                        openLinkHandler('print');
                    }
                }}
                role="menuitem"
                tabIndex={0} // Add tabIndex to make it focusable
                className={style.menuItem}
            >
                <span className={style.itemText}>Print</span>
            </div> */}

      <div
        onClick={() => openLinkHandler('delete')}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            openLinkHandler('delete');
          }
        }}
        role="menuitem"
        tabIndex={6} // Add tabIndex to make it focusable
        className={style.menuItem}
      >
        <span className={style.itemText}>Delete</span>
      </div>
    </div>
  );

  return (
    <Tippy
      ref={tippyRef}
      content={dropDownContent}
      visible={visible}
      onClickOutside={hide}
      allowHTML
      arrow={false}
      appendTo={document.body}
      interactive
      placement="right"
    >
      <div className={style.actionBox}>
        <button className={style.actionBtn} onClick={visible ? hide : show}>
          <p>Actions</p>
          <ActionImg />
        </button>
      </div>
    </Tippy>
  );
};

export default ActionCellPopup;
