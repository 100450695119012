import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import PassInfo from '../../common_lib_front/types/passInfo';
import { formatDate } from '../../common_lib_front/utilities/formatDate';
import tableStyle from './incompletePassTableData.module.css';

interface IncompletePassTableDataProps {
  data: PassInfo;
  // eslint-disable-next-line react/require-default-props
  showCompleteBtn?: string | ((p: PassInfo) => string);
}

export default function IncompletePassTableData(
  props: IncompletePassTableDataProps,
): ReactElement {
  const { data, showCompleteBtn } = props;

  const btnComplete = (() => {
    if (showCompleteBtn) {
      return (
        <Link
          to={
            typeof showCompleteBtn === 'string' ? showCompleteBtn : showCompleteBtn(data)
          }
        >
          <button className={tableStyle.completeBtn}>Complete Pass</button>{' '}
        </Link>
      );
    }
    return <></>;
  })();

  return (
    <tr className={`${tableStyle.sheet} white ${tableStyle.pointerHover}`}>
      <td className={tableStyle.item}>{formatDate(new Date(data.startDate))}</td>
      <td className={tableStyle.item}>{formatDate(new Date(data.endDate))}</td>
      <td className={tableStyle.item}>{btnComplete}</td>
    </tr>
  );
}
