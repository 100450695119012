import React, { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import footerStyle from './footer.module.css';

export function Footer(): ReactElement {
  const { t } = useTranslation();
  const { name: communityName } = useContext(CommunityContext);
  return (
    <div className={` ${footerStyle.footer} darkGrey `}>
      <div className={` ${footerStyle.footer__policy} `}>
        <Link
          to="/guest/privacy-policy"
          className={` ${footerStyle.footer__policy} textColorLight `}
        >
          {t('Privacy Policy')}
        </Link>
        <Link
          to="/guest/terms-conditions"
          className={` ${footerStyle.footer__policy} textColorLight `}
        >
          {t('Terms and Conditions')}
        </Link>
      </div>
      <p className={` ${footerStyle.footer__title} textColorLight `}>
        {t('Copyright')} <span className={footerStyle.titleName}>{communityName}</span>{' '}
        2021 |
        <Link to="/guest/terms-conditions">
          <span className={` ${footerStyle.footer__link} textColorLight `}>
            {t('Terms of use')}
          </span>
        </Link>
        | {t('Powered by')}{' '}
        <a
          className={` ${footerStyle.footer__link} textColorLight `}
          href="https://symliv.com/"
        >
          SymLiv
        </a>
      </p>
    </div>
  );
}
